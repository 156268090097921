import React, { useState, useCallback } from 'react';
import { Card, Avatar, Tabs, Comment, Tooltip, Tag, Popover, message } from 'antd';
import { UserOutlined, MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import numeral from 'numeral';
import styled from 'styled-components'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import { useActiveWeb3React } from '../../hooks'
import { useSocialList, useTrendingList, postPredict, postSocial } from './hook';
import { useManagerInfo } from '../STS/hooks';
import { shortenAddress } from 'utils';
import STS from '../STS'
import {ReactComponent as UpIcon} from 'assets/svg/up.svg'
import {ReactComponent as DownIcon} from 'assets/svg/down.svg'
import {ReactComponent as ExtraIcon} from 'assets/svg/extra.svg'
import {ReactComponent as DislikeIcon} from 'assets/svg/dislike.svg'
import {ReactComponent as NoVoiceIcon} from 'assets/svg/novoice.svg'
import './index.less'

const { TabPane } = Tabs;

const Container = styled.div`
    padding: 50px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 20px;
    `};
`

const CoinPrice = ({price} : any) => {
    const arr = price.split(' ');
    return <div className={`comment-price ${arr[2].includes('-') ? 'down' : ''}`}>{price}</div>
}

export default function Social() {
    const { account, chainId, library } = useActiveWeb3React();
    const [refresh, setRefresh] = useState<number>(1);
    const [activeTopic, setActiveTopic] = useState<string>('');
    const socialList = useSocialList(activeTopic, refresh);
    const trendingList = useTrendingList();
    const { managerInfo } = useManagerInfo(account);
    const { t } = useTranslation()

    const onChange = (key: string) => {
        setActiveTopic(key)
    };

    const handlePredict = useCallback(async (data: any, direction: string) => {
        if(account && library && chainId){
            const res = await postPredict(account, data.id, direction, chainId, library)
            if(res && !res.error){
                message.success('Predict success!');
                setRefresh(refresh + 1)
            }
        }
    }, [account, library, chainId, refresh])

    const handleSocilaAction = useCallback(async (data: any, action: string) => {
        if(account && library && chainId){
            const id = action === 'dislike' ? data.id : data.user.id;
            const res = await postSocial(account, id, action, chainId, library)
            if(res && !res.error){
                action === 'dislike' ? message.success('Dislike success!') : message.success(`@${data.user.screen_name} has been muted`);
                setRefresh(refresh + 1)
            }
        }
    },[account, library, chainId, refresh])

    const preventDefault = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        console.log('Clicked! But prevent default.');
        setActiveTopic('');
    };

    return (
        <STS>
            <Container className='social-page'>
                 <div>
                    <Card className='treding-card'>
                        <div className='item' onClick={() => {setActiveTopic('')}}><h1>All Tokens</h1></div>
                        {trendingList.map((data: any) => <div key={data.topic} className={`item ${data.topic === activeTopic ? 'active' : ''}`} onClick={() => {setActiveTopic(data.topic)}}>
                            <h2><Avatar size={20} src={getTokenLogoURL(data.topic)} />&nbsp;{data.topic.toUpperCase()}</h2>
                        </div>)}
                    </Card>
                </div>
                <div>
                    {account && 
                        <Card className="myInfo">
                            <div className="avatar">
                                {(activeTopic === '' || activeTopic === 'tx' || activeTopic === 'post') ? <Link to={`/sts/profile/${account}`}>{ managerInfo?.photo_url ? <Avatar size={40} src={managerInfo?.photo_url} />: <Avatar size={40} icon={<UserOutlined />}/>}</Link> : <Avatar size={40} src={getTokenLogoURL(activeTopic)} />} 
                            </div>
                            <div className="links">
                                <div>{(activeTopic === '' || activeTopic === 'tx' || activeTopic === 'post') ? account && shortenAddress(account) : activeTopic.toUpperCase()}</div>
                            </div>
                        </Card>
                    }
                    <Card className='social-content'>
                        {/* {activeTopicName && <Tag className='topic-tag' color="#F6F1FF" closable onClose={preventDefault}>{activeTopicName}</Tag>} */}
                        <Tabs defaultActiveKey="1" onChange={onChange}>
                            <TabPane tab={t('social_all')} key="">    
                            </TabPane>
                            <TabPane tab={t('social_post')} key="post">    
                            </TabPane>
                            <TabPane tab={t('social_transaction')} key="tx">    
                            </TabPane>
                        </Tabs>
                        {socialList.map((data: any) =>
                            <Comment
                                key={data.id}
                                author={<div className='author'>
                                    <div><Link to={`/sts/manager/${data.user.manager}`}><Avatar size={40} src={data.user.photo_url} alt={data.user.name} /></Link></div>
                                    <div>
                                        <a href={data.user.url} target="_blank">{data.user.name}&nbsp;&nbsp;<span>@{data.user.screen_name}</span></a>
                                        <div className='time'>
                                            <Tooltip title={moment(data.created_at * 1000).format('YYYY-MM-DD HH:mm:ss')}>
                                                <span>{moment(data.created_at * 1000).fromNow()}</span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                }
                                // datetime={
                                //     <Tooltip title={moment(data.created_at * 1000).format('YYYY-MM-DD HH:mm:ss')}>
                                //     <span>{moment(data.created_at * 1000).fromNow()}</span>
                                //     </Tooltip>
                                // }
                                content={
                                    <>
                                        <a href={data.url} target="_blank">
                                            <div className='content'>
                                                {data.text}
                                                <a className='more' href={data.user.url} target="_blank">{t('social_show_more')}</a>
                                            </div>
                                        </a>
                                        <ul className='ant-comment-actions'>
                                            <li>
                                                <Tooltip title={t('social_up_down_tooltip')}>
                                                    <div className={`comment-action-up ${data.address_prediction === 'up' ? 'active' : (data.address_prediction !== '' ? 'selected':'')}`} onClick={() => {handlePredict(data, 'up')}}>
                                                        <UpIcon/>
                                                        <span>{numeral(data.up).format('0,0')}</span>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title={t('social_up_down_tooltip')}>
                                                    <div className={`comment-action-down ${data.address_prediction === 'down' ? 'active' : (data.address_prediction !== '' ? 'selected':'')}`} onClick={() => {handlePredict(data, 'down')}}>
                                                        <DownIcon/>
                                                        <span>{numeral(data.down).format('0,0')}</span>
                                                    </div>
                                                </Tooltip>
                                            </li>
                                            {data.coins && data.coins.length > 0 && 
                                                data.coins.map((data: any) => <li key={data}><CoinPrice price={data}/></li>)
                                            }
                                        </ul>
                                        <div className='extra'>
                                            <Popover 
                                                content={
                                                    <div className='extra-content'>
                                                        <p onClick={() => {handleSocilaAction(data, 'dislike')}}><DislikeIcon/>Not interested in this post</p>
                                                        <p onClick={() => {handleSocilaAction(data, 'mute')}}><NoVoiceIcon/>Mute @{data.user.screen_name}</p>
                                                    </div>
                                                } 
                                                trigger="click" 
                                                title={<MoreOutlined/>}
                                            >
                                                <MoreOutlined/>
                                            </Popover>
                                        </div>
                                    </>
                                }
                            />
                        )}  
                    </Card>
                </div>
            </Container>
        </STS>
    )
}