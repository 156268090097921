import React, { useEffect, useState } from 'react'
import { Button, Radio } from 'antd';
import numeral from 'numeral'
import moment from 'moment';
import styled from 'styled-components'

import { ResponsiveContainer, Legend, LineChart, Line, YAxis, Tooltip, XAxis } from 'recharts'

interface ManagerChartProps {
  data?: {
    ts: string
  }[]
  chartRange?: string
  address: string[]
  selectedRowKeys: string[]
  allPortfolios: any
  setChartRange?: (range: string) => void
}

const TooltipBox = styled.div`
  border: 1px solid rgba(204, 255, 218, 1);
  padding: 10px;
  p{
    margin: 0
  }
`

const ManagerChart: React.FC<ManagerChartProps> = ({
  data,
  chartRange,
  setChartRange,
  address,
  selectedRowKeys,
  allPortfolios
}) => {

  const handleRangeChange = (e:any) => {
    setChartRange && setChartRange(e.target.value)
  }

  const formatToolTip = (chartData: any) => {
    if (!chartData) return ['--', 'No Data Available']
    const {
      payload: { value, ts },
    } = chartData
    const timeString = moment(ts*1000).format('YYYY-MM-DD HH:mm:ss');
    return [timeString, `$${numeral(value).format('0,0.00')}`]
  }

  const renderTooltip = (props: any) => {
    const tooltipData = props.payload?.[0]
    const [label, value] = formatToolTip(tooltipData)

    return <TooltipBox>
      <p>{label}</p>
      <p>Price: {value}</p>
    </TooltipBox>
  }

  const lineColor: any = {
    0: '#03CE3C',
    1: '#A880FF',
    2: '#FFCC80',
    3: '#5D88F7',
    4: '#94E8D3',
  }


  // const minY = Math.min(...(data || []).map<number>(({ value }) => value))
  // const maxY = Math.max(...(data || []).map<number>(({ value }) => value))
  // const minimumYAxisLabel = minY - 5 > 0 ? minY - 5 : 0

  return (
    <Container>
      <DurationWrapper>
        <ButtonWrapper>
          <Radio.Group onChange={handleRangeChange} defaultValue={chartRange}>
            <Radio.Button value="1d">1D</Radio.Button>
            <Radio.Button value="1w">1W</Radio.Button>
            {/* <Radio.Button value="1m">1M</Radio.Button>
            <Radio.Button value="1y">1Y</Radio.Button>
            <Radio.Button value="1y">ALL</Radio.Button> */}
          </Radio.Group>
        </ButtonWrapper>
      </DurationWrapper>
      <ChartContainer>
        <LineChart
          data={data}
        >
          <YAxis
            axisLine={false}
            tickLine={false}
            mirror={true}
            domain={['dataMin', 'dataMax']}
            tickFormatter={(v: any) => numeral(v).format('0,0.000')}
          />
          <XAxis  
            axisLine={false}
            tickLine={false}
            dataKey="ts"
            hide={true}
          />
          <Tooltip labelFormatter={(time) => moment(time*1000).format('YYYY-MM-DD HH:mm:ss')} formatter={(value: any, name: any) => { 
            const index = allPortfolios.findIndex((v: any) => v.address === name);
            return  [`$${numeral(value).format('0,0.00000000')}`, allPortfolios[index].symbol]}
          }/>
          {
            address.map((v: string, i: number) => 
              {
                return selectedRowKeys.includes(v) && <Line type="monotone" dataKey={v} stroke={lineColor[i%5]} dot={false} />
              }
            )
          }
        </LineChart>
      </ChartContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const ChartContainer = styled(ResponsiveContainer)`
  min-height: 235px;
`

const ChartTitle = styled.h2`
  font-size: 42px;
`

const DurationWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 0;
  display: flex;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  z-index: 10;
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
    box-shadow: none;
  }
  .ant-radio-button-wrapper{
    padding: 4px 8px;
    color: #84818A;
    font-size: 10px;
    border: none!important;
    &:before{
      display: none!important;
    }
  }
`

export default ManagerChart
