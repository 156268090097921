import React, { useCallback, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import useThrottle from "@rooks/use-throttle"
import { RedoOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Progress, Card, Tabs, Input, Avatar, Tooltip, Button, Row, Col, message } from 'antd';
//@ts-ignore
import randomColor from 'randomcolor';
import { usePortfolioDetail, useChartData, postFavoritePortfolio } from './hooks';
import SimplePriceChart from './SimplePriceChart';
import { useActiveWeb3React } from '../../hooks'
import { PriceChartRangeOption } from 'constants/priceChartEnums'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import { useTransactionAdder, useIsTransactionPending } from '../../state/transactions/hooks'
import { useSetTokenContract, useBasicIssuanceModuleContract, useTradeModuleContract, useSofiManageContract } from '../../hooks/useContract'
import { managerModule, portfolioFactory, portfolioModule, trading, NETWORK_LABELS } from '../../constants'
import { retry, RetryableError } from '../../utils/retry'
import { shortenAddress, toPlainString } from '../../utils'
import { ChainLogo } from 'components/CurrencyLogo'
import STSBuy from './STSBuy'
import {ReactComponent as ManageIcon} from 'assets/svg/manage.svg'
import {ReactComponent as CollectIcon} from 'assets/svg/sts/collect.svg'
import {ReactComponent as UncollectIcon} from 'assets/svg/sts/uncollect.svg'

import './index.less';
import './Portfolio.less'
import STS from './index'
import { relative } from 'path';
import { useEffect } from 'react';
import { ChainId } from 'constants/chainId';

const { Meta } = Card;
const { TabPane } = Tabs;
const { Search } = Input;

const Container = styled.div`
    padding: 50px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 20px;
    `};
`
const Title = styled.h1`
    font-size:32px;
    color: #5542F6;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        margin-bottom: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-bottom: 20px;
    `};
    span{
        font-size: 14px;
        color: #000;
    }
`

const SubTitle = styled.h2`
    font-size:14px;
    margin-top: 18px;
    margin-bottom: 12px;
`

export default function Portfolio(props: RouteComponentProps<{ address: string }>) {
  const {
    match: {
      params: { address }
    }
  } = props
  const { t } = useTranslation();
  const { account, chainId, library } = useActiveWeb3React()
  const [chartRange, setChartRange] = useState<string>(PriceChartRangeOption.DAILY_PRICE_RANGE)
  const [refreshPortfolio, setRefreshPortfolio] = useState<number>(1)
  const { portfolio } = usePortfolioDetail(address, refreshPortfolio);
  const [refresh, setRefresh] = useState<number>(1);
  const [refreshTime, setRefreshTime] = useState<any>(new Date());
  const { chartData } = useChartData(address, chartRange, refresh);
  const setTokenContract = useSetTokenContract(address)
  const [isTrade, setIsTrade] = useState<boolean>(false)
  const [isBuySell, setIsBuySell] = useState<boolean>(false)
  const [tradeStatus, setTradeStatus] = useState<number>(2)
  const [loading, setLoading] = useState<boolean>(false)
  const [type, setType] = useState<string>('')
  const basicIssuanceModuleContract = useBasicIssuanceModuleContract(portfolioModule[chainId ? chainId: 4])
  const tradeModuleContract = useTradeModuleContract(trading[chainId ? chainId: 4])
  const sofiManageContract = useSofiManageContract(managerModule[chainId ? chainId : 4])
  const addTransaction = useTransactionAdder()
  const history = useHistory();

  useEffect(() => {
    if(address && account){
      getIsTrade();
      getIsBuySell();
    }
  }, [address, account])

  const getIsBuySell = async () => {
    if(setTokenContract){
      const _isBuySell = await setTokenContract.isInitializedModule(portfolioModule[chainId ? chainId: 4])
      console.log('_isBuySell:', _isBuySell)
      setIsBuySell(_isBuySell)
    }
  }
 
  const getIsTrade = async () => {
    if(setTokenContract){
      const status = await setTokenContract.moduleStates(managerModule[chainId ? chainId: 4])
      setTradeStatus(status);
      const _isTrade = status === 2;
      setIsTrade(_isTrade)
    }
  }

  const enableBuySell = () => {
    if(library){
      basicIssuanceModuleContract?.initialize(address)
      .then((res: any) => {
        addTransaction(res, {
          summary: t("buy_and_sell_initialize") 
        })
        retry(() => {
            return library
            .getTransactionReceipt(res.hash)
            .then(receipt => {
                if (receipt === null) {
                    console.debug('Retrying for hash', res.hash)
                    throw new RetryableError()
                  }
                  if (receipt) {
                    setIsBuySell(true);
                    console.log("trade receipt", receipt)
                  }
            })
        }, {
            n: Infinity,
            minWait: 2500,
            maxWait: 3500
        })
      })
    }
  }

  const enableTrade = useCallback(async() => {
    if(library && setTokenContract){
      if(tradeStatus === 0){
        setTokenContract.addModule(managerModule[chainId ? chainId: 4])
        .then((res: any) => {
          addTransaction(res, {
            summary: t("add_trading_module")
          })
          retry(() => {
            return library
            .getTransactionReceipt(res.hash)
            .then(receipt => {
                if (receipt === null) {
                    console.debug('Retrying for hash', res.hash)
                    throw new RetryableError()
                  }
                  if (receipt) {
                    setTradeStatus(1);
                    console.log("trade receipt", receipt)
                  }
            })
          }, {
              n: Infinity,
              minWait: 2500,
              maxWait: 3500
          })
        })
      }else if(tradeStatus === 1){
        sofiManageContract?.initialize(address)
        .then((res: any) => {
          addTransaction(res, {
            summary: t("trade_initialize")
          })
          retry(() => {
            return library
            .getTransactionReceipt(res.hash)
            .then(receipt => {
                if (receipt === null) {
                    console.debug('Retrying for hash', res.hash)
                    throw new RetryableError()
                  }
                  if (receipt) {
                    setIsTrade(true);
                    console.log("trade receipt", receipt)
                  }
            })
          }, {
              n: Infinity,
              minWait: 2500,
              maxWait: 3500
          })
        })
      }
    }
  }, [library, tradeStatus, chainId])
 
  const refreshChart = () => {
    setRefresh(refresh + 1);
    setRefreshTime(new Date())
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }


  const favoritePortfolio = useCallback(async(e: any, portfolioAddress: string, action: boolean) => {
    e.stopPropagation();
    if(account && chainId){
      const res: any = await postFavoritePortfolio(portfolioAddress, account, action, chainId, library);
      console.log(res)
      if(res.address) {
        message.success(action ? t('successfully_favorited') : t('successfully_unfavorited') )
        setRefreshPortfolio(refreshPortfolio + 1)
      }
    }
  }, [account, library, refreshPortfolio])

  const [ handleFavoritePortfolio ] = useThrottle(favoritePortfolio, 2000)

  return (
    <STS>
      <Container className='portfolio'>
        <Row gutter={[48, 24]}>
          <Col sm={16} xs={24}>
            <div className='tokenInfo'>
              <div>
                <img className="tokenAvator" src={portfolio?.icon || portfolio?.avatar_uri}/>
                <div>{portfolio.symbol === 'WETH' ? 'ETH' : portfolio.symbol}<span>{portfolio.name}</span></div>
              </div>
              <div>
                <Button>{portfolio.favorite ? <CollectIcon onClick={(e) => {handleFavoritePortfolio(e, portfolio.address, false)}}/> : <UncollectIcon onClick={(e) => {handleFavoritePortfolio(e, portfolio.address, true)}}/>}</Button>
              </div>
            </div>
            <Card 
              className="chartCard" 
              bordered={false}
            >
              <SimplePriceChart data={chartData} chartRange={chartRange} setChartRange={setChartRange}/>
              <div><div className="price_box"><b className="price"><Tooltip title={`$${toPlainString(portfolio?.value_per_set)}`}>{portfolio?.value_per_set > 0.01 ? `$${numeral(toPlainString(portfolio?.value_per_set)).format('0,0.00')}` : '<$0.01'}</Tooltip></b><b className={portfolio.price_changed_24hr > 0 ? "up":"down"}>{`${portfolio.price_changed_24hr > 0 ? `+${portfolio.price_changed_24hr}%`: `${portfolio.price_changed_24hr}%`}`}</b></div></div>
              <div className="card_footer">{moment(refreshTime).format('YYYY-MM-DD HH:mm:ss')}&nbsp;&nbsp;<button onClick={refreshChart}>{loading ? <LoadingOutlined /> : <RedoOutlined />}&nbsp;Refresh</button></div>
            </Card>
            <Card className="infoCard">
                <div className='title'>{t('summary')}</div>
                <div>
                  <div>
                      <div>{t("market_cap")}</div>
                      <div>${numeral(portfolio?.total_value).format('0,0.00')  || '-'}</div>
                  </div>
                  <div>
                    <div>{t("cumulative_APY")}</div>
                    <div>{`${portfolio?.cumulative_apy}%` || '-'}</div>
                  </div>
                  <div>
                    <div>{t("inception_date")}</div>
                    <div>{moment(portfolio?.created_at*1000).format('YYYY-MM-DD')}</div>
                  </div>
                  <div>
                    <div>{t("creator")}</div>
                    <div onClick={() => {history.push(`/sts/profile/${portfolio?.manager}`)}}>{portfolio?.manager && shortenAddress(portfolio?.manager)}</div>
                  </div>
                </div>
            </Card>
            <div className='title'>{t("asset_distribution")}</div>
            <div className='asset'>
              {
                portfolio.components && portfolio.components.map((data: any) => 
                  <Card 
                    className='assetCard'
                    key={data.address}
                  >
                    <div>
                      <Avatar size={20} src={getTokenLogoURL(data.symbol)} /><span>{data.symbol}</span>
                      <h3>{data.price_usd ? `$${numeral(data.price_usd).format('0,0.00')}` : '-'}</h3>
                      <p>Value per Set <Tooltip title={`$${toPlainString(data.value_per_set)}`}>{data.value_per_set > 0.01 ? `$${numeral(data.value_per_set.toFixed(2)).format('0,0.00')}` : '<$0.01'}</Tooltip></p>
                    </div>
                    <div>
                      {/* @ts-ignore */}
                      <Progress width={60} strokeWidth={4} strokeColor={'#14B8A6'} type="circle" format={percent => `${percent}%`} percent={Number((data.value_per_set/portfolio.value_per_set*100).toFixed(2))} />
                    </div>
                  </Card>
                )
              }
            </div>
            {portfolio.intro && 
              <div className='about'>
                <div className='title'>{t('about')}</div>
                <p>{portfolio.intro}</p>
              </div>
            }
          </Col>
          <Col sm={8} xs={24}>
            <div className='left-content'>
              <div className='network'>
                <div>{t('current_network')}</div>
                <div>
                <ChainLogo chainId={chainId}/>
                {//@ts-ignore
                  t(NETWORK_LABELS[chainId || ChainId.RINKEBY])
                }
                </div>
              </div>
              <div>
              {!type && 
                <div className={`btns ${!isTrade && portfolio?.manager === account ? 'enable' : '' }`}>
                  {isBuySell &&
                    <div className='two'>
                      <Button type="primary" size="large" key="1" onClick={() => {setType('buy')}}>{t("buy")}</Button>
                      <Button type="primary" size="large" key="2" onClick={() => {setType('sell')}}>{t("sell")}</Button>
                    </div> 
                  }
                  {!isBuySell &&
                    <>
                      {portfolio?.manager === account ? 
                      <Button type="primary" size="large" onClick={enableBuySell}>{t('enable_to_use')}</Button>
                      :
                      <Button type="primary" size="large">{t('not_enable_to_trading')}</Button>}
                    </>
                  }
                  {portfolio?.manager === account &&
                    <>
                    { isTrade ?
                      <Button icon={<ManageIcon/>} size="large" key="3" onClick={() => {setType('manage')}}>
                        {t("manage")}
                      </Button>
                      :
                      <Button type="default" size="large" key="1" onClick={enableTrade}>{tradeStatus === 0 ? t('add_trading_module'): t('enable_to_trading')}</Button>
                      }
                    </>
                  }
                  </div>
                }
                {type && 
                  <div>
                    <STSBuy contractAddress={portfolio?.address} type={type}/>
                    <Button onClick={() => {setType('')}} style={{marginTop: '12px'}} size="large" key="1" block>Cancel</Button>
                  </div>
                }
              </div>
            </div>
          </Col>
        </Row>    
      </Container>
    </STS>
  )
}
