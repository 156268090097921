import React, { useState, useEffect } from 'react'
import { Button, Badge, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Container } from '../../STS/Markets'
import STS from '../../STS'
import './index.less'
import EditPortfolioListImg from '../../../assets/images/vote/Edit_Portfolio_List.png'
import MarketingCampaignImg from '../../../assets/images/vote/Marketing_Campaign.png'
import { Link, useHistory } from 'react-router-dom'

export default function SelectVote() {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <STS>
      <Container>
        <div className="select_content">
          <div className="title">{t("governance_title4")}</div>
          <div className="desc">{t("create_governance_desc")}</div>
          <div className="content">
            <div className="item">
              <div className="img_contariner">
                <img src={EditPortfolioListImg} alt="" />
              </div>
              <div className="item_title">{t("edit_portfolio_list")}</div>
              <div className="item_desc">{t('edit_portfolio_desc')}</div>
              <div className="choose">
                <Link to="/proposals/create?type=Edit Portfolio List">
                  <Button size="large" type="primary">
                    {t("governance_choose")}
                  </Button>
                </Link>
              </div>
            </div>
            <div className="item">
              <div className="img_contariner">
                <img src={MarketingCampaignImg} alt="" />
              </div>
              <div className="item_title">{t("marketing_campaign")}</div>
              <div className="item_desc">{t("marketing_campaign_desc")}</div>
              <div className="choose">
                <Link 
                to={{
                  pathname:'/proposals/create',
                  state: {
                    type: 'Marketing Campaign'
                  }
                }} >
                  <Button size="large" type="primary">
                    {t("governance_choose")}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="cancel"
            onClick={() => {
              history.go(-1)
            }}
          >
            <Button>{t('cancel')}</Button>
          </div>
        </div>
      </Container>
    </STS>
  )
}
