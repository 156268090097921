import React, { useCallback, useEffect, useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits } from '@ethersproject/units'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { RouteComponentProps, useHistory, Link, useLocation } from 'react-router-dom'
import { Avatar, Button, Input, Form, Result, Steps, Select, Slider, Progress, Card, message, Tabs, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ChainId } from 'constants/chainId'
import { LockOutlined, UserOutlined, LoadingOutlined, ArrowUpOutlined, CloseOutlined } from '@ant-design/icons';
import sumBy from 'lodash.sumby';
import numeral from 'numeral';
import STS from './index'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useTransactionAdder, useIsTransactionPending } from '../../state/transactions/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useTokens, postPortfolioProfile, postSearchAI } from './hooks';
import { toPlainString } from 'utils/index';
import { useWalletModalToggle } from '../../state/application/hooks'
import { useCreatePortfolioContract, useBasicIssuanceModuleContract, useTradeModuleContract, useSofiManageContract } from '../../hooks/useContract'
import { portfolioFactory, portfolioModule, trading, managerModule, getGaslimitCount, APILIST } from '../../constants'
import { floatAdd, floatDiv } from 'utils/calculate';
import { ReactComponent as PhotoAddIcon } from 'assets/svg/PhotoAdd.svg'
import { ReactComponent as SuccessIcon } from 'assets/svg/success.svg'
import { ReactComponent as FailedIcon } from 'assets/svg/failed.svg'
import './CreatePortfolio.less'

const { Step } = Steps;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];


const Container = styled.div`
    padding: 50px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 20px;
    `};
    overflow: auto;
    label{
        font-size: 18px!important;
        font-weight: 400!important;
    }
    .ant-tabs-nav{
        display: none!important;
    }
    hr{
        border-top: 1px dashed #D9D9D9;
        border-bottom: none;
        margin: 40px 0;
    }
`

const Title = styled.h1`
    font-size:32px;
    margin-bottom: 30px;
    position: fixed:
    top: 0;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        margin-bottom: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-bottom: 20px;
    `};
`
const Content = styled.div`
`

const StepActions = styled.div`
    margin: 20px auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        flex-direction: column;
        gap: 10px;
    `};
    &>div{
        &:last-of-type{
            display: flex;
        }
    }
    button{
        display: block;
        width: 150px;
        height: 42px;
    }
`
// const StepForms = styled.div`
//     margin: 20px 80px;
//     ${({ theme }) => theme.mediaWidth.upToSmall`
//         margin:20px 0;
//     `};
//     label{
//         font-size: 18px!important;
//         font-weight: 400!important;
//         color: #000!important;
//     }
//     p{
//         color: #84818A;
//         font-size: 12px;
//     }
// `
const inputRegex = /^\d+(\.\d{1,2})?$/


export default function CreatePortfolio(){
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const param = searchParams.get("search");
    console.log('searchParams:', param)
    const { t } = useTranslation()
    const { tokens } = useTokens()
    const [form] = Form.useForm()
    const [current, setCurrent] = useState<number>(0);
    const [selectedTokens, setSelectedTokens] = useState<any>([]);
    const [tokensMap, setTokensMap] = useState<any>(new Map());
    const [unLockedTokensMap, setUnLockedTokensMap] = useState<any>(new Map());
    const [lockedTokensMap, setLockedTokensMap] = useState<any>(new Map());
    const [loading, setLoading] = useState<boolean>(false);
    const [txHash, setTxHash] = useState<string>('');
    const [agree, setAgree] = useState<boolean>(true);
    const [failed, setFailed] = useState<boolean>(false);
    const { chainId, account, library } = useActiveWeb3React();
    const contract =  useCreatePortfolioContract(portfolioFactory[chainId ? chainId: 4]);
    const basicIssuanceModuleContract = useBasicIssuanceModuleContract(portfolioModule[chainId ? chainId: 4])
    const sofiManageContract = useSofiManageContract(managerModule[chainId ? chainId : 4])
    const addTransaction = useTransactionAdder()
    const claimPending = useIsTransactionPending(txHash ?? '')
    const { width } = useWindowSize()
    const [percentInput, setPercentInput] = useState<any>({});
    const [startPriceInput, setStartPriceInput] = useState<any>({});
    const [imageUrl, setImageUrl] = useState('');
    const [loadingSearch, setLoadingSearch] = useState(false)
    const [searchMessage, setSearchMessage] = useState('')
    const toggleWalletModal = useWalletModalToggle()

    const fetchSearchAIToken = async (param: string, chainId: ChainId) => {
        const {tokens, message} = await postSearchAI(param, chainId)
        setLoadingSearch(false)
        setSearchMessage(message)
        if(tokens.length > 0){
            const address = tokens.map((v:any) => v.address)
            form.setFieldsValue({tokens: address})
            handleChange(address);   
        }
    }
    useEffect(() => {
        if(param){
            setLoadingSearch(true)
        }
        if(param && chainId && tokens.length){
            fetchSearchAIToken(param, chainId)
        }
    }, [param, tokens])

    const handleAgreeChange = (e: any) => {
        setAgree(e.target.checked);
    }
    const history = useHistory();
    // const getAllowedToken = useGetAllowedTokens()
    // console.log("getAllowedToken", getAllowedToken)
    const next = useCallback(async() => {
        try {
            let fields;
            if(current === 0){
                fields = ['tokens']
                const arr = Object.keys(percentInput);
                for(let i = 0; i < arr.length; i++){
                    console.log(percentInput[arr[i]].validateStatus)
                    if(percentInput[arr[i]].validateStatus !== 'success'){
                        return;
                    }
                }
            }
            if(current === 1){
                fields = ['name', 'symbol', 'startPrice']
            }
            const values = await form.validateFields(fields);
            setCurrent(current + 1);
            console.log('Success:', values);
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    }, [percentInput, current]);
  
    const prev = () => {
      setCurrent(current - 1);
    };

    const deleteToken = (key: string) => {
        setTokensMap((prev:any) => {
            const newState = new Map(prev);
            newState.delete(key);
            return newState;
        });
        const index = selectedTokens.findIndex((v: any) => v.address === key);
        setSelectedTokens((prev:any) => {
            const newState = [...prev];
            newState.splice(index, 1);
            form.setFieldsValue({ tokens: newState.map((v:any) => v.address) });
            return newState;
        });
    }

    const handleChange = useCallback((value: any) => {
        let list = value.map((address: any) => tokens?.filter((token: any) => token.address === address)[0])
        setSelectedTokens(list);
        if(tokensMap.size > value.length){
            for (let key of tokensMap.keys()) {
                if(!value.includes(key)){
                    deleteToken(key)
                }
            }
        }

        for(let i = 0; i < value.length; i++){
            if(!tokensMap.has(value[i])){
                setTokensMap((prev: any) => new Map([...prev, [value[i], {
                    decimals: tokens.filter((token: any) => token.address === value[i])[0].decimals,
                    price_usd: tokens.filter((token: any) => token.address === value[i])[0].price_usd,
                    percent: 0,
                    locked: false
                }]]))
            }
        }
    }, [tokens]);

    const toggleLock = (addr: string) => {
        setTokensMap((prev: any) => new Map(prev).set(addr, {...prev.get(addr), locked: !prev.get(addr).locked}));
    }

    const changeTokenPercent = (value: number, addr: string) => {
        if(unLockedTokensMap.size > 1){
            const lockedTotal = sumBy(Array.from(lockedTokensMap.values()), 'percent');
            if(value > 10000 - lockedTotal - (unLockedTokensMap.size - 1)){
                value = 10000 - lockedTotal - (unLockedTokensMap.size - 1)
            }
            let total = 10000 - value;
            if(lockedTokensMap.size > 0){
                total = total - lockedTotal;
            }
            const unLockedTokens = new Map(unLockedTokensMap)
            unLockedTokens.delete(addr)
            updatePercentForTokens(unLockedTokens, total)
        }
        form.setFieldsValue({[`precent-${addr}`]: value/100});
        setTokensMap((prev: any) => new Map(prev).set(addr, {...prev.get(addr), percent:value, locked: prev.get(addr).locked}));
    }

    const updatePercentForTokens = (unLockedTokens: any, total: number) => {
        if(total%unLockedTokens.size === 0){
            for (let [key, value] of unLockedTokens) {
                console.log(key + ' = ' + value)
                setTokensMap((prev: any) => new Map(prev).set(key, {...prev.get(key), percent: floatDiv(total, unLockedTokens.size), locked: false}));
                form.setFieldsValue({[`precent-${key}`]: floatDiv(total, unLockedTokens.size)/100});
            }
        }else {
            for (let [key, value] of unLockedTokens) {
                console.log(key + ' = ' + value)
                const last = Array.from(unLockedTokens.keys()).pop();
                if(key === last){
                    setTokensMap((prev: any) => new Map(prev).set(key, {...prev.get(key), percent: floatAdd(Math.floor(floatDiv(total, unLockedTokens.size)),  Math.floor(total%unLockedTokens.size)), locked: false}));
                    form.setFieldsValue({[`precent-${key}`]:  floatAdd(Math.floor(floatDiv(total, unLockedTokens.size)),  Math.floor(total%unLockedTokens.size))/100});
                }else {
                    setTokensMap((prev: any) => new Map(prev).set(key, {...prev.get(key), percent: Math.floor(floatDiv(total, unLockedTokens.size)), locked: false}));
                    form.setFieldsValue({[`precent-${key}`]: Math.floor(floatDiv(total, unLockedTokens.size))/100});
                }
            } 
        }
    }

    useEffect(() => {
        if(tokensMap.size > 0){
            let unLockedTokens = new Map(); 
            let lockedTokens = new Map();
            for (let [key, value] of tokensMap) {
                console.log(key + ' = ' + value)
                if(value.locked){
                    lockedTokens.set(key, value)
                }else {
                    unLockedTokens.set(key, value)
                }
            }
            setUnLockedTokensMap(unLockedTokens);
            setLockedTokensMap(lockedTokens);
        }
    }, [tokensMap])

    useEffect(() => {
        if(tokensMap.size > 0){
            let unLockedTokens = new Map(); 
            let lockedTokens = new Map();
            for (let [key, value] of tokensMap) {
                console.log(key + ' = ' + value)
                if(value.locked){
                    lockedTokens.set(key, value)
                }else {
                    unLockedTokens.set(key, value)
                }
            }
            setUnLockedTokensMap(unLockedTokens);
            setLockedTokensMap(lockedTokens);
            if(unLockedTokens.size > 0){
                let total = 10000;
                if(lockedTokensMap.size > 0){
                    const lockedTotal = sumBy(Array.from(lockedTokens.values()), 'percent');
                    total = 10000 - lockedTotal;
                }
                updatePercentForTokens(unLockedTokens, total)
            }
        }
    }, [tokensMap.size])

    const formatter = (value: number | undefined) =>  {
        return `${value ? value/100 : 0}%`;
    }

    const validatePercentNumber = (num: string): any => {
        if (0.01<=Number(num) && Number(num) <= 100 - selectedTokens.length * 0.01 && inputRegex.test(num)) {
          return {
            validateStatus: 'success',
            errorMsg: null,
          };
        }
        return {
          validateStatus: 'error',
          errorMsg: t('please_input_right_percent')
        };
    }

    const handleInputChange = useCallback((value: any, address: string) => {
        if(unLockedTokensMap.size <= 1){
            return;
        }
        value = value.replace(/[^\d.]/g, "");
        const validate = validatePercentNumber(value)
        setPercentInput({[address]: validate})
        if(validate.validateStatus !== 'success') {
            return
        }
        form.setFieldsValue({[`precent-${address}`]: value});
        if(value >= 1 && value < 100 && inputRegex.test(value)){
            changeTokenPercent(value*100, address)
        }
    },[unLockedTokensMap.size])

    const validateStartPriceNumber = (num: string): any => {
        if (1 <= Number(num) && inputRegex.test(num)) {
          return {
            validateStatus: 'success',
            errorMsg: null,
          };
        }
        return {
          validateStatus: 'error',
          errorMsg: t('please_input_right_staring_price')
        };
    }

    const handleStartPriceChange = (value: any) => {
        // value = value.replace(/[^\d]/g, "");
        setStartPriceInput({...validateStartPriceNumber(value)})
        form.setFieldsValue({startPrice: value});
    }
    
    const TokenCard = useCallback(({data, validateData, percentData} : any) => {
        return <Card key={data.address} title={<div><img className="tokenAvator" style={{width: '20px', height: '20px'}} src={getTokenLogoURL(data.symbol)}/>{data.symbol}</div>} extra={<CloseOutlined onClick={() => {deleteToken(data.address)}}/>}>
                <div className="percentInput">
                    <Form.Item 
                        name={`precent-${data.address}`}
                        rules={[
                            { required: true, message: t('please_input_percent') },
                            { pattern: /^\d+(\.\d{1,2})?$/, message: t('please_input_right_percent') }
                        ]}
                        initialValue={percentData?.percent}
                        validateStatus={validateData?.validateStatus}
                        help={validateData?.errorMsg || ''}
                    >
                        <Input 
                            suffix={<>%</>}
                            onChange={(e: any) => {handleInputChange(e.target.value, data.address)}}
                            addonAfter={<LockOutlined onClick={() => {toggleLock(data.address)}}  style={{color: `${percentData?.locked ? '#5542F6' : '#000'}`}}/>} 
                        />
                    </Form.Item>    
                    <Slider
                        min={1}
                        max={10000}
                        step={1}
                        onChange={(value: number) => {changeTokenPercent(value, data.address)}}
                        value={percentData?.percent}
                        disabled={percentData?.locked || unLockedTokensMap.size === 1}
                        tipFormatter={formatter}
                    />
                </div>    
            </Card>
    }, [unLockedTokensMap.size])

    const columns: ColumnsType<any> = [
        {
          title: t("asset_name"),
          dataIndex: 'pair',
          key: 'pair',
          width: '140px',
          render: (pair: any, row: any) => <><img className="tokenAvator" src={getTokenLogoURL(row.symbol)}/>{row?.symbol}&nbsp;{pair?.token1?.name}</>,
        },
        {
          title: t("allocation"),
          key: 'address',
          dataIndex: 'address',
          align: "center",
          render: (address: string) => ( 
                 <div className="percentInput">
                    <Form.Item 
                        name={`precent-${address}`}
                        rules={[
                            { required: true, message: t('please_input_percent') },
                            { pattern: /^\d+(\.\d{1,2})?$/, message: t('please_input_right_percent') }
                        ]}
                        initialValue={tokensMap.get(address)?.percent}
                        validateStatus={percentInput?.[address]?.validateStatus}
                        help={percentInput?.[address]?.errorMsg || ''}
                    >
                        <Input 
                            style={{width: '150px'}} 
                            suffix={<>%</>}
                            onChange={(e: any) => {handleInputChange(e.target.value, address)}}
                            addonAfter={<LockOutlined onClick={() => {toggleLock(address)}}  style={{color: `${tokensMap.get(address)?.locked ? '#5542F6' : '#000'}`}}/>} 
                        />
                    </Form.Item>    
                    <Slider
                        style={{minWidth: '130px', width: '100%', marginLeft: '10px'}}
                        min={1}
                        max={10000}
                        step={1}
                        onChange={(value: number) => {changeTokenPercent(value, address)}}
                        value={tokensMap.get(address)?.percent}
                        disabled={tokensMap.get(address)?.locked || unLockedTokensMap.size === 1}
                        tipFormatter={formatter}
                    />
                </div>    
          ),
        },
        {
          title: t("action"),
          key: 'action',
          dataIndex: 'address',
          width: '150px',
          render: (address: string) => (
            <Button type="default" size="middle" onClick={() => {deleteToken(address)}}>
                {t("remove")}
            </Button>
          ),
        },
    ];
    
    const columnsH5: ColumnsType<any> = [
        {
          title: '',
          dataIndex: 'pair',
          key: 'pair',
          render: (pair: any, row: any) => 
            <>
                <div className="percentInput">
                    <div><img className="tokenAvator" src={getTokenLogoURL(row.address)}/>{row?.symbol}&nbsp;{pair?.token1?.name}</div>
                    <div>
                        <Button type="default" size="middle" onClick={() => {deleteToken(row.symbol)}}>
                            Remove
                        </Button>
                    </div>
                </div>
                <div className="percentInput">
                    <Form.Item 
                        name={`precent-${row.address}`}
                        rules={[
                            { required: true, message: t('please_input_percent') },
                            { pattern: /^\d+(\.\d{1,2})?$/, message: t('please_input_right_percent') }
                        ]}
                        initialValue={tokensMap.get(row.address)?.percent}
                        validateStatus={percentInput?.[row.address]?.validateStatus}
                        help={percentInput?.[row.address]?.errorMsg || ''}
                    >
                        <Input 
                            style={{width: '130px'}} 
                            suffix={<>%</>}
                            onChange={(e: any) => {handleInputChange(e.target.value, row.address)}}
                            addonAfter={<LockOutlined onClick={() => {toggleLock(row.address)}}  style={{color: `${tokensMap.get(row.address)?.locked ? '#5542F6' : '#000'}`}}/>} 
                        />
                    </Form.Item>    
                    <Slider
                        style={{minWidth: '130px', width: '100%', marginLeft: '10px'}}
                        min={1}
                        max={10000}
                        step={1}
                        onChange={(value: number) => {changeTokenPercent(value, row.address)}}
                        value={tokensMap.get(row.address)?.percent}
                        disabled={tokensMap.get(row.address)?.locked || unLockedTokensMap.size === 1}
                        tipFormatter={formatter}
                    />
                </div> 
            </>,
        },
    ];
      
    const onFinish = async (values: any) => {
        console.log('Success:', values);
        next()
        const tokens = Array.from(tokensMap.keys());
        setLoading(true);
        if(contract){
            const gasEstimate = await contract.estimateGas.create(
                tokens,
                tokens.map((v: any) => parseUnits(toPlainString(tokensMap.get(v).percent * values.startPrice/tokensMap.get(v).price_usd/10000), tokensMap.get(v).decimals)),         
                [basicIssuanceModuleContract?.address, sofiManageContract?.address],
                account,
                values.name,
                values.symbol.toUpperCase()
            )
            await contract.create(
                tokens,
                tokens.map((v: any) => parseUnits(toPlainString(tokensMap.get(v).percent * values.startPrice/tokensMap.get(v).price_usd/10000), tokensMap.get(v).decimals)),
                [basicIssuanceModuleContract?.address, sofiManageContract?.address],
                account,
                values.name,
                values.symbol.toUpperCase(),
                {
                    gasLimit: gasEstimate.mul(getGaslimitCount(chainId ?? 137)).div(BigNumber.from(10))
                }
            ).then((res: any) => {
                addTransaction(res, {
                    summary: t("create_portfolio_success") 
                })
                // uploadIconAndIntro(res.hash, values.intro)
                console.log('res:', res);
            }).catch((error: any) => {
                console.error('Create Portfolio Failed', error);
                setFailed(true)
                setLoading(false);
            })
            contract.on("PortfolioCreated",  async (res) => {
                setLoading(false);
                setTxHash(res)
            })
        }
    };

    const uploadIconAndIntro = async (txid: string, intro: string) => {
        if(account && chainId && library){
            const res = await postPortfolioProfile({
                txid,
                intro,
                icon: imageUrl,
            }, account, chainId, library);
            if(res){
                message.success('上传成功')
            }
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
      
    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const handleImgChange = (info: any) => {
        if (info.file.status === 'uploading') {
          getBase64(info.file.originFileObj, (url: any) => { 
            setImageUrl(url);
          });
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (url: any) => { 
            setImageUrl(url);
          });
        }
    };

    const uploadButton = (
        <div className='upload-btn'>
          {loading ? <LoadingOutlined style={{color: '#D0CDF0', fontSize: '14px'}}/> : <PhotoAddIcon />}
        </div>
      );
      

    return (
        <STS>
            <Container className='create-portfolio'>
                <Title>{!account ? 'You need to connect your wallet to use this function' : param ? `What are tokens related to "${param}"`:t("create_portfolio")}</Title>
                <h3>Disclaimer</h3>
                <p>Please note that any portfolio token created by a user on our platform does not inherently contain any value. The value of a portfolio token is solely determined by market activity, meaning it gains value only when other users choose to buy or sell it. The initial creation of a portfolio token does not guarantee or imply any financial value. As with any investment, the value can fluctuate based on demand and market conditions. Users are advised to understand these dynamics and participate accordingly.</p>
                {searchMessage && <p className='message'>
                    <ReactMarkdown>{searchMessage}</ReactMarkdown>
                </p>}
                {
                    
                }
                {!account ? 
                <div style={{textAlign: 'center'}}><Button type="primary" onClick={toggleWalletModal}>
                  {t('conect_wallet')}
                </Button></div>
                :param && loadingSearch && <Spin tip="Loading" size="large">
                        <div className="content" />
                    </Spin>
                }
                {width && width >= 768 && !param && !loadingSearch  && 
                    <Steps style={{marginBottom: '50px'}} current={current}>
                        <Step title={t("add_tokens")}/>
                        <Step title={t("complete_portfolio_info")}/>
                        <Step title={t("publish")}/>
                    </Steps>
                }
                {!loadingSearch && 
                    <Content>
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            layout="vertical"
                            hideRequiredMark
                            className="form"
                            form={form}
                        >
                            <Tabs activeKey={`${current}`} >
                                <TabPane tab="" key="0">
                                    {width && width < 768 && 
                                        <Steps size="small" current={0}>
                                            <Step title={t("add_tokens")} />
                                        </Steps>
                                    }
                                    <Form.Item
                                        rules={[
                                            
                                            { required: true, message: t("please_select_tokens") }
                                        ]}
                                        initialValue={[]}
                                        name="tokens"
                                    >
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            style={{ width: '100%'}}
                                            placeholder={t("select_tokens")}
                                            optionFilterProp="symbol"
                                            filterOption={(input: any, option: any) =>{
                                                console.log('option:', option);
                                                    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            }
                                            onChange={handleChange}
                                            notFoundContent={null}
                                        >
                                            {tokens.map((d: any) => <Option key={d.symbol} value={d.address}><img className="tokenAvator" src={getTokenLogoURL(d.symbol)}/>{d.symbol}</Option>)}
                                        </Select>
                                    </Form.Item>
                                    <div className='tokens'>
                                        {
                                            selectedTokens.map((token: any) => <TokenCard key={token.address} percentData={tokensMap.get(token.address)} validateData={percentInput[token.address]} data={token}/>)
                                        }
                                    </div>
                                    {/* {width && width >= 768 && <Table className="marketTable" scroll={{ x: 500 }} columns={columns} dataSource={selectedTokens} pagination={false} rowKey="address"/>}
                                    {width && width < 768 && <Table className="marketTable" columns={columnsH5} dataSource={selectedTokens} pagination={false} rowKey="address"/>}
                                    {width && width < 768 && 
                                        <Steps style={{marginTop: '30px'}} size="small" current={-1} initial={1} direction="vertical">
                                            <Step title={t("complete_portfolio_info")} />
                                            <Step title={t("publish")} />
                                        </Steps>
                                    } */}
                                </TabPane>
                                <TabPane tab="" key="1">
                                    {width && width < 768 && 
                                        <Steps size="small" current={1} direction="vertical">
                                            <Step title={t("add_tokens")} />
                                            <Step title={t("complete_portfolio_info")} />
                                        </Steps>
                                    }
                                    <Form.Item
                                        label={<>{t("name_the_portfolio")}<p>{t("name_the_portfolio_detail")}</p></>}
                                        rules={[{ required: true, message: t("please_select_tokens") }]}
                                        name="name"
                                    >
                                        <Input placeholder={t("name_placeholder")}/>
                                    </Form.Item>
                                    <Form.Item
                                        label={<>{t("create_symbol")}<p>{t("create_symbol_detail")}</p></>}
                                        name="symbol"
                                        rules={[{ required: true, message: t("please_input_your_username") }]}
                                    >
                                        <Input placeholder={t("symbol_placeholder")} style={{textTransform: 'uppercase'}}/>
                                    </Form.Item> 
                                    <Form.Item
                                        label={<>{t("please_enter_the_starting_price")}<p>{t("starting_price_detail")}</p></>}
                                        name="startPrice"
                                        initialValue={100}
                                        rules={[
                                            { required: true, message: t("please_input_starting_price") },
                                            { pattern: inputRegex, message: t("please_input_right_staring_price")}
                                        ]}
                                        validateStatus={startPriceInput?.validateStatus}
                                        help={startPriceInput?.errorMsg}
                                    >
                                        <Input style={{ width: '100%' }} onChange={(e: any) => {handleStartPriceChange(e.target.value)}}/>
                                    </Form.Item>
                                    {/* <hr />
                                    <Form.Item
                                        label={<>{t('upload_an_icon_for_the_asset')}<p>{t('size_is_less_than_1MB')}</p></>}
                                    >
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={beforeUpload}
                                            onChange={handleImgChange}
                                            
                                        >
                                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        label={<>{t('portfolio_introduction')}<p>{t('introduce_your_investment_strategy')}</p></>}
                                        name="intro"
                                    >
                                        <TextArea rows={1} placeholder={t('less_than_800_words')} maxLength={800} allowClear showCount autoSize={{ minRows: 1, maxRows: 7 }}/>
                                    </Form.Item>
                                    {width && width < 768 && 
                                        <Steps style={{marginTop: '30px'}} size="small" current={-1} initial={2} direction="vertical">
                                            <Step title={t("publish")} />
                                        </Steps>
                                    } */}
    
                                </TabPane>
                                <TabPane tab="" key="2">
                                    {width && width < 768 && 
                                        <Steps size="small" current={2} direction="vertical">
                                            <Step title={t("add_tokens")} />
                                            <Step title={t("complete_portfolio_info")} />
                                            <Step title={t("publish")} />
                                        </Steps>
                                    }
                                    {!txHash && !loading && 
                                        <>
                                            <Card
                                                title={
                                                    <div className='tokenInfo'>
                                                        <Avatar size={64} icon={!imageUrl && <UserOutlined />} src={imageUrl} />
                                                        <div><div>{form.getFieldValue('symbol')}</div><div>{form.getFieldValue('name')}</div></div>
                                                    </div>
                                                }
                                                extra={`$${numeral(form.getFieldValue('startPrice')).format('0,0.00')}`}
                                            >
                                                <ul className="tokenList">
                                                    {
                                                        selectedTokens.map((token: any) => 
                                                        <li>
                                                            <div>
                                                                <img className="tokenAvator" src={getTokenLogoURL(token.symbol)}/>
                                                                {token?.symbol}&nbsp;{token?.pair?.token1?.name}
                                                            </div>
                                                            <Progress width={44} strokeWidth={4} strokeColor={'#14B8A6'} type="circle" format={percent => `${percent}%`} percent={tokensMap.get(token.address)?.percent/100} />
                                                        </li>
                                                        ) 
                                                    }
                                                </ul>
                                            </Card>
                                            <h2>{t('the_contract_is_about_to_be_created')}</h2>
                                            {/* <div style={{textAlign: 'center'}}>
                                                <Checkbox onChange={handleAgreeChange}>
                                                    {t("agree_terms1")} <a href="">{t("agree_terms2")}</a>
                                                </Checkbox>
                                            </div> */}
                                        </>
                                    }
                                </TabPane>
                                <TabPane tab="" key="3">
                                    {width && width < 768 && 
                                        <Steps size="small" current={3} direction="vertical">
                                            <Step title={t("add_tokens")} />
                                            <Step title={t("complete_portfolio_info")} />
                                            <Step title={t("publish")} />
                                        </Steps>
                                    }
                                    {loading && <Result
                                        icon={<LoadingOutlined />}
                                        title={t("the_transaction_is_being_processed")}
                                        extra={<Button type="default" disabled={!txHash}><a href={`https://rinkeby.etherscan.io/tx/${txHash}`} target="_blank">{t("view_transaction_detail")}</a></Button>}
                                    />}
                                    {txHash && !loading && <Result
                                        status="success"
                                        title={t("the_transaction_successed")}
                                        icon={<SuccessIcon/>}
                                        extra={<Button type="default"><Link to={`/sts/profile/${account}/3`}>{t("check_detail")}</Link></Button>}
                                    />}
                                    {failed && !loading && <Result
                                        status="error"
                                        title={t("the_transaction_failed")}
                                        icon={<FailedIcon/>}
                                        extra={<><Button type="default" onClick={() => {history.push('/sts')}}>{t('cancel')}</Button><Button type="primary" onClick={() => {setCurrent(0)}}>{t("create_again")}</Button></>}
                                    />}
                                </TabPane>
                            </Tabs>
                            <StepActions>
                                {/* {current === 2 && (
                                    <Button type="default" onClick={() => message.success('Processing complete!')}>
                                        View transaction detail
                                    </Button>
                                )} */}
                                <div>
                                    {current >= 0 && current <= 2 && <Button type="default" onClick={() => {history.push('/sts')}}>{t('cancel')}</Button>}
                                </div>
                                <div>
                                    {current > 0 && current <= 2 && (
                                        <Button onClick={() => prev()}>
                                        {t("prev")} 
                                        </Button>
                                    )}
                                    {current >= 0 && current < 2 && (
                                        <Button type="primary" style={{marginLeft: `${current === 0 ? '0px': '8px'}`}} onClick={() => {next()}}>
                                            {t("next")}
                                        </Button>
                                    )}
                                    {current === 2 && (
                                        <Button type="primary" style={{marginLeft: '8px'}} htmlType="submit" disabled={!agree}>
                                            {t("publish")}
                                        </Button>
                                    )}
                                </div>
                            </StepActions>
                        </Form>
                    </Content>
                }
            </Container>
        </STS>
    )
}
