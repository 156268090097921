import React, { useEffect, useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Select, Button, Tooltip, notification, Statistic } from 'antd'
import numeral from 'numeral';
import { ETHER, Currency, Fraction, JSBI, TokenAmount, Token, CurrencyAmount } from '@uniswap/sdk'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";
import useThrottle from "@rooks/use-throttle"
import { ethers } from 'ethers'
import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits, formatUnits } from '@ethersproject/units'
import { TransactionResponse } from '@ethersproject/providers'
import { ChainId } from 'constants/chainId';
import { ReactComponent as RightArrow } from '../../assets/svg/rightArrow.svg'
import { Input as NumericalInput } from '../../components/NumericalInput'
import { toPlainString } from '../../utils'
import { useSetsInfo, useTokens, Component } from './hooks'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { tryParseAmount } from '../../state/swap/hooks'
import { useActiveWeb3React } from '../../hooks'
import { TUSDT, trading, RAIProxy_contract_address, managerModule, getGaslimitCount, portfolioFactory, GAS_TOKEN, getChain } from '../../constants'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { useRAIProxyContract, useSetTokenContract, useSofiManageContract, useTradeModuleContract, useSofiPromoteContract } from '../../hooks/useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useCurrency, useToken } from '../../hooks/Tokens'
import PromoteModal from './PromoteModal';

import './STSBuy.less'

const { Option } = Select;
const { Countdown } = Statistic;
const inputRegex = /^\d+(\.\d{1,8})?$/

export default function STSBuy({ contractAddress, type }: any) {

  const findTokenBySymbol = (symbol: string): Token => {
    let innerSymbol = symbol
    if (symbol === "MATIC") innerSymbol = "WMATIC"
    if (symbol === "ETH") innerSymbol = "WETH"
    if (!tokens.length) return TUSDT[chainId || 137]
    const tokenInfo = tokens.find((token: any) => {
      return token.symbol === innerSymbol
    })
    return new Token(chainId || 137, tokenInfo.address, tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name)
  }
  const getTokensInfo = (code: string): any => {
    if (!tokens || !code) return {}
    let innerSymbol = code
    if (code === "MATIC") innerSymbol = "WMATIC"
    if (code === "ETH") innerSymbol = "WETH"
    return tokens.find((token: any) => token.symbol === innerSymbol)
  }

  const { t } = useTranslation();
  const { account, chainId } = useActiveWeb3React()
  const setsInfo = useSetsInfo(contractAddress)
  const { tokens }  = useTokens()

  const [buySetsAmount, setBuySetsAmount] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [sellSetsAmount, setSellSetsAmount] = useState("");
  const [payToken, setPayToken] = useState("");
  const [sellReceiveToken, setSellReceiveToken] = useState("");
  const [sellReceiveAmount, setSellReceiveAmount] = useState("");
  const [showPromoteModal,  setShowPromoteModal] = useState(false)
  // const [USDTPrice, setUSDTPrice] = useState(BigNumber.from(0));
  const userPayTokenBalance = useCurrencyBalance(account ?? undefined, payToken === "ETH" || payToken === "MATIC" ? Currency.ETHER :
  findTokenBySymbol(payToken) ?? undefined)
  const setsCurrencyInfo = useToken(contractAddress)
  const setsTokenBalance = useCurrencyBalance(account ?? undefined, setsCurrencyInfo ?? undefined)
  const parseBuySetsAmount = tryParseAmount(paymentAmount, findTokenBySymbol(payToken))
  const [approval, approveCallback] = useApproveCallback(payToken === "ETH" || payToken === "MATIC" ? CurrencyAmount.ether(JSBI.BigInt(100000000000000000000)) :
  parseBuySetsAmount, trading[chainId ? chainId: 4])
  const parseSellSetsAmount = tryParseAmount(sellSetsAmount, setsCurrencyInfo ?? undefined)
  const [approvalSetsToken, approveSetsTokenCallback] = useApproveCallback(parseSellSetsAmount, trading[chainId || ChainId.MAINNET])
  const setTokenInfo = useSetTokenContract(contractAddress)
  const tradeModuleContract = useTradeModuleContract(trading[chainId ? chainId : 4])
  const addTransaction = useTransactionAdder()
  const [manageInputToken, setManageInputToken] = useState("");
  const [manageOutputToken, setManageOutputToken] = useState("");
  const [manageInputAmount, setManageInputAmount] = useState("");
  const [manageOutputAmount, setManageOutputAmount] = useState("");
  const RAIProxy = useRAIProxyContract(RAIProxy_contract_address[chainId || ChainId.RINKEBY])
  const [selectManageRouter, setSelectManageRouter] = useState("")
  const sofiManageContract = useSofiManageContract(managerModule[chainId ? chainId : 4])
  let notManage = false
  if (Object.keys(setsInfo).length) {
    notManage = setsInfo?.components[0]?.amount === 0
  }
  let location = useLocation();
  let pageName = ""
  if (location.pathname.indexOf("/sts/buy") > -1) {
    pageName = "buy"
  }
  if (location.pathname.indexOf("/sts/sell") > -1) {
    pageName = "sell"
  }
  if (location.pathname.indexOf("/sts/manage") > -1) {
    pageName = "manage"
  }

  useEffect(() => {
    if(chainId){
      setPayToken(GAS_TOKEN[chainId][0])
      setSellReceiveToken(GAS_TOKEN[chainId][0])
    }
  },[chainId])


  const handleChange = (value: any) => {
    setPayToken(value)
    setBuySetsAmount("")
  }

  const openNotification = (message: string | undefined, content: string) => {
    notification["error"]({
      message: message,
      description:
        content
    });
  }

  const calRate = useCallback((isUSDT: boolean, percent: string, setsAmount: string, useToken: string) => {
    if(tokens.length > 0) {
      const tokenPrice = tokens.find((token: any) => {
        let rename = useToken
        if (useToken === "ETH") rename = "WETH"
        if (useToken === "MATIC") rename = "WMATIC"
        return token.symbol === rename
      }).price_usd

      return parseUnits(percent, 4)
        .mul(parseUnits(String(setsInfo.value_per_set.toFixed(8)), 8).div(BigNumber.from(10).pow(2)))
        .mul(parseUnits(setsAmount ?? "0", setsCurrencyInfo?.decimals))
        .div(parseUnits(String(tokenPrice), 8).div(BigNumber.from(10).pow(2)))
        .mul(BigNumber.from(10).pow(findTokenBySymbol(useToken).decimals))
        .div(BigNumber.from(10).pow(4))
        .div(BigNumber.from(10).pow(18))
    }else{
      return parseUnits(percent, 4)
      .mul(parseUnits(String(setsInfo.value_per_set.toFixed(8)), 8))
      .mul(parseUnits(setsAmount ?? "0", setsCurrencyInfo?.decimals))
      .div(BigNumber.from(10).pow(4))
      .div(BigNumber.from(10).pow(2))
      .div(BigNumber.from(10).pow(18))
    }  
  }, [tokens, setsInfo, setsCurrencyInfo])

  useEffect(() => {
    if (!setsInfo.value_per_set || tokens.length <= 0) return
    if (pageName === "manage") return
    if (payToken === "USDT") {
      if (buySetsAmount) {
        const rate = calRate(true, "1.1", buySetsAmount, payToken)
        setPaymentAmount(numeral(formatUnits(rate, 6)).format('0,0.0000'))
      } else {
        setPaymentAmount("")
      }
    } else {
      if (buySetsAmount) {
        const rate = calRate(false, "1.1", buySetsAmount, payToken)
        setPaymentAmount(numeral(formatUnits(rate, getTokensInfo(payToken).decimals)).format('0,0.0000'))
      } else {
        setPaymentAmount("")
      }
    }
  }, [ buySetsAmount, tokens.length ])
  useEffect(() => {
    if (sellReceiveToken === "USDT") {
      if (sellSetsAmount) {
        const rate = calRate(true, "1", sellSetsAmount, sellReceiveToken)
        setSellReceiveAmount(numeral(formatUnits(rate, 6)).format('0,0.00'))
      } else {
        setSellReceiveAmount("")
      }
    } else {
      if (sellSetsAmount) {
        const rate = calRate(false, "1", sellSetsAmount, sellReceiveToken)
        setSellReceiveAmount(numeral(formatUnits(rate, findTokenBySymbol(sellReceiveToken).decimals)).format('0,0.00'))
      } else {
        setSellReceiveAmount("")
      }
    }
  }, [ sellSetsAmount, tokens.length ])
  const handleBuy = async () => {
    if (type === 'buy') {
      if (!buySetsAmount) return
      if (tradeModuleContract && setTokenInfo) {
        if (payToken !== "ETH" && payToken !== "MATIC") {
          // pay ERC20
          const gasEstimate = await tradeModuleContract.estimateGas.buyExactPortfolioFromToken(
            contractAddress,
            findTokenBySymbol(payToken).address,
            ethers.utils.parseUnits(buySetsAmount, setsInfo.decimals),
            calRate(payToken === "USDT", "1.1", buySetsAmount, payToken)
          );
          await tradeModuleContract
          .buyExactPortfolioFromToken(
            contractAddress,
            findTokenBySymbol(payToken).address,
            ethers.utils.parseUnits(buySetsAmount, setsInfo.decimals),
            calRate(payToken === "USDT", "1.1", buySetsAmount, payToken),
            {
              gasLimit: gasEstimate.mul(getGaslimitCount(chainId ?? 137)).div(BigNumber.from(10))
            })
          .then((response: TransactionResponse) => {
            console.log("response", response)
            addTransaction(response, {
              summary: `Buy token`
            })
          })
          .catch((error: any) => {
            openNotification("", error.message)
            console.log(error)
          })
        } else {
          // pay use ETH

          const gasEstimate = await tradeModuleContract.estimateGas.buyExactPortfolioFromETH(
            contractAddress,
            ethers.utils.parseUnits(buySetsAmount, setsInfo.decimals),
            {
              "value": ethers.utils.parseEther(paymentAmount)
            })
          await tradeModuleContract
            .buyExactPortfolioFromETH(
              contractAddress,
              ethers.utils.parseUnits(buySetsAmount, setsInfo.decimals),
              {
                "value": ethers.utils.parseEther(paymentAmount),
                "gasLimit": gasEstimate.mul(getGaslimitCount(chainId ?? 137)).div(BigNumber.from(10))
              }
            )
            .then((response: TransactionResponse) => {
              console.log("response", response)
              addTransaction(response, {
                summary: `Buy token`
              })
            })
            .catch((error: any) => {
              openNotification("", error.message)
              console.log(error)
            })
        }
      }
    } else if (type === "sell") {
      if (tradeModuleContract) {
        if (sellReceiveToken !== "ETH" && sellReceiveToken !== "MATIC") {
          const gasEstimate = await tradeModuleContract.estimateGas.sellExactPortfolioForToken(
            contractAddress,
            findTokenBySymbol(sellReceiveToken).address,
            ethers.utils.parseUnits(sellSetsAmount, setsCurrencyInfo?.decimals),
            0
          )
          await tradeModuleContract
          .sellExactPortfolioForToken(
            contractAddress,
            findTokenBySymbol(sellReceiveToken).address,
            ethers.utils.parseUnits(sellSetsAmount, setsCurrencyInfo?.decimals),
            0
          , {
            gasLimit: gasEstimate.mul(getGaslimitCount(chainId ?? 137)).div(BigNumber.from(10)) 
          })
          .then((response: TransactionResponse) => {
            console.log("response", response)
            addTransaction(response, {
              summary: `sell token`
            })
          })
          .catch((error: any) => {
            alert(error.message)
            console.log(error)
          })
        } else {
          const gasEstimate = await tradeModuleContract.estimateGas.sellExactPortfolioForETH(
            contractAddress,
            ethers.utils.parseEther(sellSetsAmount),
            0
          )
          await tradeModuleContract
            .sellExactPortfolioForETH(
              contractAddress,
              ethers.utils.parseEther(sellSetsAmount),
              0,
              {
                gasLimit: gasEstimate.mul(getGaslimitCount(chainId ?? 137)).div(BigNumber.from(10))
              }
            )
            .then((response: TransactionResponse) => {
              console.log("response", response)
              addTransaction(response, {
                summary: `sell token`
              })
            })
            .catch((error: any) => {
              alert(error.message)
              console.log(error)
            })
        }
      }
    } else if (type === "manage") {
      if (sofiManageContract) {
      const gasEstimate = await sofiManageContract.estimateGas.trade(
        portfolioFactory[chainId ? chainId: 4],
        contractAddress,
        "SOFIProxy",
        selectManageRouter,
        findTokenBySymbol(manageInputToken).address,
        parseUnits(Number(manageInputAmount).toFixed(findTokenBySymbol(manageInputToken).decimals), findTokenBySymbol(manageInputToken).decimals).mul(BigNumber.from(10).pow(18))
        .div(parseUnits(String(setsInfo.total_amount), 18)),
        findTokenBySymbol(manageOutputToken).address,
        0,
        "0x"
      )
      await sofiManageContract?.trade(
        portfolioFactory[chainId ? chainId: 4],
        contractAddress,
        "SOFIProxy",
        selectManageRouter,
        findTokenBySymbol(manageInputToken).address,
        parseUnits(Number(manageInputAmount).toFixed(findTokenBySymbol(manageInputToken).decimals), findTokenBySymbol(manageInputToken).decimals).mul(BigNumber.from(10).pow(18))
        .div(parseUnits(String(setsInfo.total_amount), 18)),
        findTokenBySymbol(manageOutputToken).address,
        0,
        "0x",
        {
          gasLimit: gasEstimate.mul(getGaslimitCount(chainId ?? 137)).div(BigNumber.from(10))
        }
      )
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `manage sets`
        })
      })
      .catch((error: any) => {
        alert(error.message)
        console.log(error)
      })
    }
    }
  }

  const [ buyThrottle ] = useThrottle(handleBuy, 10000)

  const getSetsTokenInfo = (code: string): any => {
    if (!setsInfo || !code) return {}
    const resultTokenInfo = setsInfo?.components.find((component: Component) => component.symbol === "USDT")
    if (code === "USDT") {
      if (!resultTokenInfo) {
        return TUSDT
      }
    }
    return setsInfo?.components.find((component: Component) => component.symbol === code)
  }
  const calSwapRate = (isUSDT: boolean, manageInputToken: string, manageInputAmount: string) => {
    if (isUSDT) {
      return parseUnits(String(getSetsTokenInfo(manageInputToken).price_usd), 18)
        .mul(parseUnits(manageInputAmount, 18))
        .div(BigNumber.from(10).pow(18))
    } else {
      return parseUnits(manageInputAmount, 18)
        .mul(BigNumber.from(10).pow(18))
        .div(parseUnits(String(getTokensInfo(manageOutputToken)?.price_usd), 18))
    }
  }

  const getToFixed = (num: number, decimal: number | undefined = 2) => {
    return Math.floor(num * Math.pow(10, decimal)) / Math.pow(10, decimal)
  }

  const tokenSelectOption = useCallback((tokens: any) => {
    const symbols = tokens.map((v: any) => v.symbol)
    return (
      <>
        {tokens
        .map((token: any) => {
          return <Option key={token?.address} value={token.symbol}><CurrencyIcon src={getTokenLogoURL(token.symbol)}/>{token?.symbol}</Option>
        })}
        {
          chainId && GAS_TOKEN[chainId].map((token: string) => {
            return !symbols.includes(token) ? <Option value={token}><CurrencyIcon src={getTokenLogoURL(token)}/>{token}</Option> : null
          })
        }
      </>
    )
  }, [chainId])


  const managerTokenSelectOption = useCallback((tokens: any, inputToken: string) => {
    if(!chainId){
      return null
    }
    const chain = getChain(chainId)
    const opitons = inputToken === chain?.wrappedTokenSymbol ? tokens.filter((v: any) => v.symbol !== chain?.wrappedTokenSymbol) : [chain?.wrappedToken] 
    return (
      <>
        {opitons
        .map((token: any) => {
          return <Option key={token?.address} value={token.symbol}><CurrencyIcon src={getTokenLogoURL(token.symbol)}/>{token?.symbol}</Option>
       })}
      </>
    )
  }, [chainId])

  useEffect(() => {
    if (!manageInputAmount) {
      setManageOutputAmount("")
      return
    }
    if (manageInputToken && manageOutputToken && RAIProxy && Number(manageInputAmount) > 0) {
      RAIProxy
      .getUniV2Routers(
        parseUnits(Number(manageInputAmount)?.toFixed(findTokenBySymbol(manageInputToken).decimals), findTokenBySymbol(manageInputToken).decimals),
        findTokenBySymbol(manageInputToken).address,
        findTokenBySymbol(manageOutputToken).address
      ).then((res: any) => {
        const maxOutputAmount = res[1].reduce((preAmount: BigNumber, nextAmount: BigNumber) => {
          return preAmount.gt(nextAmount) ? preAmount : nextAmount
        })
        const findRouter = res[0][res[1].findIndex((outputAmount: BigNumber) => outputAmount.eq(maxOutputAmount))]
        setSelectManageRouter(findRouter)
        setManageOutputAmount(formatUnits(maxOutputAmount, findTokenBySymbol(manageOutputToken).decimals))
      })
    }
  }, [manageInputAmount])
  return (
      <div className='stsBuy'>
        <StsBuy>
          {/* <SetsInfo>
            <InfoLeft>
              <SetsDescription>
                <SetsName>
                  {setsInfo.name}
                  <SetsSymbol>
                    {setsInfo.symbol}
                  </SetsSymbol>
                </SetsName>
                <SetsRates>
                  {setsInfo?.components?.map((token: Component) => {
                    return (
                      <TokenWraper key={token.symbol}>
                        <img className="tokenAvator" src={getTokenLogoURL(token.symbol)}/>
                        <TokenName>
                          {token.name} 
                        </TokenName>
                        <TokenRate>
                          {(token.value_per_set / setsInfo.value_per_set * 100).toFixed(2)}%
                        </TokenRate>
                      </TokenWraper>
                    )
                  })}
                </SetsRates>
              </SetsDescription>
            </InfoLeft>
            <InfoRight>
              <PriceTitle>
                {t("current_price")}
              </PriceTitle>
              <PriceValue>
                ${setsInfo?.value_per_set ? numeral(setsInfo.value_per_set).format('0,0.00') : ""}
              </PriceValue>
            </InfoRight>
          </SetsInfo> */}
          {type === "buy" ? (
            <PayInfo>
              <PayRight>
                <BalanceTitle>{t("avaliable_balance")}</BalanceTitle>
                <BalanceValue>{userPayTokenBalance?.toExact() ? numeral(toPlainString(userPayTokenBalance?.toExact())).format('0,0.000000') : '-'} {payToken}</BalanceValue>
              </PayRight>
            </PayInfo>
          ) : ""}
          {type === "buy" ? (
            <InputWraper>
              <div className='input-item'>
                <InputTitle>{t("buy")}</InputTitle>
                <InputCotent>
                  <NumericalInput
                    className="token-amount-input"
                    value={buySetsAmount}
                    onUserInput={val => {
                      setBuySetsAmount(val)
                    }}
                  />
                  <Symbol>
                    {setsInfo.symbol}
                  </Symbol>
                </InputCotent>
              </div>
              <div className='input-item'>
                <InputTitle>
                  {t("you_need_pay")}
                  <Tooltip placement="top" title={"Due to transaction time and price fluctuation, the amount you need to pay will be higher than the current price. The remaining amount will be refunded to your account once the transaction is completed."}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </InputTitle>
                <Select
                    // defaultValue={payToken}
                    value={payToken}
                    onChange={handleChange}
                  >
                    {tokenSelectOption(tokens)}
                </Select>
                <InputCotent>
                  <NumericalInput
                    className="token-amount-input"
                    readOnly
                    value={paymentAmount}
                    onUserInput={val => {
                      setPaymentAmount(val)
                    }}
                  />
                  <Symbol>
                    {payToken}
                  </Symbol>
                </InputCotent>
              </div>
            </InputWraper>
          ) : "" }
          {type === "sell" ? (
            <ManageInputWraper>
              <div className='input-item'>
                <ManageInputLeft>
                  <NumericalInput
                    className="token-amount-input"
                    value={sellSetsAmount}
                    onUserInput={val => {
                      setSellSetsAmount(val)
                      if(!val) setSellReceiveAmount("")
                    }}
                  />
                  {/* <TokenValue>${sellSetsAmount !== "" ? USDTPrice.multiply(JSBI.BigInt(parseUnits(sellSetsAmount))).divide(JSBI.BigInt(10 ** 18)).toFixed(2).toString() : ""} </TokenValue> */}
                </ManageInputLeft>
                <ManageInputRight>
                  <SelectWraper>
                    <MaxButton onClick={() => {
                      if(setsTokenBalance) setSellSetsAmount(setsTokenBalance?.toExact().toString() || "")
                      // setSellReceiveAmount(new Fraction(JSBI.BigInt(parseUnits(setsTokenBalance?.toSignificant(4).toString() || ""))).multiply(USDTPrice).divide(new Fraction(JSBI.BigInt(10 ** 18))).toFixed(3).toString())
                    }}>{t("max")}</MaxButton>
                    <SetsTag>
                      {setsCurrencyInfo?.symbol}
                    </SetsTag>
                  </SelectWraper>
                  <BalanceWraper>
                    {t("balance")}: {numeral(setsTokenBalance?.toFixed(6)).format('0,0.000000')} {setsCurrencyInfo?.symbol}
                  </BalanceWraper>
                </ManageInputRight>
              </div>
              <div className='input-item'>
                <ManageInputLeft>
                  <NumericalInput
                    className="token-amount-input"
                    value={sellReceiveAmount}
                    onUserInput={val => {
                      setSellReceiveAmount(val)
                    }}
                  />
                  {/* <TokenValue>${sellReceiveToken === "USDT" ? sellReceiveAmount : sellReceiveAmount ?
                    (getTokenPrice(sellReceiveToken) * Number(sellReceiveAmount)).toFixed(2) : "" }
                  </TokenValue> */}
                </ManageInputLeft>
                <ManageInputRight>
                  <SelectWraper>
                    {/* <MaxButton>Max</MaxButton> */}
                    <Select
                      value={sellReceiveToken}
                      style={{ width: 120 }}
                      onChange={(val: string) => {
                        setSellReceiveToken(val)
                        setSellSetsAmount("")
                      }}
                    >
                      {tokenSelectOption(tokens)}
                    </Select>
                  </SelectWraper>
                  <BalanceWraper>
                    {/* Balance: 30.33333 DEFI */}
                    {/* ---- */}
                  </BalanceWraper>
                </ManageInputRight>
              </div>
            </ManageInputWraper>
          ) : ""}
          {type === 'buy' && (
            Number(userPayTokenBalance?.toExact()) < Number(paymentAmount) ? 
            <Button
              type="primary"
              disabled
              block
              size="large"
              onClick={buyThrottle}
            >
              {t("insufficient_balance")}
            </Button> :
              (approval !== ApprovalState.APPROVED ? (
                <Button
                  type="primary"
                  block
                  size="large"
                  onClick={() => approveCallback()}
                  disabled={
                    approval === ApprovalState.PENDING ||
                    approval === ApprovalState.UNKNOWN
                  }
                >{t("approve")}</Button>
              ) : (
                Number(buySetsAmount) <= 0 ? (
                  <Button
                    type="primary"
                    disabled
                    block
                    size="large"
                    onClick={buyThrottle}
                  >
                    {t("enter_an_amount")}
                  </Button>
                ) : calRate(payToken === "USDT", "1.1", buySetsAmount, payToken)
                  .gt(parseUnits(userPayTokenBalance?.toExact() || "", getTokensInfo(payToken).decimals)) ? (
                  <Button
                    type="primary"
                    disabled
                    block
                    size="large"
                    onClick={buyThrottle}
                  >
                    {t("insufficient_balance")}
                  </Button>
                  ): (
                    <Button
                      type="primary"
                      block
                      size="large"
                      onClick={buyThrottle}
                    >
                      {t("buy")}
                    </Button>
                  )
              )
            )
          )}
          {type === 'sell' && (
             setsTokenBalance?.toExact() === '0' && !setsCurrencyInfo?.decimals ? 
            <Button
              type="primary"
              disabled
              block
              size="large"
              onClick={buyThrottle}
            >
              {t("insufficient_balance")}
            </Button> : ( Number(sellSetsAmount) > 0  &&  setsCurrencyInfo?.decimals ? 
            (parseUnits(sellSetsAmount, setsCurrencyInfo?.decimals)
              .gt(parseUnits(setsTokenBalance?.toExact() || '', setsCurrencyInfo?.decimals)) ?
              <Button
                type="primary"
                disabled
                block
                size="large"
                onClick={buyThrottle}
              >
                {t("insufficient_balance")}
              </Button>
              :
              (approvalSetsToken !== ApprovalState.APPROVED ? 
                <Button
                  type="primary"
                  block
                  size="large"
                  onClick={() => approveSetsTokenCallback()}
                  disabled={
                    approvalSetsToken === ApprovalState.PENDING ||
                    approvalSetsToken === ApprovalState.UNKNOWN
                  }
                >{t("approve")}</Button>
              : 
              <Button
                type="primary"
                block
                size="large"
                onClick={handleBuy}
              >
                {t("sell")}
              </Button>
              )
            )
            :
            <Button
              type="primary"
              disabled
              block
              size="large"
              onClick={buyThrottle}
            >
              {t("enter_an_amount")}
            </Button>
          ))}
          {!!setsInfo?.promote_end_at && <Countdown prefix={`${t('promote_end_time')}:  `} value={setsInfo?.promote_end_at * 1000} format="D[d] H[h] m[m] s[s]" />}
          {type === "manage" ? (
            <ManageInputWraper>
              <div className='input-item'>
                <ManageInputLeft>
                  <NumericalInput
                    className="token-amount-input"
                    value={manageInputAmount}
                    onUserInput={val => {
                      setManageInputAmount(val)
                    }}
                  />
                  {/* <TokenValue>${sellSetsAmount !== "" ? USDTPrice.multiply(JSBI.BigInt(parseUnits(sellSetsAmount))).divide(JSBI.BigInt(10 ** 18)).toFixed(2).toString() : ""} </TokenValue> */}
                </ManageInputLeft>
                <ManageInputRight>
                  <SelectWraper>
                    <MaxButton onClick={() => {
                      if (!manageInputToken) return
                      setManageInputAmount(toPlainString(getSetsTokenInfo(manageInputToken).amount))
                    }}>{t("max")}</MaxButton>
                    <Select
                      defaultValue=""
                      value={manageInputToken}
                      style={{ width: 135 }}
                      onChange={(val: string) => {
                        setManageInputAmount("")
                        setManageInputToken(val)
                        if (val === manageOutputToken) {
                          setManageOutputToken("")
                        }
                      }}
                    >
                      <Option value="">{t("select_a_token")}</Option>
                      {setsInfo ? (
                        setsInfo?.components?.map((component: Component) => {
                          return <Option key={component.symbol} value={component.symbol}>
                            <CurrencyIcon src={getTokenLogoURL(component.symbol)}/>
                            {component.symbol}
                          </Option>
                        })
                      ) : ""}
                    </Select>
                  </SelectWraper>
                  <BalanceWraper>
                    {t("balance")}: {manageInputToken ? numeral(toPlainString(getSetsTokenInfo(manageInputToken).amount)).format('0,0.00000000') : "---"} {manageInputToken}
                  </BalanceWraper>
                </ManageInputRight>
              </div>
              <RightArrow className="rightArrow" />
              <div className='input-item'>
                <ManageInputLeft>
                  <NumericalInput
                    className="token-amount-input"
                    value={manageOutputAmount}
                    readOnly
                    onUserInput={val => {
                      setManageOutputAmount(val)
                    }}
                  />
                  {/* <TokenValue>$300.2</TokenValue> */}
                </ManageInputLeft>
                <ManageInputRight>
                  <SelectWraper>
                    {/* <MaxButton>Max</MaxButton> */}
                    <Select
                      defaultValue=""
                      value={manageOutputToken}
                      disabled={!manageInputToken}
                      style={{ width: 135 }}
                      onChange={(val: string) => {
                        setManageOutputToken(val)
                        if (manageInputToken === "USDT") {
                          if (manageInputAmount) {
                            setManageInputAmount("")
                          }
                        }
                      }}
                    >
                      <Option value="">{t("select_a_token")}</Option>
                      {managerTokenSelectOption(tokens, manageInputToken)}
                    </Select>
                  </SelectWraper>
                  <BalanceWraper>
                  </BalanceWraper>
                </ManageInputRight>
              </div>
            </ManageInputWraper>
          ) : ""}
          {
            <DataWraper>
              <DataItem>
                <DataTitle>
                  {t("price")}
                </DataTitle>
                <DataValue>
                  {type === "manage" ? (
                    <>
                      {(manageInputToken && manageOutputToken) ? <>
                        {numeral(formatUnits(calSwapRate(manageOutputToken === "USDT", manageInputToken, "1"), 18)).format('0,0.00')}{" "}
                        {manageOutputToken} Per {manageInputToken}
                      </> : "-"}
                    </>
                  ) : (
                    <>
                      {setsInfo.value_per_set ? numeral(formatUnits(calRate(payToken === "USDT", "1", "1", payToken), findTokenBySymbol(payToken).decimals)).format('0,0.00') : ""} {payToken} per {setsInfo.symbol}
                    </>
                  )}
                </DataValue>
              </DataItem>
              <DataItem>
                <DataTitle>
                  {t("exit_fee")}
                </DataTitle>
                <DataValue>
                  {setsInfo.fee}
                </DataValue>
              </DataItem>
              <DataItem>
                  <DataTitle>
                    {t("slippage_tolerance")}
                  </DataTitle>
                  <DataValue>
                    10%
                  </DataValue>
              </DataItem>
              {type === "manage" && (
                  <Button
                    type="primary"
                    disabled={notManage}
                    block
                    size="large"
                    onClick={handleBuy}
                    style={{height: '100%'}}
                  >
                    {notManage ? t("insufficient_balance") :  t("swap")}
                  </Button>
              )}
              {type === "manage" ? "" : (
                <DataItem>
                  <DataTitle>
                    {t("total_pay")}
                  </DataTitle>
                  <DataValue>
                    {type === "sell" ? (
                      <>{sellSetsAmount || "-"} {setsCurrencyInfo?.symbol}</>
                    ) :
                      <>{paymentAmount || "-"} {payToken}</>
                    }
                  </DataValue>
                </DataItem>
              )}
            </DataWraper>
          }
          {type === "manage" && (
            <div className='btns'>
              <Button
                type="primary"
                block
                size="large"
                onClick={() => {setShowPromoteModal(true)}}
              >
                {t('promote_this_portfolio')}
              </Button>
            </div>
          )}
        </StsBuy>
        <PromoteModal isOpen={showPromoteModal} handleCancel={() => {setShowPromoteModal(false)}} portfolioAddress={contractAddress}/>
      </div>
  )
}

const Bread = styled.div`
  color: #5542F6;
  margin-bottom: 55px;
`

const BreadTitle = styled.span`
  margin-right: 10px;
  font-size: 32px;
`
const LastTitle = styled.span`
  color: #000000;
`
const StsBuy = styled.div`
  padding: 0;
  .ant-select-selection-item {
    text-align: center;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
  `};
  .ant-statistic-content{
    font-size: 16px;
  }
`

const PayInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  // ${({ theme }) => theme.mediaWidth.upToSmall`
  //   flex-direction: column;
  //   align-items: flex-start;
  // `};
`

const PayLeft = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    justify-content: space-between;
  `};
  .ant-select{
    width: 120px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 80px;
    `};
  }
`

const PayRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  `};
`

const PayTitle = styled.span`
  margin-right: 30px;
  font-size: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 10px;
  `}
`

const BalanceTitle = styled.span`
  font-size: 14px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
  `}
`

const BalanceValue = styled.span`
  font-size: 14px;
`

const InputWraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .rightArrow {
      transform: rotate(90deg);
      margin: 10px auto;
    }
  `};
`

const InputTitle = styled.div`
  font-size: 16px;
  color: #84818A;
  display: flex;
  align-items: center;
  svg {
    margin-left: 4px;
  }
`

const Symbol = styled.div`
  font-size: 16px;
  font-weight: 700;
`

const InputCotent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`

const DataWraper = styled.div`
  margin-bottom: 30px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 0;
  `};
`

const DataLeft = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
`

const DataRight = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
`

const DataItem = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

const DataTitle = styled.span`
  font-size: 16px;
  color: #84818A;
`

const DataValue = styled.span`
  font-size: 14px;
`

const ManageInputWraper = styled.div`
  text-align: center;
  .rightArrow {
    transform: rotate(90deg);
    margin: 11px 0;
  }
`

const ManageInputItem = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px;
  `};
`

const ManageInputLeft = styled.div`
  text-align: left;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    .token-amount-input {
      width: 100%;
    }
  `};
`

const ManageInputRight = styled.div`
  text-align: right;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background: none!important;
    border: none!important;
  }
`

const TokenValue = styled.div`
  color: #84818A;
  font-size: 16px;
`

const BalanceWraper = styled.div`
  margin-top: 4px;
`

const SelectWraper = styled.div`
  position: relative;
  .setsTokenTag {
    svg {
      display: none;
    }
  }
`

const MaxButton = styled.span`
  background: #EDECFE;
  color: #5542F6;
  border-radius: 4px;
  padding: 4px 7px;
  margin-right: 19px;
  cursor: pointer;
`

const SetsTag = styled.span`
    border-radius: 2px;
    padding: 7px 11px;
    font-size: 14px;
`

const CurrencyIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 6px;
`