import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Button, Upload, message } from 'antd';
import { useTranslation } from 'react-i18next'
import { LoadingOutlined } from '@ant-design/icons';
import useThrottle from "@rooks/use-throttle"
import { useActiveWeb3React } from '../../hooks'
import { postPortfolioProfile } from './hooks'
import { ReactComponent as PhotoAddIcon } from 'assets/svg/PhotoAdd.svg'

import './EditPortfolioModal.less';

const { TextArea } = Input;

export default function ({isOpen, handleCancel, setForceUpdate, stsInfo}: any){
    const { chainId, account, library } = useActiveWeb3React()
    const [imageUrl, setImageUrl] = useState('');
    const [imageChanged, setImageChanged] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation()
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        if(account && chainId && library){
            if(account && chainId && library){
                let params: any = {
                    manager: stsInfo.manager,
                    portfolio: stsInfo.address,
                    intro: values.intro,
                    icon: imageUrl,
                }
                if(!imageChanged) {delete params.icon};
                const res = await postPortfolioProfile(params, account, chainId, library);
                if(res?.error){
                    message.error(t('setting_failed'))
                }else{
                    message.success(t('setting_success'))
                    form.resetFields()
                    handleCancel()
                    setForceUpdate()
                }
            }
        }
    };

    const [ postThrottle ] = useThrottle(onFinish, 2000)

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
      
    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }


    const handleImgChange = (info: any) => {
        if (info.file.status === 'uploading') {
          getBase64(info.file.originFileObj, (url: any) => { 
            setImageUrl(url);
            setImageChanged(true)
          });
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (url: any) => { 
            setImageUrl(url);
            setImageChanged(true)
          });
        }
    };

    useEffect(() => {
        if(stsInfo.avatar_uri){
            setImageUrl(stsInfo.avatar_uri)
        }
    }, [stsInfo.avatar_uri])
    useEffect(() => {
        if(stsInfo.intro){
            form.setFieldsValue({intro: stsInfo.intro})
        }
    }, [stsInfo.intro])

    const uploadButton = (
        <div className='upload-btn'>
          {loading ? <LoadingOutlined style={{color: '#D0CDF0', fontSize: '14px'}}/> : <PhotoAddIcon />}
        </div>
      );
    
    return (
        <Modal className="editPortfolioModal"  title="Edit Portfolio Info" width={800} visible={isOpen} footer={null} onCancel={handleCancel}>
           <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={postThrottle}
                onFinishFailed={onFinishFailed}
                hideRequiredMark   
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label={<>{t('upload_an_icon_for_the_asset')}<p>{t('size_is_less_than_1MB')}</p></>}
                >
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleImgChange}
                        
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label={<>{t('portfolio_introduction')}<p>{t('introduce_your_investment_strategy')}</p></>}
                    name="intro"
                >
                    <TextArea rows={4} placeholder={t('less_than_800_words')} maxLength={800} allowClear showCount autoSize={{ minRows: 4, maxRows: 7 }}/>
                </Form.Item>
                <Form.Item className='btns'>
                    <Button type="default" onClick={handleCancel}>
                        {t('cancel')}
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {t('edit')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    ) 
}