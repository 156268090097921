import { CurrencyAmount, JSBI, Token, Trade, TokenAmount, Currency } from '@uniswap/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { Button } from 'antd';
import numeral from 'numeral';
import moment from 'moment';
import { BigNumber } from "ethers"
import { formatUnits, parseUnits } from "@ethersproject/units"
import { HistoryOutlined, LineChartOutlined } from '@ant-design/icons';
import { ThemeContext } from 'styled-components'
import { TransactionResponse } from '@ethersproject/providers'
import { ChainId } from 'constants/chainId';
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import TokenWarningModal from '../../components/TokenWarningModal'
import { ChainLogo } from 'components/CurrencyLogo'
import { RAIProxy_contract_address, WETH, WMATIC } from '../../constants/index'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { useRAIProxyContract } from '../../hooks/useContract'
import { useDefaultPairList } from 'state/lists/hooks';
import useDebounce from 'hooks/useDebounce'
import { ApprovalState, useApproveCallback, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
  tryParseAmount
} from '../../state/swap/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useExpertModeManager, useUserSlippageTolerance, useUserSingleHopOnly } from '../../state/user/hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { AggregateChartRangeOption } from 'constants/priceChartEnums'
import { NETWORK_LABELS } from 'constants/index'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { useChartData, useAggregateTicker24hr } from './hook';
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import AggregateModal from './components/AggregateModal'
import STS from '../STS'
import Chart from './Chart';
import { Container } from '../STS/Markets'
import {ReactComponent as SwapIcon} from 'assets/svg/swap.svg'
import {ReactComponent as RefreshIcon} from 'assets/svg/refresh.svg'
import './index.less'

const SearchText = styled.div<{isShowSearchText: boolean}>`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  visibility: ${props => props.isShowSearchText ? "visible" : "hidden"};
`

export default function Swap({ history }: RouteComponentProps) {
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { t } = useTranslation()
  const { chainId, account, connector } = useActiveWeb3React()
  //@ts-ignore
  const RAIProxy = useRAIProxyContract(RAIProxy_contract_address[chainId || ChainId.RINKEBY])
  const [inputValue, setInputValue] = useState<any>('')
  const [outputValue, setOutputValue] = useState<any>('')
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(1);
  const [chartRange, setChartRange] = useState<string>(AggregateChartRangeOption.DAILY_PRICE_RANGE)
  const { pathname } = useLocation();
  const hasChart = pathname === '/aggregateSwap/chart';
  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !Boolean(token.address in defaultTokens)
    })

  const theme = useContext(ThemeContext)
  const [useRouter, setUseRouter] = useState<string>("")
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const addTransaction = useTransactionAdder()

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()

  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v2Trade,
    [Version.v2]: v2Trade
  }

  const trade = showWrap ? undefined : tradesByVersion[toggledVersion]
  const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]

  const betterTradeLinkV2 = Version.v2

  const parsedAmounts = {
    [Field.INPUT]: independentField === Field.INPUT ? inputValue : outputValue,
    [Field.OUTPUT]: independentField === Field.OUTPUT ? inputValue : outputValue
  }
  const inputValueDebounce = useDebounce(parsedAmounts[Field.INPUT], 150)
  const setTokenValueMethod = independentField === Field.INPUT
  ? {
      [Field.INPUT]: setInputValue,
      [Field.OUTPUT]: setOutputValue
    }
  : {
      [Field.INPUT]: setOutputValue,
      [Field.OUTPUT]: setInputValue
  }
  const parseInputAmount = tryParseAmount(parsedAmounts[Field.INPUT], currencies[Field.INPUT])
  //@ts-ignore
  const [inputTokenApproval, inputTokenApproveCallback] = useApproveCallback(parseInputAmount, RAIProxy_contract_address[chainId || ChainId.RINKEBY])
  const pairList = useDefaultPairList();
  const getPair = (symbol: string) => {
    return symbol.split("-").map((tokenName: string) => {
        if (tokenName === "ETH") return "WETH"
        if (tokenName === "MATIC" && chainId === 137) return "WMATIC"
        return tokenName
    }).join("-")
  }

  const getDefaultOtherSymbol= () => {
    if(currencies[Field.INPUT]?.symbol && !currencies[Field.OUTPUT]?.symbol){
      const index = pairList.findIndex((pair: any) => pair.symbol.includes(currencies[Field.INPUT]?.symbol))
      return pairList[index].symbol.replace('-', '').replace(currencies[Field.INPUT]?.symbol, '');
    }
    if(!currencies[Field.INPUT]?.symbol && currencies[Field.OUTPUT]?.symbol){
      const index = pairList.findIndex((pair: any) => pair.symbol.includes(currencies[Field.OUTPUT]?.symbol))
      return pairList[index].symbol.replace('-', '').replace(currencies[Field.OUTPUT]?.symbol, '');
    }
    return ''
  }

  const getSymbol = () => {
    if(currencies[Field.INPUT]?.symbol && currencies[Field.OUTPUT]?.symbol){
      const index = pairList.findIndex((pair: any) => pair.symbol === `${currencies[Field.INPUT]?.symbol}-${currencies[Field.OUTPUT]?.symbol}` || pair.symbol === `${currencies[Field.OUTPUT]?.symbol}-${currencies[Field.INPUT]?.symbol}`)
      return getPair(pairList[index].symbol);
    }else if(currencies[Field.INPUT]?.symbol || currencies[Field.OUTPUT]?.symbol) {
      const index = pairList.findIndex((pair: any) => pair.symbol.includes(currencies[Field.INPUT]?.symbol) || pair.symbol.includes(currencies[Field.OUTPUT]?.symbol))
      return getPair(pairList[index].symbol);
    } else {
      return ''
    }
  }

  const chartData = useChartData(getSymbol(), chartRange, refresh);
  const tickerData = useAggregateTicker24hr(getSymbol(), refresh)
  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = inputValue && outputValue && currencies[Field.INPUT]?.symbol && currencies[Field.INPUT]?.symbol;
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback((value: string) => {
    setTokenValueMethod[Field.INPUT](value)
    onUserInput(Field.INPUT, value)
  },[onUserInput])

  const handleTypeOutput = useCallback((value: string) => {
    setTokenValueMethod[Field.OUTPUT](value)
    onUserInput(Field.OUTPUT, value)
  },[onUserInput])

  const getAddress = (currency: Token | undefined): Token | undefined => {
    if (!chainId) return undefined
    if (currency?.symbol === "ETH") {
      return WETH[chainId]
    }
    if (currency?.symbol === "MATIC") {
      return WMATIC[chainId]
    }
    return currency
  }

  useEffect(() => {
    if (!inputValueDebounce) {
      setUseRouter("")
      console.log('set Output ')
      setTokenValueMethod[Field.OUTPUT]("")
      // setGetSearchResult(false)
      // setIsShowSearchText(false)
      return
    }
    const parseAmount = tryParseAmount(parsedAmounts[Field.INPUT], currencies[Field.INPUT])
    // if ((currentPair.symbol === "ETH-WETH" || currentPair.symbol === "MATIC-WMATIC") && chainId) {
    //   setUseRouter(currentPair.symbol === "ETH-WETH" ?
    //   "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F" :
    //   "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506")
    //   // setTokenValueMethod[Field.OUTPUT](parsedAmounts[Field.INPUT])
    //   // setGetSearchResult(false)
    //   // setIsShowSearchText(true)
    //   return
    // }
    if (RAIProxy && parseAmount) {
      RAIProxy
      .getUniV2Routers(
        `0x${parseAmount?.raw.toString(16)}`,
        getAddress(currencies[Field.INPUT])?.address,
        getAddress(currencies[Field.OUTPUT])?.address
      ).then((res: any) => {
        const maxOutputAmount = res[1].reduce((preAmount: BigNumber, nextAmount: BigNumber) => {
          return preAmount.gt(nextAmount) ? preAmount : nextAmount
        })
        const findRouter = res[0][res[1].findIndex((outputAmount: BigNumber) => outputAmount.eq(maxOutputAmount))]
        setUseRouter(findRouter)
        setTokenValueMethod[Field.OUTPUT](formatUnits(maxOutputAmount, currencies[Field.OUTPUT]?.decimals))
      })
    }
  }, [inputValueDebounce, refresh])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    history.push('/swap/')
  }, [history])

  // modal and loading
  // const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
  //   showConfirm: boolean
  //   tradeToConfirm: Trade | undefined
  //   attemptingTxn: boolean
  //   swapErrorMessage: string | undefined
  //   txHash: string | undefined
  // }>({
  //   showConfirm: false,
  //   tradeToConfirm: undefined,
  //   attemptingTxn: false,
  //   swapErrorMessage: undefined,
  //   txHash: undefined
  // })

  // const formattedAmounts = {
  //   [independentField]: typedValue,
  //   [dependentField]: showWrap
  //     ? parsedAmounts[independentField]?.toExact() ?? ''
  //     : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  // }

  const route = trade?.route

  // check whether the user has approved the router on the input token
  // const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  // const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  // useEffect(() => {
  //   if (approval === ApprovalState.PENDING) {
  //     setApprovalSubmitted(true)
  //   }
  // }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && tryParseAmount(parsedAmounts[Field.INPUT], currencies[Field.INPUT])?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(async() => {
    if (RAIProxy) {
      const parseInputAmount = tryParseAmount(parsedAmounts[Field.INPUT], currencies[Field.INPUT])
      let resTrade: TransactionResponse
      if (currencies[Field.INPUT]?.symbol === "ETH" || currencies[Field.INPUT]?.symbol === "MATIC") {
        resTrade = await RAIProxy.tradeTokenByExactETH(
          useRouter,
          currencies[Field.OUTPUT]?.address,
          0,
          account,
          {"value": `0x${parseInputAmount?.raw.toString(16)}`}
        )
      } else if (currencies[Field.OUTPUT]?.symbol === "ETH" || currencies[Field.OUTPUT]?.symbol === "MATIC") {
        resTrade = await RAIProxy.tradeETHByExactToken(
          useRouter,
          currencies[Field.INPUT]?.address,
          `0x${parseInputAmount?.raw.toString(16)}`,
          0,
          account
        )
      } else {
        resTrade = await RAIProxy.tradeTokenByExactToken(
          useRouter,
          currencies[Field.INPUT]?.address,
          currencies[Field.OUTPUT]?.address,
          `0x${parseInputAmount?.raw.toString(16)}`,
          0,
          account
        )
      }
      setShowConfirm(false)
      addTransaction(resTrade, {
        summary: `Swap ${parsedAmounts[Field.INPUT]} ${currencies[Field.INPUT]?.symbol} for ${numeral(parsedAmounts[Field.OUTPUT]).format("0.00000000")} ${currencies[Field.OUTPUT]?.symbol}`
      })
    }
    // if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
    //   return
    // }
    // if (!swapCallback) {
    //   return
    // }
    // setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    // swapCallback()
    //   .then(hash => {
    //     setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

    //     ReactGA.event({
    //       category: 'Swap',
    //       action:
    //         recipient === null
    //           ? 'Swap w/o Send'
    //           : (recipientAddress ?? recipient) === account
    //           ? 'Swap w/o Send + recipient'
    //           : 'Swap w/ Send',
    //       label: [
    //         trade?.inputAmount?.currency?.symbol,
    //         trade?.outputAmount?.currency?.symbol,
    //       ].join('/')
    //     })

    //     ReactGA.event({
    //       category: 'Routing',
    //       action: singleHopOnly ? 'Swap with multihop disabled' : 'Swap with multihop enabled'
    //     })
    //   })
    //   .catch(error => {
    //     setSwapState({
    //       attemptingTxn: false,
    //       tradeToConfirm,
    //       showConfirm,
    //       swapErrorMessage: error.message,
    //       txHash: undefined
    //     })
    //   })
  }, [
    priceImpactWithoutFee,
    swapCallback,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade,
    singleHopOnly
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  // const showApproveFlow =
  //   !swapInputError &&
  //   (approval === ApprovalState.NOT_APPROVED ||
  //     approval === ApprovalState.PENDING ||
  //     (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
  //   !(priceImpactSeverity > 3 && !isExpertMode)

  // const handleConfirmDismiss = useCallback(() => {
  //   setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
  //   // if there was a tx hash, we want to clear the input
  //   if (txHash) {
  //     onUserInput(Field.INPUT, '')
  //   }
  // }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  // const handleAcceptChanges = useCallback(() => {
  //   setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  // }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      // setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && setTokenValueMethod[Field.INPUT](maxAmountInput.toExact())
  }, [maxAmountInput, setTokenValueMethod])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const refreshData = () => {
    setRefresh(refresh + 1);
    setRefreshLoading(true)
    setTimeout(() => {
      setRefreshLoading(false)
    }, 1000)
  }


  return (
    <STS>
        <Container className='aggregate'>
            <div className='title'>
                <div>{t('header_aggregator')}</div>
                <div><Button onClick={() => {history.push(`/sts/profile/${account}/4`)}} icon={<HistoryOutlined />} type="default">{t('recent_transaction')}</Button></div>
            </div>
            <div className={`${hasChart ? 'chart' : ''}`}>
              {hasChart && <div>
                <div className='tokenInfo'>
                  <div>
                    <img className="tokenAvator" src={getTokenLogoURL(currencies[Field.INPUT]?.symbol)}/>
                    <img className="tokenAvator" src={getTokenLogoURL(currencies[Field.OUTPUT]?.symbol || getDefaultOtherSymbol())}/>
                    {currencies[Field.INPUT]?.symbol}&nbsp;/&nbsp;{currencies[Field.OUTPUT]?.symbol || getDefaultOtherSymbol()}
                  </div>
                  <div>
                    {/* <Button onClick={() => {history.push('/headTail')}}>Go To H&T Games</Button> */}
                  </div>
                </div>
                <Chart data={chartData} chartRange={chartRange} setChartRange={setChartRange}/>
                <div className="price_box">
                  <b className="price">{`${tickerData.price >= 1 ? numeral(tickerData.price).format('0,0.00') : numeral(tickerData.price).format('0,0.00000000')}`}</b>
                  <span className={tickerData?.priceChangePercent > 0 ? 'up': 'down'}>{`${tickerData?.priceChangePercent > 0 ? `+${tickerData?.priceChangePercent}%`: `${tickerData?.priceChangePercent}%`}`}</span>
                </div>
                <div className="card_footer">{moment(tickerData?.time).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>}
              <div className="swap-content">
                  <div className='head'>
                      <div>
                        <Button icon={<LineChartOutlined />} onClick={() => {history.push('/aggregateSwap/chart')}} type="default">{t('chart')}</Button>
                        <Button onClick={refreshData} icon={refreshLoading ? <LoadingOutlined /> : <RefreshIcon />}></Button>
                      </div>
                      <div>
                        <ChainLogo chainId={chainId}/>
                        {NETWORK_LABELS[chainId || ChainId.RINKEBY]}
                      </div>
                  </div>
                  <div className='body'>
                  <AggregateModal
                    isOpen={showConfirm}
                    onDismiss={() => setShowConfirm(false)}
                    handleSwap={handleSwap}
                    inputValue={parsedAmounts[Field.INPUT]}
                    outputValue={parsedAmounts[Field.OUTPUT]}
                    inputCurrency={currencies[Field.INPUT]}
                    outputCurrency={currencies[Field.OUTPUT]}
                  />
                  <div className='input-content'>
                    <div>
                      <CurrencyInputPanel
                        label={t('pay')}
                        value={parsedAmounts[Field.INPUT]}
                        showMaxButton={!atMaxAmountInput}
                        currency={currencies[Field.INPUT]}
                        onUserInput={handleTypeInput}
                        onMax={handleMaxInput}
                        onCurrencySelect={handleInputSelect}
                        otherCurrency={currencies[Field.OUTPUT]}
                        id="swap-currency-input"
                      />
                      <div className='swith-btn'>
                        <Button onClick={onSwitchTokens}><SwapIcon/></Button>
                      </div>
                      <CurrencyInputPanel
                        value={parsedAmounts[Field.OUTPUT]}
                        onUserInput={handleTypeOutput}
                        label={t('receive')}
                        showMaxButton={false}
                        currency={currencies[Field.OUTPUT]}
                        onCurrencySelect={handleOutputSelect}
                        otherCurrency={currencies[Field.INPUT]}
                        id="swap-currency-output"
                      />
                    </div>
                    <div className='swith-btn'>
                      <Button onClick={onSwitchTokens}><SwapIcon/></Button>
                    </div>
                  </div>
                  <div className='btns'>
                    {/* {getSearchResult ? 
                      <SearchText isShowSearchText={true} >Get the best price from {switchRouterName}</SearchText>
                      : 
                      <SearchText isShowSearchText={isShowSearchText} >Getting price from {switchRouterName}</SearchText>
                    } */}
                    {
                      swapInputError && 
                      <Button
                          disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                          block
                      >
                          {swapInputError}
                      </Button>
                    }
                    {(
                      !swapInputError && (inputTokenApproval !== ApprovalState.APPROVED ? (
                        <Button type='primary' onClick={inputTokenApproveCallback} block> {t('approve')}</Button>
                        ) : (
                          currencyBalances[Field.INPUT]?.lessThan(tryParseAmount(parsedAmounts[Field.INPUT], currencies[Field.INPUT]) ?? JSBI.BigInt(0)) ?
                        <Button type='default' disabled block>{t('insufficient_balance')}</Button>
                        :
                        <Button type='primary' disabled={!isValid} onClick={() => setShowConfirm(true)} block>{t('swap')}</Button>
                        )
                      ))}
                      {/* {swapIsUnsupported ? (
                      <ButtonPrimary disabled={true}>
                          <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
                      </ButtonPrimary>
                      ) : !account ? (
                      <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
                      ) : showWrap ? (
                      <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                          {wrapInputError ??
                          (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                      </ButtonPrimary>
                      ) : noRoute && userHasSpecifiedInputOutput ? (
                      <GreyCard style={{ textAlign: 'center' }}>
                          <TYPE.main mb="4px">Insufficient liquidity for this trade.</TYPE.main>
                          {singleHopOnly && <TYPE.main mb="4px">Try enabling multi-hop trades.</TYPE.main>}
                      </GreyCard>
                      ) : showApproveFlow ? (
                      <RowBetween>
                          <ButtonConfirmed
                          onClick={approveCallback}
                          disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                          width="48%"
                          altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                          confirmed={approval === ApprovalState.APPROVED}
                          >
                          {approval === ApprovalState.PENDING ? (
                              <AutoRow gap="6px" justify="center">
                              Approving <Loader stroke="white" />
                              </AutoRow>
                          ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                              'Approved'
                          ) : (
                              'Approve ' + currencies[Field.INPUT]?.symbol
                          )}
                          </ButtonConfirmed>
                          <ButtonError
                          onClick={() => {
                              if (isExpertMode) {
                              handleSwap()
                              } else {
                              setSwapState({
                                  tradeToConfirm: trade,
                                  attemptingTxn: false,
                                  swapErrorMessage: undefined,
                                  showConfirm: true,
                                  txHash: undefined
                              })
                              }
                          }}
                          width="48%"
                          id="swap-button"
                          disabled={
                              !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                          }
                          error={isValid && priceImpactSeverity > 2}
                          >
                          <Text fontSize={16} fontWeight={500}>
                              {priceImpactSeverity > 3 && !isExpertMode
                              ? `Price Impact High`
                              : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                          </Text>
                          </ButtonError>
                      </RowBetween>
                      ) : (
                      <ButtonError
                          onClick={() => {
                          if (isExpertMode) {
                              handleSwap()
                          } else {
                              setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              showConfirm: true,
                              txHash: undefined
                              })
                          }
                          }}
                          id="swap-button"
                          disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                          error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                      >
                          <Text fontSize={20} fontWeight={500}>
                          {swapInputError
                              ? swapInputError
                              : priceImpactSeverity > 3 && !isExpertMode
                              ? `Price Impact Too High`
                              : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                          </Text>
                      </ButtonError>
                      )}
                      {showApproveFlow && (
                      <Column style={{ marginTop: '1rem' }}>
                          <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                      </Column>
                      )}
                      {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                      {betterTradeLinkV2 && !swapIsUnsupported && toggledVersion === Version.v1 ? (
                      <BetterTradeLink version={betterTradeLinkV2} />
                      ) : toggledVersion !== DEFAULT_VERSION && defaultTrade ? (
                      <DefaultVersionLink />
                      ) : null} */}
                  </div>
                  </div>
              </div>
            </div>
            <TokenWarningModal
                isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
                tokens={importTokensNotInDefault}
                onConfirm={handleConfirmTokenWarning}
                onDismiss={handleDismissTokenWarning}
            />
            {/* {!swapIsUnsupported ? (
                <AdvancedSwapDetailsDropdown trade={trade} />
            ) : (
                <UnsupportedCurrencyFooter show={swapIsUnsupported} currencies={[currencies.INPUT, currencies.OUTPUT]} />
            )} */}
        </Container>
    </STS>
  )
}