import React, { useState } from 'react'
import numeral from 'numeral';
import { Modal, Form, Input, Button, Checkbox, message } from 'antd';
import { useTranslation } from 'react-i18next'
import { post } from 'utils/request';
import { useActiveWeb3React } from '../../hooks'
import { APILIST } from '../../constants'
import styled from 'styled-components'

export default function ({isOpen, handleCancel, stsInfo, setForceUpdate}: any){
    const [isShowInputTwitter, setIsShowInputTwitter] = useState(false)
    const [isClickedTwitter, setIsClickedTwitter] = useState(false)
    const [isClickedTelegram, setIsClickedTelegram] = useState(false)
    const [twitterUsername, setTwitterUsername] = useState("")
    const [telegramUsername, setTelegramUsername] = useState("")

    const { chainId, account } = useActiveWeb3React()
    const { t } = useTranslation()
    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
        console.log('Success:', values);
        if(!account){
            return;
        }
        const api_url = chainId ? APILIST[chainId] : APILIST[4];
        const res = await post(`${api_url}/sts/faucet`, {
            address: account,
            name: `${twitterUsername}${telegramUsername}`
        })
        if(res){
            if (res.error) {
                message.error(res.error)
                if (res.faucet_count === 1) {
                    setIsShowInputTwitter(true)
                }
            } else {
                handleCancel();

                message.success(t("success"))
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    
    return (
        <Modal
          title={isShowInputTwitter ? "Get Extra Test Token" : t("get_test_token")}
          visible={isOpen}
          footer={null}
          onCancel={handleCancel}
          destroyOnClose={true}
          forceRender={true}
        >
           <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                hideRequiredMark
                className="waveModal"
                form={form}
            >
                {/*  */}
                {isShowInputTwitter ? (
                    <>
                        <FaucetText>
                            <div>Click on the Twitter and Telegram link below and follow. Then submit your Twitter and Telegram username to claim your extra 100 test token.</div>
                            <div>1. Follow <a target="_blank" onClick={() => setIsClickedTwitter(true) } href="https://twitter.com/RaiFinance" >Twitter</a></div>
                            <div>2. Join <a target="_blank" onClick={() => setIsClickedTelegram(true) } href="https://t.me/RaiFinance" >Telegram</a></div>
                            <div className="inputItem" >Twitter username: &nbsp;
                                <Input
                                    style={{ width: 120 }}
                                    value={twitterUsername}
                                    onChange={(e) => {
                                        setTwitterUsername(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="inputItem" >Telegram username: &nbsp;
                                <Input
                                    style={{ width: 120 }}
                                    value={telegramUsername}
                                    onChange={(e) => {
                                        setTelegramUsername(e.target.value)
                                    }}
                                />
                            </div>
                        </FaucetText>
                        <Form.Item 
                        wrapperCol={{ span: 24 }}
                        style={{ textAlign: "center" }}
                        >
                            &nbsp;&nbsp;&nbsp;
                            <Button
                            type="primary"
                            htmlType="submit"
                            disabled={!telegramUsername || !twitterUsername || !isClickedTelegram || !isClickedTwitter}
                            >
                                Get Extra Test Token
                            </Button>
                        </Form.Item>
                    </>
                ) :
                <>
                    <FaucetText>
                        You will be able to claim 100 test USDT. To get test ETH for Rinkeby gas, please go to https://faucet.rinkeby.io/ and follow the instruction. (If you need more test USDT, you can open the faucet again and follow our social media channel to claim 100 extra test USDT)
                    </FaucetText>
                    <Form.Item 
                    wrapperCol={{ span: 24 }}
                    style={{ textAlign: "center" }}
                    >
                        &nbsp;&nbsp;&nbsp;
                        <Button
                        type="primary"
                        htmlType="submit"
                        >
                            {t("claim_test_tokens")}
                        </Button>
                    </Form.Item>
                </>

                }

            </Form>
        </Modal>
    ) 
}

const FaucetText = styled.div`
    .inputItem {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    margin-bottom: 24px;
    a {

    }
`