import React, { useState, useEffect } from 'react'
import { Button, Badge, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChainId } from 'constants/chainId';
import { InfoCircleOutlined, QuestionCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { Link, useHistory } from "react-router-dom";
import { Container } from '../STS/Markets'
import STS from '../STS'
import { useActiveWeb3React } from '../../hooks'
import { useProposals } from './hooks'
import { useVoteContract } from 'hooks/useContract'
import Tip from '../../assets/images/tip.png'
import './index.less'
import { SOFI_DAO_contract_address } from "../../constants";
import { proposalStatus, proposalStateColor } from './ProposalsDetail'
import {ReactComponent as OnchainIcon} from 'assets/svg/onchain.svg'

const PrefixInteger = (num: number, n: number) => {
    //@ts-ignore
    return (Array(n).join(0) + num).slice(-n);
}

export const ProposalItem = ({proposal, index}: any) => {
    const { chainId } = useActiveWeb3React()
    const voteContract = useVoteContract(SOFI_DAO_contract_address[chainId || ChainId.RINKEBY])
    const [status, setStatus] = useState<number>(0);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        if(voteContract){
            voteContract.state(proposal.proposalId)
            .then((res: any) => {
                setStatus(res)
            })
        }
    }, [voteContract])

    const getLeftTime = (timestamp: number) => {
        const leftTime = (new Date(timestamp*1000).getTime() - new Date().getTime())/1000;
        //@ts-ignore
        const oDay = parseInt(leftTime/(24*60*60));
        //@ts-ignore
        const oHours = parseInt(leftTime/(60*60)%24);
        return `${t('left_time', {days: oDay, hrs: oHours})}`
    }
    return (
        <div className="item" onClick={() => {history.push(`/proposals/${proposal.contract}/${proposal.proposalId}`)}}>

            <div className='head'>
                <div className="id">{`SOFIP-${PrefixInteger(index, 3)}`}</div>
                {proposal.actions && <div className="chain">On-chain</div>}
            </div>
            <div className='category'>
                {proposal.actions ? t('edit_portfolio_list') : t('marketing_campaign')}
            </div>
            <h2>{proposal.title}</h2>
            <div className="footer">
                <div>
                    <div className={`tag ${proposal.state === 0 ? 'soon' : (proposal.state === 1 || proposal.state === 4 || proposal.state === 5 || proposal.state === 7 || proposal.state === 8) ? 'active' : 'closed'}`} color={proposalStateColor[proposal.state]}>
                        {proposalStatus(proposal.state)}
                        </div>
                    {proposal.state === 1 && getLeftTime(proposal.endTime)}
                </div>
                <div className="ended">{t('ended_at')} {moment(proposal.endTime * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
            </div>
        </div>
    )
}

export default function Proposals() {
    const { t } = useTranslation();
    const { chainId, account, library } = useActiveWeb3React();
    const proposals = useProposals(chainId);
    return (
        <STS>
            <Container>
                <div className="proposals">
                    <div className="create">
                        <div className='title'>{t('header_governance')}</div>
                        <div className='operation'>
                            <Tooltip placement="left" title={t("governance_title5")}>
                                <div className='tips'>
                                    <QuestionCircleOutlined style={{ fontSize: '16px'}}  />
                                </div>
                            </Tooltip>
                            {/* <Link to="/proposals/create" >
                                <Button size="large" icon={<InfoCircleOutlined />} type="primary">{t("governance_title4")}</Button>
                            </Link> */}
                            <Link to="/proposals/select" >
                                <Button size="large" icon={<InfoCircleOutlined />} type="primary">{t("governance_title4")}</Button>
                            </Link>                            
                        </div>
                    </div>
                    <div className="tip">
                        <InfoCircleFilled style={{ fontSize: '16px', color: '#5542F6' }} />&nbsp;&nbsp;
                        {t("governance_title1")} <a className="target" href="https://docs.rai.finance/rai-finance/how-to-guides/governance" target="_blank">{t("governance_title2")}</a>
                    </div>
                    <div className="content">
                        {proposals.map((proposal: any, index: number) => 
                            <ProposalItem key={index} proposal={proposal} index={proposals.length - index}/>
                        )}
                    </div>
                </div>
            </Container>
        </STS>
    )
}
