import React, { useCallback, useEffect, useState } from 'react'
import { List, Card, Button, Tabs, Input, Avatar, message, Tooltip } from 'antd';
import { RouteComponentProps, useHistory } from 'react-router-dom'
import numeral from 'numeral';
import useThrottle from "@rooks/use-throttle"
import orderBy from 'lodash.orderby';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { SearchOutlined, MoreOutlined, QuestionCircleOutlined, UserOutlined, UserAddOutlined, UserDeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import Copy from 'components/AccountDetails/Copy'
import { useTokens, useHotPortfolios, useAllPortfolios, useManagersList, useChartData, postFollowManager, postFavoritePortfolio, usePortfolios } from './hooks';
import PortfolioChart from './PortfolioChart';
import { PriceChartRangeOption } from 'constants/priceChartEnums'
import { shortenAddress, toPlainString } from '../../utils'
import { useActiveWeb3React } from '../../hooks'
import {ReactComponent as CollectIcon} from 'assets/svg/sts/collect.svg'
import {ReactComponent as UncollectIcon} from 'assets/svg/sts/uncollect.svg'

import './index.less';
import './Market.less';
import STS from './index'

const { Meta } = Card;
const { TabPane } = Tabs;
const { Search } = Input;


export const Container = styled.div`
    padding: 50px;
    padding-top: 56px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 20px;
    `};
    overflow: auto;
`

const SubTitle = styled.h2`
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 28px;
    color: #18181B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>div{
      color: #71717A;
      font-weight: 500;
      font-size: 14px;
    }
`

const PortfolioAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`

export const Chart = ({address} : any) => {
  const [chartRange, setChartRange] = useState<string>(PriceChartRangeOption.WEEKLY_PRICE_RANGE)
  const { chartData } = useChartData(address, chartRange);
  return <PortfolioChart data={chartData} chartRange={chartRange} setChartRange={setChartRange}/>
}

export default function More(props: RouteComponentProps<{ type: string }>) {
    const {
      match: {
        params: { type }
      }
    } = props
  const { t } = useTranslation();
  const { tokens } = useTokens();
  const history = useHistory();
  const [refreshPortfolio, setRefreshPortfolio] = useState<number>(1);
  const [refreshManager, setRefreshManager] = useState<number>(1);
  const portfolios = usePortfolios(type, 1000, refreshPortfolio);
  const allPortfolios = useAllPortfolios('', refreshPortfolio);
  const { list: managerList } = useManagersList(refreshManager);
  const [searchPortfolios, setSearchPortfolios] = useState([]);
  const [searchManagerList, setSearchManagerList] = useState([]);
  const [searchText, setSearchText] = useState('');  
  const [showMoreType, setShowMoreType] = useState('');  
  const [showMorePortfolios, setShowMorePortfolios] = useState([]);  
  const [activeKey, setActiveKey] = useState('portfolio');  
  const { chainId, account, library } = useActiveWeb3React()
  const [isSearchDrawerVisible, setIsSearchDrawerVisible] = useState(false);
  const bestPortfolios: any = orderBy(allPortfolios, ['price_changed_1mon'], ['desc'])
  const newPortfolios: any = orderBy(allPortfolios, ['created_at'], ['desc'])


  const onSearch = (value: any) => {
    onClose();
    if(value){
      setSearchText(value);
      const list = allPortfolios.filter((v: any) => JSON.stringify(v).toLowerCase().includes(value.toLowerCase()))
      setSearchPortfolios(list);
    }else {
      setSearchText('')
      setSearchPortfolios([]);
      setSearchManagerList([]);
    }
  };


  const favoritePortfolio = useCallback(async(e: any, portfolioAddress: string, action: boolean) => {
    e.stopPropagation();
    if(account && chainId){
      const res: any = await postFavoritePortfolio(portfolioAddress, account, action, chainId, library);
      console.log(res)
      if(res.address) {
        message.success(action ? t('successfully_favorited') : t('successfully_unfavorited') )
        setRefreshPortfolio(refreshPortfolio + 1)
      }
    }
  }, [account, library, refreshPortfolio])

  const [ handleFavoritePortfolio ] = useThrottle(favoritePortfolio, 2000)

  const showDrawer = () => {
    setIsSearchDrawerVisible(true);
  };

  const onClose = () => {
    setIsSearchDrawerVisible(false);
  };

  const getTypeName = (type: string) => {
    switch (type) {
      case 'favorited': 
        return t('sts_favorites');
      case 'best': 
        return t('sts_featured');
      case 'rated': 
        return t('sts_top_rated');
      case 'viewed': 
        return t('sts_most_viewed');
      case 'traded': 
        return t('sts_most_traded');
      case 'risk': 
        return t('sts_high_risk');
        default:
        return type
    }
  }

  return (
    <STS isSearchDrawerVisible={isSearchDrawerVisible} onClose={onClose} onSearch={onSearch}>
      <Container className='markets'>
        <div className='back'>
          <div>
            <LeftOutlined onClick={() => {history.goBack()}}/>
          </div>
          <div>
            <h2>{getTypeName(type)}</h2>
          </div>
        </div>
        <SubTitle>
          <div>{type === 'managers' ? managerList.length : portfolios.length} {t('results_were_found')}</div>
        </SubTitle>
        {type === 'managers' ? 
          <List
            className="managerList"
            dataSource={managerList}
            renderItem={(item: any) => (
              <List.Item onClick={() => {history.push(`/sts/profile/${item.address}`)}}>
                <Card 
                  title={<div className='tokenInfo'>
                    {item?.photo_url ? <Avatar size={24} src={item?.photo_url} />: <Avatar size={24} icon={<UserOutlined style={{fontSize: '18px'}}/>}/>}
                    <div>{shortenAddress(item?.address)}</div>
                  </div>} 
                >
                </Card>
              </List.Item>
            )}
          />
          :
          <List
            className="marketsList"
            dataSource={portfolios}
            renderItem={(item: any) => (
              <List.Item onClick={() => {history.push(`/sts/portfolio/${item.address}`)}}>
                <Card 
                  title={<div className='tokenInfo'><img className="tokenAvator" src={item.avatar_uri}/><div>{item.symbol === 'WETH' ? 'ETH' : item.symbol}<br /><span>{item.name}</span></div></div>} 
                  extra={<span>{item.favorite ? <CollectIcon onClick={(e) => {handleFavoritePortfolio(e, item.address, true)}}/> : <UncollectIcon onClick={(e) => {handleFavoritePortfolio(e, item.address, true)}}/>}<MoreOutlined /></span>}
                >
                  <div className='price'><Tooltip title={`$${toPlainString(item?.value_per_set)}`}>{item?.value_per_set > 0.01 ? `$${numeral(toPlainString(item?.value_per_set)).format('0,0.00')}` : '<$0.01'}</Tooltip> <b className={`${item.apy >= 0 ? 'up':'down'}`}>{`${item.apy > 0 ? `+${item.apy}%`: `${item.apy}%`}`}</b></div>
                </Card>
              </List.Item>
            )}
          />
        }
      </Container>
    </STS>
  )
}
