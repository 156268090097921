import { useCallback, useEffect, useState } from 'react'
import { ChainId } from '@uniswap/sdk'
import { useActiveWeb3React } from '../../hooks'
import { APILIST } from '../../constants'
import { get } from '../../utils/request';

export function useChartData(symbol:string, type: string, refresh = 1): any {
  const [chartData, setChartData] = useState<any>([]);
  const { chainId, account } = useActiveWeb3React()
  const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
  const fetchChartData = useCallback(async () => {
    get(`${api_url}/v2/proxy/history?symbol=${symbol}&type=${type}`).then((res: any) => {
      if (res) {
        setChartData(res)
      }
    });
  }, [symbol, type])

  useEffect(() => {
    if (symbol && !symbol.includes('undefined')) {
      fetchChartData();
    }
  }, [symbol, type, refresh])
  return chartData
}

export function useAggregateTicker24hr(symbol:any, refresh = 1): any {
  const [ticker, setTicker] = useState<any>({});
  const { chainId, account } = useActiveWeb3React()
  const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
  const fetchTicker = useCallback(async (symbol: string) => {
    if (!symbol) {
      return;
    }
    get(`${api_url}/proxy/ticker/24hr?symbol=${symbol}`).then((response: any) => {
      if (response) {
        const price = (symbol === "WMATIC-WMATIC" || symbol === "WETH-WETH") ? 1 : response.price;
        setTicker({...response, price, time: new Date()})
      }
    });
  }, [symbol, chainId])

  useEffect(() => {
    const getAllTicker = () => {
      if (!symbol.includes('undefined')) {
        fetchTicker(symbol);
      }
    }
    getAllTicker()
    const refreshFetchTicker = setInterval(getAllTicker, 1000 * 10)
    return () => clearInterval(refreshFetchTicker)
  }, [symbol])
  useEffect(() => {
    const getAllTicker = () => {
      if (!symbol.includes('undefined')) {
        fetchTicker(symbol);
      }
    }
    getAllTicker()
  }, [refresh])
  return ticker
}