import Qatar_Ecuador from 'assets/images/headTail/Qatar_Ecuador.jpeg'
import USA_Wales from 'assets/images/headTail/USA_Wales.jpeg'
import England_USA from 'assets/images/headTail/England_USA.jpeg'
import Germany_Japan from 'assets/images/headTail/Germany_Japan.jpeg'
import Portugal_Ghana from 'assets/images/headTail/Portugal_Ghana.jpeg'
import Uruguay_Korea from 'assets/images/headTail/Uruguay_Korea.jpeg'
import Japan_Costa_rica from 'assets/images/headTail/Japan_Costa_rica.jpeg'
import Korea_Ghana from 'assets/images/headTail/Korea_Ghana.jpeg'
import Portugal_Uruguay from 'assets/images/headTail/Portugal_Uruguay.jpeg'
import Iran_USA from 'assets/images/headTail/Iran_USA.jpeg'
import Japan_Spain from 'assets/images/headTail/Japan_Spain.jpeg'
import Korea_Portugal from 'assets/images/headTail/Korea_Portugal.jpeg'
import Poland_Argentina from 'assets/images/headTail/Poland_Argentina.jpeg'
import France_Poland from 'assets/images/headTail/France_Poland.jpeg'
import Japan_Croatia from 'assets/images/headTail/Japan_Croatia.jpeg'
import Korea_Brazil from 'assets/images/headTail/Korea_Brazil.jpeg'
import Morocco_Spain from 'assets/images/headTail/Morocco_Spain.jpeg'
import Croatia_Brazil from 'assets/images/headTail/Croatia_Brazil.jpeg'
import England_France from 'assets/images/headTail/England_France.jpeg'
import Moroco_Portugal from 'assets/images/headTail/Moroco_Portugal.jpeg'
import Netherland_Argentina from 'assets/images/headTail/Netherland_Argentina.jpeg'
import Argentina_Croatia from 'assets/images/headTail/Argentina_Croatia.jpeg'
import France_Morocco from 'assets/images/headTail/France_Morocco.jpeg'
import Argentina_France from 'assets/images/headTail/Argentina_France.jpeg'
import Croatia_Morocco from 'assets/images/headTail/Croatia_Morocco.jpeg'

export const imgages: any = {
    Qatar_Ecuador: Qatar_Ecuador,
    USA_Wales: USA_Wales,
    England_USA: England_USA,
    Germany_Japan: Germany_Japan,
    Portugal_Ghana: Portugal_Ghana,
    Uruguay_Korea: Uruguay_Korea,
    Japan_Costa_rica: Japan_Costa_rica,
    Korea_Ghana: Korea_Ghana,
    Portugal_Uruguay: Portugal_Uruguay,
    Iran_USA: Iran_USA,
    Japan_Spain: Japan_Spain,
    Korea_Portugal: Korea_Portugal,
    Poland_Argentina: Poland_Argentina,
    France_Poland: France_Poland,
    Japan_Croatia: Japan_Croatia,
    Korea_Brazil: Korea_Brazil,
    Morocco_Spain: Morocco_Spain,
    Croatia_Brazil: Croatia_Brazil,
    England_France: England_France,
    Moroco_Portugal: Moroco_Portugal,
    Netherland_Argentina: Netherland_Argentina,
    Argentina_Croatia: Argentina_Croatia,
    France_Morocco: France_Morocco,
    Argentina_France: Argentina_France,
    Croatia_Morocco: Croatia_Morocco
}

export const formatTeam = (team: string) => {
    if(team === 'United States of America'){
        return 'USA'
    }
    if(team === 'Costa Rica'){
        return 'Costa_rica'
    }
    if(team === 'Republic of Korea'){
        return 'Korea'
    }
    return team
}
