import { Token } from '@uniswap/sdk'
import { ChainId } from 'constants/chainId';
import { UNSUPPORTED_LIST_URLS } from './../../constants/lists'
import DEFAULT_TOKEN_LIST from '@uniswap/default-token-list'
import MATIC_PAIR_LIST from '../../matic_aggregate_pair.json';
import BASE_PAIR_LIST from '../../base_aggregate_pair.json';
import BASE_GOERLI_PAIR_LIST from '../../base_goerli_aggregate_pair.json';
import PZT_PAIR_LIST from '../../pzt_aggregate_pair.json';
import ZKTEST_PAIR_LIST from '../../zkSync_test_aggregate_pair.json';
import ETH_PAIR_LIST from '../../eth_aggregate_pair.json';
import TAIKO_PAIR_LIST from '../../taiko_test_aggregate_pair.json';
import { Tags, TokenInfo, TokenList } from '@uniswap/token-lists'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getChain } from 'constants/index'
import { AppState } from '../index'
import sortByListPriority from 'utils/listSort'
import UNSUPPORTED_TOKEN_LIST from '../../constants/tokenLists/uniswap-v2-unsupported.tokenlist.json'
import { useActiveWeb3React } from 'hooks';

type TagDetails = Tags[keyof Tags]
export interface TagInfo extends TagDetails {
  id: string
}

/**
 * Token instances created from token info.
 */
export class WrappedTokenInfo extends Token {
  public readonly tokenInfo: TokenInfo
  public readonly tags: TagInfo[]
  constructor(tokenInfo: TokenInfo, tags: TagInfo[]) {
    super(tokenInfo.chainId, tokenInfo.address, tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name)
    this.tokenInfo = tokenInfo
    this.tags = tags
  }
  public get logoURI(): string | undefined {
    return this.tokenInfo.logoURI
  }
}

export type TokenAddressMap = Readonly<
  { [chainId in ChainId]: Readonly<{ [tokenAddress: string]: { token: WrappedTokenInfo; list: TokenList } }> }
>

/**
 * An empty result, useful as a default.
 */

const EMPTY_LIST: any = {
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {},
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {},
  [ChainId.MAINNET]: {},
  [ChainId.BASE]: {},
  [ChainId.BASE_GOERLI]: {},
  [ChainId.POLYGON]: {},
  [ChainId.PZ]: {},
  [ChainId.PZT]: {},
  [ChainId.ZKSYNC]: {},
  [ChainId.ZKSYNC_TEST]: {},
  [ChainId.TAIKO]: {},
  [ChainId.BLAST_SEPOLIA]: {},
}

const listCache: WeakMap<TokenList, TokenAddressMap> | null =
  typeof WeakMap !== 'undefined' ? new WeakMap<TokenList, TokenAddressMap>() : null

export function listToTokenMap(list: TokenList): TokenAddressMap {
  const result = listCache?.get(list)
  if (result) return result

  const map = list.tokens.reduce<TokenAddressMap>(
    (tokenMap, tokenInfo) => {
      const tags: TagInfo[] =
        tokenInfo.tags
          ?.map(tagId => {
            if (!list.tags?.[tagId]) return undefined
            return { ...list.tags[tagId], id: tagId }
          })
          ?.filter((x): x is TagInfo => Boolean(x)) ?? []
      const token = new WrappedTokenInfo(tokenInfo, tags)
      if (tokenMap[token.chainId][token.address] !== undefined) throw Error('Duplicate tokens.')
      return {
        ...tokenMap,
        [token.chainId]: {
          ...tokenMap[token.chainId],
          [token.address]: {
            token,
            list: list
          }
        }
      }
    },
    { ...EMPTY_LIST }
  )
  listCache?.set(list, map)
  return map
}

export function useAllLists(): {
  readonly [url: string]: {
    readonly current: TokenList | null
    readonly pendingUpdate: TokenList | null
    readonly loadingRequestId: string | null
    readonly error: string | null
  }
} {
  return useSelector<AppState, AppState['lists']['byUrl']>(state => state.lists.byUrl)
}

function combineMaps(map1: TokenAddressMap, map2: TokenAddressMap): TokenAddressMap {
  return {
    1: { ...map1[1], ...map2[1] },
    3: { ...map1[3], ...map2[3] },
    4: { ...map1[4], ...map2[4] },
    5: { ...map1[5], ...map2[5] },
    42: { ...map1[42], ...map2[42] },
    137: { ...map1[137], ...map2[137] },
    8453: { ...map1[8453], ...map2[8453] },
    84531: { ...map1[84531], ...map2[84531] },
    1442: { ...map1[1442], ...map2[1442] },
    1101: { ...map1[1101], ...map2[1101] },
    280: {...map1[280], ...map2[280]},
    324: {...map1[324], ...map2[324]},
    167000: {...map1[167000], ...map2[167000]},
    168587773: {...map1[168587773], ...map2[168587773]},
    // 56: { ...map1[56], ...map2[56] },
    // 97: { ...map1[97], ...map2[97] }
  }
}

// merge tokens contained within lists from urls
function useCombinedTokenMapFromUrls(urls: string[] | undefined): TokenAddressMap {
  const lists = useAllLists()

  return useMemo(() => {
    if (!urls) return EMPTY_LIST

    return (
      urls
        .slice()
        // sort by priority so top priority goes last
        .sort(sortByListPriority)
        .reduce((allTokens, currentUrl) => {
          const current = lists[currentUrl]?.current
          if (!current) return allTokens
          try {
            const newTokens = Object.assign(listToTokenMap(current))
            return combineMaps(allTokens, newTokens)
          } catch (error) {
            console.error('Could not show token list due to error', error)
            return allTokens
          }
        }, EMPTY_LIST)
    )
  }, [lists, urls])
}

// filter out unsupported lists
export function useActiveListUrls(): string[] | undefined {
  return useSelector<AppState, AppState['lists']['activeListUrls']>(state => state.lists.activeListUrls)?.filter(
    url => !UNSUPPORTED_LIST_URLS.includes(url)
  )
}

export function useInactiveListUrls(): string[] {
  const lists = useAllLists()
  const allActiveListUrls = useActiveListUrls()
  return Object.keys(lists).filter(url => !allActiveListUrls?.includes(url) && !UNSUPPORTED_LIST_URLS.includes(url))
}

// get all the tokens from active lists, combine with local default tokens
export function useCombinedActiveList(): TokenAddressMap {
  const activeListUrls = useActiveListUrls()
  const activeTokens = useCombinedTokenMapFromUrls(activeListUrls)
  const defaultTokenMap = listToTokenMap(DEFAULT_TOKEN_LIST)
  return combineMaps(activeTokens, defaultTokenMap)
}

// all tokens from inactive lists
export function useCombinedInactiveList(): TokenAddressMap {
  const allInactiveListUrls: string[] = useInactiveListUrls()
  return useCombinedTokenMapFromUrls(allInactiveListUrls)
}

// used to hide warnings on import for default tokens
export function useDefaultTokenList(): TokenAddressMap {
  const { chainId } = useActiveWeb3React();
  const pairs = useDefaultPairList();
  let tokenMap = new Map();
  for(let i = 0; i < pairs.length; i++){
    tokenMap.set(pairs[i].token0.symbol, {...pairs[i].token0, chainId, logoURI: `https://rai-static.s3.ap-northeast-1.amazonaws.com/sts/token/${pairs[i].token0.symbol.toLowerCase()}.png`})
    tokenMap.set(pairs[i].token1.symbol, {...pairs[i].token1, chainId, logoURI: `https://rai-static.s3.ap-northeast-1.amazonaws.com/sts/token/${pairs[i].token1.symbol.toLowerCase()}.png`})
  }
  pairs.map((pair: any) => pair.token0)
  let tokens: any = Array.from(tokenMap.values());
  return listToTokenMap({
    "name": `${getChain(chainId || ChainId.BASE)?.name} Default List`,
    "timestamp": "2021-01-21T23:57:10.982Z",
    "version": {
      "major": 2,
      "minor": 0,
      "patch": 0
    },
    "tags": {},
    tokens
  })
}

export function useDefaultPairList(): any {
  const { chainId } = useActiveWeb3React();
  let pairs: any = [];
  // if (chainId === 137) {
  //   pairs = MATIC_PAIR_LIST
  // }
  // if (chainId === 1)  {
  //   pairs = ETH_PAIR_LIST
  // }
  // if (chainId === 8453)  {
  //   pairs = BASE_PAIR_LIST
  // }
  // if (chainId === 84531)  {
  //   pairs = BASE_GOERLI_TOKEN_LIST
  // }
  // if (chainId === 1442)  {
  //   pairs = PZT_PAIR_LIST
  // }
  // if (chainId === 280){
  //   pairs = ZKTEST_PAIR_LIST
  // }
  // if (chainId === 167005){
  //   pairs = TAIKO_TEST_PAIR_LIST
  // }
  if (chainId === ChainId.POLYGON) {
    pairs = MATIC_PAIR_LIST
  }
  if (chainId === ChainId.MAINNET)  {
    pairs = ETH_PAIR_LIST
  }
  if (chainId === ChainId.BASE)  {
    pairs = BASE_PAIR_LIST
  }
  if (chainId === ChainId.BASE_GOERLI)  {
    pairs = BASE_GOERLI_PAIR_LIST
  }
  if (chainId === ChainId.PZT)  {
    pairs = PZT_PAIR_LIST
  }
  if (chainId === ChainId.ZKSYNC_TEST){
    pairs = ZKTEST_PAIR_LIST
  }
  if (chainId === ChainId.TAIKO){
    pairs = TAIKO_PAIR_LIST
  }
  return pairs
}

// list of tokens not supported on interface, used to show warnings and prevent swaps and adds
export function useUnsupportedTokenList(): TokenAddressMap {
  // get hard coded unsupported tokens
  const localUnsupportedListMap = listToTokenMap(UNSUPPORTED_TOKEN_LIST)

  // get any loaded unsupported tokens
  const loadedUnsupportedListMap = useCombinedTokenMapFromUrls(UNSUPPORTED_LIST_URLS)

  // format into one token address map
  return combineMaps(localUnsupportedListMap, loadedUnsupportedListMap)
}

export function useIsListActive(url: string): boolean {
  const activeListUrls = useActiveListUrls()
  return Boolean(activeListUrls?.includes(url))
}
