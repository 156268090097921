import React, { Suspense, useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import WalletProvider from 'contexts/AppContext';
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
// import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import { ConnectorNames } from 'connectors/index';
import useAuth from 'hooks/useAuth'
import Markets from './STS/Markets'
import More from './STS/More'
import CreatePortfolio from './STS/CreatePortfolio'
import Portfolio from './STS/Portfolio'
import Manager from './STS/Manager'
import STSBuy from './STS/STSBuy'
import Profile from './STS/Profile'
import Proposals from './Proposals'
import ProposalsDetail from './Proposals/ProposalsDetail'
import Home from './Home'
import HeadTail from './HeadTail'
import PredictionRace from './HeadTail/PredictionRace'
import MoreRace from './HeadTail/MoreRace'
import CreateVote from './Vote/create'
import SelectVote from './Vote/select'
import Social from './Social'
import Swap from './Swap'
import PredictX from './PredictX'
import PredictDetail from './PredictX/PredictDetail'
import Transactions from './PredictX/Transactions'
import {ReactComponent as TwitterLogo} from '../assets/svg/twitter.svg'
import {ReactComponent as MediumLogo} from '../assets/svg/medium.svg'
import {ReactComponent as TelegramLogo} from '../assets/svg/telegram.svg'
import {ReactComponent as GithubLogo} from '../assets/svg/github.svg'

import './App.less';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  min-height: 100vh;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div<{ isSTS: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  ${({ isSTS , theme}) =>
  !isSTS && theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 2rem;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 0;
`
const Footer = styled.div`
  width: 100%;
  height: 128px;
  padding: 56px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bg6};
  svg {
    margin: 0 10px;
  }
`

const StyledLink = styled.a`

`
function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

export default function App() {
  const {pathname} = useLocation();
  console.log('location: ', pathname);
  const isSTS = true

  const { login } = useAuth();
  useEffect(() => {
    const wallet_type = window.localStorage.getItem('wallet_type')
    if(wallet_type === 'Coinbase Wallet'){
      login(ConnectorNames.Walletlink);
    }else {
      login(ConnectorNames.Injected);
    }
  }, [login]);

  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        <BodyWrapper isSTS={isSTS}>
          <Popups />
          <Polling />
          <TopLevelModals />
          <WalletProvider>
            <Web3ReactManager>
              <Switch>
                <Route exact strict path="/aggregateSwap" component={Swap} />
                <Route exact strict path="/aggregateSwap/chart" component={Swap} />
                <Route exact strict path="/proposals/create" component={CreateVote} />
                <Route exact strict path="/proposals/select" component={SelectVote} />
                <Route exact strict path="/proposals" component={Proposals} />
                <Route exact strict path="/proposals/:contract/:proposalId" component={ProposalsDetail} />
                <Route exact strict path="/social" component={Social} />
                <Route exact strict path="/predict" component={PredictX} />
                <Route exact strict path="/predict/transactions" component={Transactions} />
                <Route exact strict path="/predict/:id" component={PredictDetail} />
                <Route exact strict path="/" component={Markets} />
                <Route exact strict path="/headTail" component={HeadTail} />
                <Route exact strict path="/headTail/moreRace" component={MoreRace} />
                <Route exact strict path="/headTail/:pair/:id" component={PredictionRace} />
                <Route exact strict path="/headTail/event/:id" component={PredictionRace} />
                <Route exact strict path="/sts" component={Markets} />
                <Route exact strict path="/sts/more/:type" component={More} />
                <Route exact strict path="/sts/profile/:address" component={Profile} />
                <Route exact strict path="/sts/profile/:address/:tab" component={Profile} />
                <Route exact strict path="/sts/create" component={CreatePortfolio} />
                <Route exact strict path="/sts/portfolio/:address" component={Portfolio} />
                <Route exact strict path="/sts/buy/:contractAddress" component={STSBuy} />
                <Route exact strict path="/sts/sell/:contractAddress" component={STSBuy} />
                <Route exact strict path="/sts/manage/:contractAddress" component={STSBuy} />
                <Route exact strict path="/sts/manager/:address" component={Manager} /> 
              </Switch>
            </Web3ReactManager>
          </WalletProvider>
          <Marginer />
        </BodyWrapper>
        {!isSTS &&         
          <Footer>
              <StyledLink 
                  target='_blank'
                  href="https://twitter.com/RaiFinance">
                    <TwitterLogo fill="#fff"/>
              </StyledLink>
              <StyledLink 
                  target='_blank'
                  href="https://github.com/RaiFinance">
                      <GithubLogo fill="#fff"/>
              </StyledLink>
              <StyledLink 
                  target='_blank'
                  href="https://medium.com/rai-finance">
                      <MediumLogo fill="#fff"/>
              </StyledLink>
              <StyledLink 
                  target='_blank'
                  href="https://t.me/RaiFinance">
                  <TelegramLogo fill="#fff"/>
              </StyledLink>
          </Footer>
        }
      </AppWrapper>
    </Suspense>
  )
}
