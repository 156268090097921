import React, { useState } from 'react'
import { useChartData } from './hooks'
import PortfolioChart from '../STS/PortfolioChart';
import { PriceChartRangeOption } from 'constants/priceChartEnums'

export const Chart = ({token} : any) => {
    const [chartRange, setChartRange] = useState<string>(PriceChartRangeOption.WEEKLY_PRICE_RANGE)
    const chartData = useChartData(token, chartRange);
    return <PortfolioChart data={chartData} chartRange={chartRange} setChartRange={setChartRange}/>
}
