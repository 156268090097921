import { useCallback, useEffect, useState,useMemo } from 'react'
import { ChainId } from 'constants/chainId';
import { useActiveWeb3React } from '../../hooks'
import { APILIST } from '../../constants'
import { get, request } from '../../utils/request';
import { getSign } from 'utils/txSign';
import { useBytes32TokenContract, usePortfolioContract, useTokenContract } from '../../hooks/useContract'
import { ProtocolViewer_Address, portfolioFactory } from '../../constants'
import { useCreatePortfolioContract } from '../../hooks/useContract'
import { useToken } from '../../hooks/Tokens'
import { getContract } from '../../utils'
import { ERC20_ABI } from 'constants/abis/erc20';

interface setsInfoRes {
  address: string;
  decimals: number;
  manager: string;
  name: string;
  symbol: string;
  promote_end_at: number;
  components: Component[];
  total_value: number;
  total_amount: number;
  price_changed_24hr: string;
  price_changed_7day: string;
  value_per_set: number;
  fee: number;
  avatar_uri: string;
}
export interface Component {
  address: string;
  name: string;
  symbol: string;
  image: string;
  price_usd: number;
  amount: number;
  value: number;
  value_per_set: number;
}

export function useTokens(): any {
    const [tokens, setTokens] = useState<any>([]);
    const { chainId, account } = useActiveWeb3React()
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    const fetchTokens = useCallback(async () => {
      get(`${api_url}/sofi/tokens?address=${account}`).then((res: any) => {
        if(res && res.length > 0){
          setTokens(res)
        }
      });
    }, [account])
  
    useEffect(() => {
      if(chainId && account){
        fetchTokens().catch((err) => console.log("useTokens err", err))
      }
    }, [chainId, account])
    return {tokens}
}

export function useSetsInfo(contractAddress: string): setsInfoRes {
  const [setsInfo, setSetsInfo] = useState<any>({});
  const { chainId } = useActiveWeb3React()
  const api_url = chainId ? APILIST[chainId] : APILIST[4];
  const fetchSets = useCallback(async () => {
    get(`${api_url}/sofi/portfolios/${contractAddress}`).then((response: any) => {
      if (response) {
        setSetsInfo(response)
      }
    });
  }, [])

  useEffect(() => {
    fetchSets()
  }, [contractAddress])
  return setsInfo
}

export function useHotPortfolios(refresh?: number): any {
    const [portfolios, setPortfolios] = useState<any>([]);
    const { chainId } = useActiveWeb3React()
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    const fetchPortfolios = useCallback(async () => {
        get(`${api_url}/sofi/portfolios?hot=true`).then(async (res: any) => {
          if(res && res.length > 0){
            setPortfolios(res)
          }
        });
    }, [])
  
    useEffect(() => {
        fetchPortfolios()
    }, [chainId, refresh])
    return {portfolios}
}

export function usePortfolios(type: string, limit: number, refresh?: number): any {
    const [portfolios, setPortfolios] = useState<any>([]);
    const { chainId, account } = useActiveWeb3React()
    const fetchPortfolios = useCallback(async () => {
      const api_url = chainId ? APILIST[chainId] : APILIST[4];
      let params: any = {
        orderby: type,
        address: account,
        limit
      };
      if(!account){ delete params.address }
      let query = Object.keys(params)
      .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
        get(`${api_url}/v2/sofi/portfolios?${query}`).then(async (res: any) => {
            if (res && res.length > 0) {
              setPortfolios(res)
            }
        });
    }, [limit, chainId])
  
    useEffect(() => {
      if(chainId){
        fetchPortfolios()
      }
    }, [chainId, limit, refresh])
    return portfolios
}

export function useHomeInfoWithType (type: string = 'portfolios', param: any = {}, needQuery?: boolean): any {
  const [socialTradingData, setSocialTradingData] = useState<any>([]);
  const { chainId, account } = useActiveWeb3React()
  const api_url = chainId ? APILIST[chainId] : APILIST[4];
  const params = {
    address: account,
    buyer: account,
    ...param
  }
  let query = Object.keys(params).map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');

  const fetchSocialTrading = useCallback(() => {
    get(`${api_url}/v2/sofi/${type}?${query}`).then((res: any) => {
      if(res && res.length > 0){
        setSocialTradingData(res);
      }
    })
  }, [api_url]);

  useEffect(() => {
    fetchSocialTrading();
  }, [chainId, type])
  return socialTradingData;
}

export function usePredictData (type: string, param?: Record<string, any>) {
  const [ predict, setPredict ] = useState<any>([]);
  const { chainId, account } = useActiveWeb3React();
  const api_url = chainId ? APILIST[chainId] : APILIST[4];
  let params: any = {
    address: account,
    buyer: account,
  }
  if (param) params = param;
  let query = Object.keys(params).map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
  const fetchPredict = useCallback(() => {
    get(`${api_url}/sofi/${type}?${query}`).then((res: any) => {
      if(res && res.length > 0){
        setPredict(res);
      }
    })
  }, [api_url])
  useEffect(() => {
    fetchPredict();
  }, [type])

  return predict
}

export function useSearchPortfolios(search: string, refresh?: number): any {
  const [portfolios, setPortfolios] = useState<any>([]);
  const { chainId, account } = useActiveWeb3React()
  const api_url = chainId ? APILIST[chainId] : APILIST[4];
  const fetchPortfolios = useCallback(async () => {
    let params: any = {
      search,
      address: account,
    };
    if(!account){ delete params.address }
    let query = Object.keys(params)
    .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
      get(`${api_url}/v2/sofi/portfolios?${query}`).then(async (res: any) => {
        if(res && res.length > 0){
          setPortfolios(res)
        }
      });
  }, [search, api_url])

  useEffect(() => {
    if(search){
      fetchPortfolios()
    }
  }, [chainId, search, refresh])
  return portfolios
}

export function useAllPortfolios(address?: string | null, refresh?: number): any {
    const [allPortfolios, setAllPortfolios] = useState<any>([]);
    const { account, chainId } = useActiveWeb3React()
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    const contract =  usePortfolioContract(ProtocolViewer_Address[chainId ? chainId: 4])
    const fetchPortfolios = useCallback(async () => {
      let params: any = {
        address: account,
        manager: address
      };
      if(!account){ delete params.address }
      if(!address){ delete params.manager }
      let query = Object.keys(params)
      .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
      const url = `${api_url}/v2/sofi/portfolios?${query}`;
        get(url).then(async (res: any) => {
          if(res && res.length > 0){
            setAllPortfolios(res)
          }
        });
    }, [address, account, api_url])
  
    useEffect(() => {
        if(chainId){
            fetchPortfolios()
        }
    }, [chainId, address, refresh])
    return allPortfolios
}

export function useMyPortfolios(address?: string | null, refresh?: number): any {
    const [allPortfolios, setAllPortfolios] = useState<any>([]);
    const { chainId, account } = useActiveWeb3React()
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    const fetchPortfolios = useCallback(async () => {
      let params: any = {
        address: account,
        buyer: address,
      };
      if(!account){ delete params.address }
      let query = Object.keys(params)
      .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
      const url = address ? `${api_url}/sofi/portfolios?${query}` : `${api_url}/sofi/portfolios`;
        get(url).then(async (res: any) => {
          if(res && res.length > 0){
            setAllPortfolios(res)
          }
        });
    }, [address, account])
  
    useEffect(() => {
        if(chainId && address){
            fetchPortfolios()
        }
    }, [chainId, address, account, refresh])
    return {allPortfolios}
}

export function usePortfolioDetail(address: string, refresh?: number): any {
    const [portfolio, setPortfolio] = useState<any>({});
    const { account, chainId } = useActiveWeb3React()
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    const fetchPortfolio = useCallback(async () => {
      let params: any = {
        address: account,
      };
      if(!account){ delete params.address }
      let query = Object.keys(params)
      .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
      get(`${api_url}/sofi/portfolios/${address}?${query}`).then(async (res: any) => {
        if(res){
          setPortfolio(res)
        }
      });
    }, [address])
  
    useEffect(() => {
        if(address && chainId){
            fetchPortfolio()
        }
    }, [address, chainId, refresh])
    return {portfolio}
}

export function useChartData(address:any, type: string, refresh = 1): any {
  const [chartData, setChartData] = useState<any>([]);
  const { chainId, account } = useActiveWeb3React()
  const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
  const fetchChartData = useCallback(async () => {
    get(`${api_url}/sofi/portfolios/${address}/history?type=${type}`).then((res: any) => {
      if (res) {
        setChartData(res)
      }
    });
  }, [address, type])

  useEffect(() => {
    if (chainId) {
      fetchChartData();
    }
  }, [chainId, type, refresh])
  return {chartData}
}

export function useAllChartData(arr:any, type: string, refresh = 1): any {
  const [allChartData, setAllChartData] = useState<any>([]);
  const { chainId, account } = useActiveWeb3React()
  const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
  // let resArr = new Array(arr.length);
  const fetchChartData = useCallback(async (address: string) => {
    return get(`${api_url}/sofi/portfolios/${address}/history?type=${type}`).then((res: any) => {
      if (res) {
        return res.map((v: any) => {return {ts: v.ts, [address]: v.value}})
      }
    });
  }, [type, allChartData])

  const getAllData = useCallback(async() => {
    let resArr: any;
    for(let i = 0; i < arr.length; i++){
      resArr = await fetchChartData(arr[i]);
      setAllChartData((oldArr: any) => {
        if(oldArr.length === 0){
          return resArr;
        }
        return oldArr.map((v: any, i: number) => {return {...v, ...resArr[i]}})
      })
    }
  }, [arr, allChartData])

  useEffect(() => {
    if (chainId) {
      getAllData()
    }
  }, [chainId, type, refresh, arr.length])
  return {allChartData}
}

export function useMyPortfolioChartData(address:any, type: string, forceUpdate?: number): any {
  const [chartData, setChartData] = useState<any>({});
  const { chainId, account } = useActiveWeb3React()
  const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
  const fetchChartData = useCallback(async () => {
    get(`${api_url}/sofi/asset/history?buyer=${address}&type=${type}`).then((res: any) => {
      if(res){
        setChartData(res)
      }
    });
  }, [address, type])

  useEffect(() => {
    if (chainId && address) {
      fetchChartData();
    }
  }, [chainId, type, forceUpdate, address])
  return {chartData}
}

  export function useTokenChartData(symbol: string, resolution: string, from: number, to: number): any {
    const [chartData, setChartData] = useState<any>([]);
    const { chainId, account } = useActiveWeb3React()
    const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
    const fetchChartData = useCallback(async () => {
      get(`${api_url}/sofi/tokens?symbol=${symbol}`).then((res: any) => {
        if(res){
          setChartData(res)
        }
      });
    }, [symbol, resolution, from, to])
  
    useEffect(() => {
      if (chainId) {
        fetchChartData();
      }
    }, [chainId, symbol])
    return {chartData}
  }

  export function useAlertDetail(set: string, buyer: string, forceUpdate?: number): any {
    const [alertData, setAlertData] = useState<any>();
    const { chainId, account } = useActiveWeb3React()
    const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
    const fetchAlertData = useCallback(async () => {
      get(`${api_url}/sofi/alert?set=${set}&buyer=${buyer}`).then((res: any) => {
        if (res) {
          setAlertData(res)
        }
      });
    }, [set, buyer])
  
    useEffect(() => {
      if (chainId) {
        fetchAlertData();
      }
    }, [chainId, set, buyer, forceUpdate])
    return alertData
  }
  
  export function useManagersList(refresh?: number): any {
    const [list, setList] = useState<any>([]);
    const { chainId, account } = useActiveWeb3React()
    const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
    const fetchManagersList = useCallback(async () => {
      let params: any = {
        address:account
      };
      if(!account){ delete params.address }
      let query = Object.keys(params)
      .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
      get(`${api_url}/sofi/managers?${query}`).then((res: any) => {
        if(res && res.length > 0){
          setList(res)
        }
      });
    }, [account])
  
    useEffect(() => {
      if (chainId) {
        fetchManagersList();
      }
    }, [chainId, refresh])
    return { list }
  }

  export function useManagerInfo(address: any, refresh?: number): any {
    const [managerInfo, setManagerInfo] = useState<any>({});
    const { chainId, account } = useActiveWeb3React()
    const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
    const fetchManagerInfo = useCallback(async () => {
      let params: any = {
        address:account
      };
      if(!account){ delete params.address }
      let query = Object.keys(params)
      .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
      get(`${api_url}/sofi/managers/${address}?${query}`).then((res: any) => {
        if (res) {
          setManagerInfo(res)
        }
      });
    }, [account])
  
    useEffect(() => {
      if (chainId && address) {
        fetchManagerInfo();
      }
    }, [chainId, address, refresh])
    return { managerInfo }
  }

  export const postManagerProfile = async (data: any, address: string, chainId: ChainId, library: any) => {
    let sig, timestamp
    const sigResult = await getSign(library, address)
    sig = sigResult.sig
    timestamp = sigResult.timestamp
    const api_url_1 = APILIST[chainId];
    const api_url_2 = chainId === 137 ? APILIST[1] : APILIST[137];
    try {
      const url_1 = `${api_url_1}/sofi/managers/${address}`
      const res1 = await request(url_1, {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'mode': 'no-cors',
          'address': address,
          'signature': sig,
          'signatureMessage': timestamp
        },
        method: 'POST'
      });
      if(chainId === ChainId.RINKEBY){
        return res1;
      }else {
        const url_2 = `${api_url_2}/sofi/managers/${address}`
        const res2 = await request(url_2, {
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'mode': 'no-cors',
            'address': address,
            'signature': sig,
            'signatureMessage': timestamp
          },
          method: 'POST'
        });
        return res1 || res2
      }
    } catch (e) {
      console.log('get userinfo error', e)
    }
  }

  export function useManagerChartData(address:any, type: string): any {
    const [chartData, setChartData] = useState<any>([]);
    const { chainId, account } = useActiveWeb3React()
    const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
    const fetchChartData = useCallback(async () => {
      get(`${api_url}/sofi/managers/${address}/history?type=${type}`).then((res: any) => {
        if (res) {
          res.history = res.history.filter((v: any) => v.value !== 0);
          setChartData(res)
        }
      });
    }, [address, type])
  
    useEffect(() => {
      if (chainId) {
        fetchChartData();
      }
    }, [chainId, type])
    return {chartData}
  }

  export async function useGetAllowedTokens() {
    const [result, setResult] = useState([])
    const { chainId, library, account } = useActiveWeb3React()

    const setTokenCreatorContract =  useCreatePortfolioContract(portfolioFactory[chainId ? chainId: 4]);
    const getAllowTokensInfo = await setTokenCreatorContract?.getAllowedTokens()
    const promises = getAllowTokensInfo.map(async (tokenAddress: string) => {
      if (library && account) {
        const tokenContract = getContract(tokenAddress, ERC20_ABI, library, account)
        const symbol = await tokenContract?.symbol()
        const name = await tokenContract?.name()
        const decimals = await tokenContract?.name()
        return Promise.resolve({
          address: tokenAddress,
          symbol,
          name,
          decimals,
        })
      }
      return
    })
    
    console.log('result', result)
    return result
  }

  export const postPortfolioProfile = async (data: any, address: string, chainId: ChainId, library: any) => {
    let sig, timestamp
    const sigResult = await getSign(library, address)
    sig = sigResult.sig
    timestamp = sigResult.timestamp
    const api_url = chainId && address ? APILIST[chainId] : APILIST[4];
    try {
      const url = `${api_url}/sofi/portfolio`
      const res = await request(url, {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'mode': 'no-cors',
          'address': address,
          'signature': sig,
          'signatureMessage': timestamp
        },
        method: 'POST'
      });
      return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
  }

  export const useFollowersList = (address: string, refresh?: number) => {
    const [list, setList] = useState<any>([])
    const { chainId } = useActiveWeb3React()
    const fetchData = useCallback(async () => {
      const api_url = chainId ? APILIST[chainId] : APILIST[4];
      //const api_url = APILIST[4];
      try {
        const url = `${api_url}/sofi/follow?action=followers&address=${address}` 
        get(url).then(async (res: any) => {
          if(res && res.length > 0){
            setList(res);
          }
        })
      } catch (e) {
        console.log('get userinfo error', e)
      }
    }, [address, chainId])
    useEffect(() => {
      if(chainId){
        fetchData();
      }
    }, [address, chainId, refresh])
    return list
  }

  export const useFollowingList = (address: string, refresh?: number) => {
    const [list, setList] = useState<any>([])
    const { chainId } = useActiveWeb3React()
    const fetchData = useCallback(async () => {
      const api_url = chainId ? APILIST[chainId] : APILIST[4];
      //const api_url = APILIST[4];
      try {
        const url = `${api_url}/sofi/follow?action=following&address=${address}` 
        get(url).then(async (res: any) => {
          if(res && res.length > 0){
            setList(res);
          }
        })
      } catch (e) {
        console.log('get userinfo error', e)
      }
    }, [address, chainId])
    useEffect(() => {
      if(chainId){
        fetchData();
      }
    }, [address, chainId, refresh])
    return list
  }

  export const postFollowManager = async (follow: string, address: string, action: string, chainId: ChainId, library: any) => {
    let sig, timestamp
    const sigResult = await getSign(library, address)
    sig = sigResult.sig
    timestamp = sigResult.timestamp
    const api_url = chainId && address ? APILIST[chainId] : APILIST[4];
    const data = {
      follow,
      address,
      action
    }
    try {
      const url = `${api_url}/sofi/follow`
      const res = await request(url, {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'mode': 'no-cors',
          'address': address,
          'signature': sig,
          'signatureMessage': timestamp
        },
        method: 'POST'
      });
      return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
  }

  export const postFavoritePortfolio = async (portfolio: string, address: string, favorite: boolean, chainId: ChainId, library: any) => {
    let sig, timestamp
    const sigResult = await getSign(library, address)
    sig = sigResult.sig
    timestamp = sigResult.timestamp
    const api_url = chainId && address ? APILIST[chainId] : APILIST[4];
    const data = {
      portfolio,
      address,
      favorite
    }
    try {
      const url = `${api_url}/sofi/favorite`
      const res = await request(url, {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'mode': 'no-cors',
          'address': address,
          'signature': sig,
          'signatureMessage': timestamp
        },
        method: 'POST'
      });
      return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
  }

  export const useTransactionList = () => {
    const [list, setList] = useState<any>([])
    const { account, chainId } = useActiveWeb3React()
    const fetchData = useCallback(async () => {
      const api_url = chainId ? APILIST[chainId] : APILIST[4];
      try {
        const url = `${api_url}/sofi/transactions?address=${account}` 
        get(url).then(async (res: any) => {
          if(res && res.length > 0){
            setList(res);
          }
        })
      } catch (e) {
        console.log('get userinfo error', e)
      }
    }, [chainId])
    useEffect(() => {
      if(chainId){
        fetchData();
      }
    }, [chainId])
    return list
  }

  export const postSearchAI = async (search: string | null, chainId: ChainId, refresh?: number) => {
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    let params: any = {
      message: search,
    };
    try {
      const url = `${api_url}/sofi/ai`
      const res = await request(url, {
        body: JSON.stringify(params),
        method: 'POST'
      });
      return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
  }