import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { CloseCircleOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import numeral from 'numeral'
import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits, parseUnits } from '@ethersproject/units'
import { Button, Row, Col, Badge, Radio, Space, Progress, Modal, Table, Steps, notification, Drawer } from 'antd'
import ReactMarkdown from 'react-markdown'
import { useActiveWeb3React } from '../../hooks'
import { Container } from '../STS/Markets'
import STS from '../STS'
import { useProposalDetail, useVotesList } from './hooks'
import { getEtherscanLink, shortenAddress } from '../../utils'
import { useVoteContract } from 'hooks/useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useWalletModalToggle } from '../../state/application/hooks'
import './index.less'
import { useEffect } from 'react'
import { SOFI_DAO_contract_address } from '../../constants'
import { ChainId } from 'constants/chainId';
import { getSign } from 'utils/txSign'
import LinkOutlined from '@ant-design/icons/lib/icons/LinkOutlined'
import Link from '../../assets/images/link.png'
import chainIcon from '../../assets/images/vote/Chain.png'

const PrefixInteger = (num: number, n: number) => {
  //@ts-ignore
  return (Array(n).join(0) + num).slice(-n)
}

export const proposalStatus = (state: number) => {
  const STATUS: any = {
    0: <Trans>review</Trans>,
    1: <Trans>active</Trans>,
    2: <Trans>canceled</Trans>,
    3: <Trans>failed</Trans>,
    4: <Trans>passed</Trans>,
    5: <Trans>passed</Trans>,
    6: <Trans>failed</Trans>,
    7: <Trans>passed</Trans>,
    8: <Trans>passed</Trans>
  }
  return STATUS[state]
}

export const voteStatus = (state: number) => {
  const STATUS: any = {
    0: <Trans>created</Trans>,
    1: <Trans>active</Trans>,
    2: <Trans>canceled</Trans>,
    3: <Trans>defeated</Trans>,
    4: <Trans>succeeded</Trans>,
    5: <Trans>queued</Trans>,
    6: <Trans>expired</Trans>,
    7: <Trans>executed</Trans>,
    8: <Trans>funded</Trans>
  }
  return STATUS[state]
}

export const proposalStateColor: { [state: number]: string } = {
  0: '#9A9A9A',
  1: '#018526',
  2: '#9A9A9A',
  3: '#9A9A9A',
  4: '#018526',
  5: '#018526',
  6: '#9A9A9A',
  7: '#018526',
  8: '#018526'
}

export default function ProposalsDetail(props: RouteComponentProps<{ contract: string; proposalId: string }>) {
  const {
    match: {
      params: { contract, proposalId }
    }
  } = props
  const [showAllVotesModal, setShowAllVotesModal] = useState<boolean>(false)
  const [showVoteModal, setShowVoteModal] = useState<boolean>(false)
  const [voteApprove, setVoteApprove] = useState<boolean>(true)
  const [needTokenAmount, setNeedTokenAmount] = useState(BigNumber.from(0))
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [cancelClaimLoading, setCancelClaimLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const { chainId, account, library } = useActiveWeb3React()
  const history = useHistory()
  const proposalInfo = useProposalDetail(contract, proposalId, chainId, account)
  const votesList = useVotesList(proposalId, chainId)
  const voteContract = useVoteContract(SOFI_DAO_contract_address[chainId || ChainId.RINKEBY])
  const [quorumVoteCount, setQuorumVoteCount] = useState(BigNumber.from(1))
  const addTransaction = useTransactionAdder()
  const toggleWalletModal = useWalletModalToggle()
  const { t } = useTranslation()
  const onChange = (e: any) => {
    setVoteApprove(e.target.value)
  }
  const actions = proposalInfo.actions ? proposalInfo.actions.split('\n') : []
  useEffect(() => {
    if (voteContract) {
      voteContract.quorumVoteCount().then((res: any) => {
        setQuorumVoteCount(res)
      })
      voteContract.proposalThresholdCount().then((res: any) => {
        setNeedTokenAmount(res)
      })
    }
  }, [])
  const enterQueue = () => {
    if (voteContract) {
      voteContract.queue(proposalId).then((res: any) => {
        console.log('res', res)
        addTransaction(res, {
          summary: 'Proposal queue'
        })
      })
    }
  }

  const execute = () => {
    if (voteContract) {
      voteContract.execute(proposalId).then((res: any) => {
        addTransaction(res, {
          summary: 'Proposal execute'
        })
      })
    }
  }

  const handleVote = async () => {
    if (voteContract) {
      const signature = await getSign(library, account)
      console.log('signature', signature)
      setConfirmLoading(true)
      voteContract
        .castVoteBySig(proposalId, voteApprove, proposalInfo.available_votes, proposalInfo.signature)
        .then((res: any) => {
          addTransaction(res, {
            summary: 'Vote Success'
          })
          setConfirmLoading(false)
          setShowVoteModal(false)
        })
        .catch((error: any) => {
          console.error('Vote Failed', error)
          if (error.code === 4001) {
            notification['error']({
              message: '',
              description: t('cancelled_transaction')
            })
          } else {
            notification['error']({
              message: '',
              description: error.message
            })
          }
          setConfirmLoading(false)
        })
    }
  }

  const cancleVote = async () => {
    if (voteContract) {
      setCancelClaimLoading(true)
      voteContract
        .cancel(proposalId)
        .then((res: any) => {
          addTransaction(res, {
            summary: t('cancel_vote_success')
          })
          setCancelClaimLoading(false)
        })
        .catch((error: any) => {
          if (error.code === 4001) {
            notification['error']({
              message: '',
              description: t('cancelled_transaction')
            })
          } else {
            notification['error']({
              message: '',
              description: error.message
            })
          }
          setCancelClaimLoading(false)
          console.error('Vote Failed', error)
        })
    }
  }

  const claim = async () => {
    if (voteContract) {
      setCancelClaimLoading(true)
      voteContract
        .claim(proposalId)
        .then((res: any) => {
          addTransaction(res, {
            summary: t('claim_success')
          })
          setCancelClaimLoading(false)
        })
        .catch((error: any) => {
          if (error.code === 4001) {
            notification['error']({
              message: '',
              description: t('cancelled_transaction')
            })
          } else {
            notification['error']({
              message: '',
              description: error.message
            })
          }
          setCancelClaimLoading(false)
          console.error('Vote Failed', error)
        })
    }
  }
  const columns = [
    {
      title: t('users'),
      dataIndex: 'voter',
      key: 'voter',
      render: (v: any) => shortenAddress(v)
    },
    {
      title: t('choose'),
      dataIndex: 'support',
      key: 'support',
      render: (v: any) => (v ? t('approve') : t('disapprove'))
    },
    {
      title: t('votes'),
      dataIndex: 'votes',
      key: 'votes',
      render: (v: any) => numeral(BigNumber.from(v).div(BigNumber.from(10).pow(18))).format(' 0,00')
    }
  ]

  const getPercent = (count: string, against: string): number => {
    if (!count || !against) return 0
    const rate = BigNumber.from(count)
      .mul(100)
      .div(BigNumber.from(against).add(BigNumber.from(count)))
      .toString()
    console.log('rate', rate)
    return Number(rate)
  }
  return (
    <STS>
      <Container>
        <div className="proposals-detail">
          <Button
            type="default"
            onClick={() => {
              history.push(`/proposals`)
            }}
          >
            {t('back')}
          </Button>
          <Row gutter={48}>
            <Col sm={16} xs={24}>
              <div className="card">
                <div className="header">
                  <div className="header_vote_info">
                    {/* <div className="proposal_id">{`SOFIP-${PrefixInteger(Number(proposalId), 3)}`}</div> */}
                    {proposalInfo.actions && (
                      <div className="chain_box">
                        <div>
                          <img src={chainIcon} alt="" />
                        </div>
                        <div className="chain_txt">On-chain</div>
                      </div>
                    )}
                  </div>
                  <div className="vote_type">
                    {proposalInfo.actions ? t('edit_portfolio_list') : t('marketing_campaign')}
                  </div>
                  <div className="header_title">
                    {/* <p className="created">{t('created_at')} {moment(proposalInfo.createTime * 1000).format('YYYY-MM-DD HH:mm:ss')}</p> */}
                    {/* <h2> */}
                    {proposalInfo.title}
                    {/* </h2> */}
                  </div>
                  <div className="head_down">
                    {proposalInfo.state === 4 && proposalInfo.actions !== '' ? (
                      <Button type="primary" onClick={() => enterQueue()}>
                        Queue
                      </Button>
                    ) : proposalInfo.state === 5 ? (
                      <Button
                        type="primary"
                        onClick={() => execute()}
                        disabled={new Date().getTime() / 1000 < proposalInfo.eta}
                      >
                        Execute
                      </Button>
                    ) : (
                      <div
                        className={`tag ${
                          proposalInfo.state === 0
                            ? 'soon'
                            : proposalInfo.state === 1 ||
                              proposalInfo.state === 4 ||
                              proposalInfo.state === 5 ||
                              proposalInfo.state === 7 ||
                              proposalInfo.state === 8
                            ? 'active'
                            : 'closed'
                        }`}
                      >
                        {proposalStatus(proposalInfo.state)}
                      </div>
                    )}
                    <p className="ended">
                      {t('ended_at')} {moment(proposalInfo.endTime * 1000).format('YYYY-MM-DD HH:mm:ss')}
                    </p>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="body">
                  <div className="body_head">
                    <div className="module_title">{t('status')}</div>
                    <div>
                      <Button
                        icon={<CheckCircleOutlined />}
                        type="default"
                        onClick={() => {
                          setShowAllVotesModal(true)
                        }}
                      >
                        {t('view_all_votes')}
                      </Button>
                    </div>
                  </div>
                  <div className="approve">
                    <div className="item_contariner">
                      <div className="item">
                        <div className="item_label">{t('approve')}</div>
                        <div className="item_value">
                          {proposalInfo.forVotes &&
                            numeral(BigNumber.from(proposalInfo.forVotes).div(BigNumber.from(10).pow(18))).format(
                              ' 0,00'
                            )}
                        </div>
                      </div>
                      <div className="item">
                        <div className="item_label">{t('disapprove')}</div>
                        <div className="item_value">
                          {proposalInfo.againstVotes &&
                            numeral(BigNumber.from(proposalInfo.againstVotes).div(BigNumber.from(10).pow(18))).format(
                              ' 0,00'
                            )}
                        </div>
                      </div>
                    </div>
                    <Progress
                      strokeColor={'#14B8A6'}
                      percent={proposalInfo.forVotes !== '0' ? getPercent(proposalInfo.forVotes, proposalInfo.againstVotes) : 0}
                      showInfo={false}
                    />
                    <Progress className='againstProgress' strokeColor={'#5542F6'} percent={proposalInfo.againstVotes !== '0' ? 100 - getPercent(proposalInfo.forVotes, proposalInfo.againstVotes) : 0} showInfo={false}/>
                  </div>
                  {/* <div className="approve">
                      <div className="item">
                          <div>
                              {t('disapprove')}
                          </div>
                          <div>
                          {proposalInfo.againstVotes && numeral(BigNumber.from(proposalInfo.againstVotes).div(BigNumber.from(10).pow(18))).format(" 0,00")}
                          </div>
                      </div>
                      <Progress strokeColor={`${proposalInfo.againstVotes >  proposalInfo.forVotes? '#5542F6' : '#EEECFE'}`} percent={proposalInfo.againstVotes > 0 ? (getPercent(proposalInfo.againstVotes)) : 0} showInfo={false}/>
                  </div> */}
                </div>
                <div className="divider"></div>
              </div>
              <div className="vote-info">
                <div className="module_title">{t('detail')}</div>
                <div className="vote_info_contariner">
                  <div className="vote_info_item">
                    <div className="item_label">{t('initiator')}</div>
                    <div className="item_value">{proposalInfo.proposer && shortenAddress(proposalInfo.proposer)}</div>
                  </div>
                  <div className="vote_info_item">
                    <div className="item_label">{t('snapshot')}</div>
                    <div className="item_value">
                      <a target="_blank" href={getEtherscanLink(chainId ?? 1, proposalInfo.blockHeight, 'block')}>
                        {proposalInfo.blockHeight}
                      </a>
                    </div>
                  </div>
                  <div className="vote_info_item">
                    <div className="item_label">{t('start_time')}</div>
                    <div className="item_value">
                      {moment(proposalInfo.startTime * 1000).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                  </div>
                  <div className="vote_info_item">
                    <div className="item_label">{t('end_time')}</div>
                    <div className="item_value">
                      {moment(proposalInfo.endTime * 1000).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                  </div>
                  {proposalInfo.proposer === account && (
                    <div className="vote_info_item">
                      <div className="item_label">{t('staked')}</div>
                      <div className="item_value">{formatUnits(needTokenAmount, 18)} SOFI</div>
                    </div>
                  )}
                </div>
                {proposalInfo.proposer === account && !proposalInfo.withdrawn && proposalInfo.state > 2 && (
                  <Button type="primary" ghost block onClick={claim} disabled={cancelClaimLoading}>
                    {t('claim_assets')}
                  </Button>
                )}
                {proposalInfo.proposer === account && proposalInfo.state < 2 && (
                  <Button type="primary" ghost block onClick={cancleVote} disabled={cancelClaimLoading}>
                    {t('cancel_proposal')}
                  </Button>
                )}
              </div>
              <div className="divider"></div>
              <div className="details">
                <div className="module_title">{t('content')}</div>
                {proposalInfo.actions && actions.map((v: any) => (v ? <div className="contract">{v}</div> : null))}
                <ReactMarkdown>{proposalInfo.description}</ReactMarkdown>
              </div>
            </Col>
            <Col sm={8} xs={24}>
              <div className="vote">
                <h2>{t('vote')}</h2>
                {!account ? (
                  <Button type="primary" onClick={toggleWalletModal}>
                    {t('conect_wallet')}
                  </Button>
                ) : (
                  <>
                    {proposalInfo.already_votes === '0' && (
                      <Radio.Group value={voteApprove} onChange={onChange} optionType="button">
                        <Space direction="vertical">
                          <Radio value={true}>{t('approve')}</Radio>
                          <Radio value={false}>{t('disapprove')}</Radio>
                        </Space>
                      </Radio.Group>
                    )}
                    {proposalInfo.already_votes > 0 && (
                      <div className="item">
                        <div className="item_label">{t('your_choice')}</div>
                        <div className="item_value">{proposalInfo.approve ? t('approved') : t('disapprove')}</div>
                      </div>
                    )}
                    <div className="item">
                      <div className="item_label">{t('available_votes')}</div>
                      <div className="item_value">
                        {proposalInfo.available_votes &&
                          numeral(BigNumber.from(proposalInfo.available_votes).div(BigNumber.from(10).pow(18))).format(
                            ' 0,00'
                          )}
                      </div>
                    </div>
                    {proposalInfo.already_votes === '0' && (
                      <Button
                        type="primary"
                        block
                        onClick={() => {
                          setShowVoteModal(true)
                        }}
                        disabled={
                          proposalInfo.state !== 1 ||
                          Number(BigNumber.from(proposalInfo.available_votes).div(BigNumber.from(10).pow(18))) <= 0
                        }
                      >
                        {t('vote_now')}
                      </Button>
                    )}
                  </>
                )}
              </div>

              <div className="step">
                <div>
                  {proposalInfo.state_history &&
                    proposalInfo?.state_history?.map((history: any) => {
                      return (
                        <div>
                          <div className="vote_history_item">
                            <div>
                              <ClockCircleOutlined />
                            </div>
                            <div className="vote_history_content">
                              <div className="vote_history_state">{voteStatus(history.state)}</div>
                              <div className="vote_history_time">
                                {moment(history.start_time * 1000).format('MMMM Do,YYYY- h:mma')}
                              </div>
                            </div>
                            <div>
                              {history.txid && (
                                <img
                                  src={Link}
                                  onClick={() =>
                                    window.open(
                                      `${getEtherscanLink(chainId ?? ChainId.MAINNET, history.txid, 'transaction')}`
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Drawer
          title={t('all_votes')}
          placement="right"
          visible={showAllVotesModal}
          onClose={() => {
            setShowAllVotesModal(false)
          }}
        >
          {votesList.map((item: any) => {
            return (
              <div className="vote_list_item">
                <div className="vote_list_support">{item.support ? t('approve') : t('disapprove')}</div>
                <div className="vote_list_vote">
                  <div className="vote_list_voter">{item.voter && shortenAddress(item.voter)}</div>
                  <div className="vote_list_votes">
                    {numeral(BigNumber.from(item.votes).div(BigNumber.from(10).pow(18))).format(' 0,00')} Votes
                  </div>
                </div>
              </div>
            )
          })}
        </Drawer>
        <Modal
          className="voteModal"
          title={t('confirm_votes')}
          okText={t('vote')}
          cancelText={t('cancel')}
          visible={showVoteModal}
          onCancel={() => {
            setShowVoteModal(false)
          }}
          confirmLoading={confirmLoading}
          onOk={handleVote}
        >
          <div className="item">
            <div>{t('voting_for')}</div>
            <div className="detail">{voteApprove ? t('approve') : t('disapprove')}</div>
          </div>
          <div className="item">
            <div>{t('your_votes')}</div>
            <div className="detail">
              <div>
                {proposalInfo.available_votes &&
                  numeral(BigNumber.from(proposalInfo.available_votes).div(BigNumber.from(10).pow(18))).format(' 0,00')}
              </div>
            </div>
          </div>
          <p className="text">{t('vote_note')}</p>
        </Modal>
      </Container>
    </STS>
  )
}
