import { useMemo } from 'react'
import { Currency, Token } from '@uniswap/sdk'
import { isAddress } from '../../utils'

export function filterTokens(tokens: Token[], search: string): Token[] {
  if (search.length === 0) return tokens

  const searchingAddress = isAddress(search)

  if (searchingAddress) {
    return tokens.filter(token => token.address === searchingAddress)
  }

  const lowerSearchParts = search
    .toLowerCase()
    .split(/\s+/)
    .filter(s => s.length > 0)

  if (lowerSearchParts.length === 0) {
    return tokens
  }

  const matchesSearch = (s: string): boolean => {
    const sParts = s
      .toLowerCase()
      .split(/\s+/)
      .filter(s => s.length > 0)

    return lowerSearchParts.every(p => p.length === 0 || sParts.some(sp => sp.startsWith(p) || sp.endsWith(p)))
  }

  return tokens.filter(token => {
    const { symbol, name } = token
    return (symbol && matchesSearch(symbol)) || (name && matchesSearch(name))
  })
}

export function useSortedTokensByQuery(tokens: Token[] | undefined, searchQuery: string): Token[] {
  return useMemo(() => {
    if (!tokens) {
      return []
    }

    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter(s => s.length > 0)

    if (symbolMatch.length > 1) {
      return tokens
    }

    const exactMatches: Token[] = []
    const symbolSubtrings: Token[] = []
    const rest: Token[] = []

    // sort tokens by exact match -> subtring on symbol match -> rest
    tokens.map(token => {
      if (token.symbol?.toLowerCase() === symbolMatch[0]) {
        return exactMatches.push(token)
      } else if (token.symbol?.toLowerCase().startsWith(searchQuery.toLowerCase().trim())) {
        return symbolSubtrings.push(token)
      } else {
        return rest.push(token)
      }
    })

    return [...exactMatches, ...symbolSubtrings, ...rest]
  }, [tokens, searchQuery])
}


export function filterPairs(pairs: any, tokens: Token[], otherSelectedCurrency:  Currency | undefined | null): Token[] {
  if (otherSelectedCurrency === null || !otherSelectedCurrency) return tokens

  let filterTokens: Token[] = [];
  for(let i = 0; i < pairs.length; i++){
    if(pairs[i].token0.symbol === otherSelectedCurrency?.symbol){
      const index = tokens.findIndex((token: Token) => token.symbol === pairs[i].token1.symbol)
      if(index >= 0){
        filterTokens.push(tokens[index]);
      }
    }
    if(pairs[i].token1.symbol === otherSelectedCurrency?.symbol){
      const index = tokens.findIndex((token: Token) => token.symbol === pairs[i].token0.symbol)
      if(index >= 0){
        filterTokens.push(tokens[index]);
      }
    }
  }
  return filterTokens;
}