import { useEffect, useState, useCallback } from 'react';
import { ChainId } from 'constants/chainId';
import { useActiveWeb3React } from '../../hooks'
import { get } from '../../utils/request';
import { getSign } from 'utils/txSign';
import { APILIST } from '../../constants'

export const useProposals = (chainId: ChainId | undefined) => {
  const [list, setList] = useState<any>([])
  const fetchProposals = useCallback(async () => {
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    //const api_url = APILIST[4];
    try {
      const url = `${api_url}/sofi/proposals` 
      get(url).then(async (res: any) => {
        if(res && res.length > 0){
          setList(res);
        }
      })
    } catch (e) {
      console.log('get userinfo error', e)
    }
  }, [chainId])
  useEffect(() => {
    if(chainId){
      fetchProposals();
    }
  }, [chainId])
  return list
}

export const useMyCreatedProposals = (address: string) => {
  const [list, setList] = useState<any>([])
  const { chainId } = useActiveWeb3React();
  const fetchProposals = useCallback(async () => {
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    //const api_url = APILIST[4];
    try {
      const url = `${api_url}/sofi/proposals?proposer=${address}` 
      get(url).then(async (res: any) => {
        if(res && res.length > 0){
          setList(res);
        }
      })
    } catch (e) {
      console.log('get userinfo error', e)
    }
  }, [chainId, address])
  useEffect(() => {
    if(chainId){
      fetchProposals();
    }
  }, [chainId, address])
  return list
}

export const useProposalDetail = (contract: string, proposalId: string, chainId: ChainId | undefined, account: any) => {
  const [detail, setDetail] = useState<any>({})
  const fetchProposals = useCallback(async () => {
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    //const api_url = APILIST[4];
    try {
      let params: any = account ? {
        proposalId: proposalId,
        contract: contract,
        address: account
      } : {
        proposalId: proposalId,
        contract: contract
      };
      
      let query = Object.keys(params)
                   .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                   .join('&');
      const url = `${api_url}/sofi/proposal?${query}` 
      get(url).then(async (res: any) => {
        if(res){
          setDetail(res);
        }
      })
    } catch (e) {
      console.log('get userinfo error', e)
    }
  }, [chainId, account])
  useEffect(() => {
    if(chainId){
      fetchProposals();
    }
  }, [chainId, account])
  return detail
}

export const useVotesList = (proposalId: string, chainId: ChainId | undefined,) => {
  const [list, setList] = useState<any>([])
  const fetchVotesList = useCallback(async () => {
    const api_url = chainId ? APILIST[chainId] : APILIST[4];
    //const api_url = APILIST[4];
    get(`${api_url}/sofi/votes?proposalId=${proposalId}`).then(async (res: any) => {
      if(res && res.length > 0){
        setList(res);
      }
    })
  }, [])
  useEffect(() => {
    fetchVotesList()
  }, [])
  return list;
}
