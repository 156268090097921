import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import LOGO_URL from '../assets/images/logo.png'
import { ChainId } from 'constants/chainId'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
const WALLETCONNECT_BRIDGE_URL = process.env.REACT_APP_WALLETCONNECT_BRIDGE_URL

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '8453')

export const supportedChainIds = [ChainId.MAINNET, ChainId.RINKEBY, ChainId.POLYGON, ChainId.BASE, ChainId.BASE_GOERLI, ChainId.TAIKO, ChainId.BLAST_SEPOLIA]

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: { 
    [ChainId.MAINNET]: NETWORK_URL ,
    [ChainId.POLYGON]: 'https://polygon-rpc.com/',
    [ChainId.BASE]: 'https://base-mainnet.g.alchemy.com/v2/a6e-68W-TjtgymZS54xkS0zIowW72VDA'
  },
  defaultChainId: NETWORK_CHAIN_ID
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 1: NETWORK_URL },
  bridge: WALLETCONNECT_BRIDGE_URL,
  qrcode: true,
  pollingInterval: 15000
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Rai Finance',
  appLogoUrl: LOGO_URL,
  supportedChainIds
})

export enum ConnectorNames {
  Injected = 'injected',
  Walletlink = 'walletlink',
}

export const connectors = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.Walletlink]: walletlink,
};

