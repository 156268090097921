import { Currency, CurrencyAmount, ETHER, Token, TokenAmount } from '@uniswap/sdk'
import {  WETH } from '../constants'
import { ChainId } from 'constants/chainId';

export function wrappedCurrency(currency: Currency | undefined | any, chainId: ChainId | undefined): Token | undefined {
  return chainId && currency === ETHER ? WETH[chainId] : currency instanceof Token ? currency : currency && chainId ? new Token(chainId, currency.address, currency.decimals,currency.symbol, currency.name) : undefined
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: ChainId | undefined
): TokenAmount | undefined {
  const token = currencyAmount && chainId ? wrappedCurrency(currencyAmount.currency, chainId) : undefined
  return token && currencyAmount ? new TokenAmount(token, currencyAmount.raw) : undefined
}

export function unwrappedToken(token: Token): Currency {
  if (token.equals(WETH[token.chainId])) return ETHER
  return token
}
