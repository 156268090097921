import React, {useState} from 'react'
import numeral from 'numeral';
import { Modal, Form, Input, Button, Checkbox, message } from 'antd';
import { useTranslation } from 'react-i18next'
import { useActiveWeb3React } from '../../hooks'
import { postManagerProfile } from './hooks'
import { APILIST } from '../../constants'

const inputRegex = /^\d+(\.\d{1,2})?$/

export default function ({isOpen, handleCancel, managerInfo}: any){
    const { chainId, account, library } = useActiveWeb3React()
    const { t } = useTranslation()
    const [form] = Form.useForm();
    const onFinish = async (values: any) => {
        console.log('Success:', values);
        if(account && chainId && library){
            const res = await postManagerProfile({
                profile: values.profile,
                name: values.name,
                mainnet_eth: values.mainnet_eth
            }, account, chainId, library);
            if(res){
                message.success(t('setting_success'))
                handleCancel()
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    
    return (
        <Modal title="Account Profile" visible={isOpen} footer={null} onCancel={handleCancel}>
           <Form
                name="basic"
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 15 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                hideRequiredMark
                className="waveModal"
                form={form}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    initialValue={managerInfo.name}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="profile"
                    initialValue={managerInfo.profile}
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>
                <Form.Item
                    label="Mainnet Address"
                    name="mainnet_eth"
                    initialValue={managerInfo.mainnet_eth}
                >
                    <Input/>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 9, span: 15 }}>
                    <Button type="default" onClick={handleCancel}>
                        {t('cancel')}
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button type="primary" htmlType="submit">
                        {t('save')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    ) 
}