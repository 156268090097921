import React, {useCallback, useEffect, useRef, useState, useMemo} from 'react'
import {Link, useHistory, useLocation} from "react-router-dom";
import { ChevronDown } from 'react-feather'
import styled from 'styled-components'
import Footer from 'rc-footer';
import {Badge, Carousel, Divider, Drawer, Dropdown, Input, Layout, Menu, Radio, Select, Space, Tag} from 'antd';
import {DownOutlined, MenuOutlined, SettingOutlined} from '@ant-design/icons';
import { BigNumber } from '@ethersproject/bignumber'
import { hexStripZeros } from '@ethersproject/bytes'
import { getChain } from 'constants/index'
import Web3Status from '../../components/Web3Status'
import {ChainLogo} from 'components/CurrencyLogo'
import {useActiveWeb3React} from '../../hooks'
import ReedemModal from './ReedemModal';
import {themes} from '../../themes';
import {useTranslation} from 'react-i18next';
import FaucetModal from './AccountFaucetModal'
import i18n from 'i18next';
import RAILogo from "../../assets/svg/logo.svg"
import './index.less';
import DynamicTheme from '../../theming';
import {useOnClickOutside} from 'hooks/useOnClickOutside';
import TermOfUseModal from '../PredictX/TermOfUseModal';
import { ChainId } from 'constants/chainId';
import {ReactComponent as TwitterLogo} from 'assets/svg/twitter.svg'
import {ReactComponent as MediumLogo} from 'assets/svg/medium.svg'
import {ReactComponent as TelegramLogo} from 'assets/svg/telegram.svg'
import {ReactComponent as WikiLogo} from 'assets/svg/wiki.svg'
import {ReactComponent as FrameLogo} from 'assets/svg/frame.svg'
import Bg1 from 'assets/images/headTail/bg1.jpg'
import Bg2 from 'assets/images/headTail/bg2.png'
import Bg3 from 'assets/images/headTail/bg3.png'
import BannerText from 'assets/images/headTail/head_tail_text.png'
import Top from 'assets/images/top.jpg'

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;
const { Search } = Input;


const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  line-height: 64px;
  height: 64px;
  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }
  .ant-btn{
    height: 40px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};

  #connect-wallet{
      border:1px solid #5542F6;
      height: 32px;
      margin-left: 10px;
      p{
        height: 30px;
        line-height: 30px;
    }
  }
  #web3-status-connected{
      border: none;
    &:focus{
        box-shadow: none;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 0;
    `};
    p{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    span{
        margin-left: 5px;
    }
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)}; */

  background: transparent;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const SideBarFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &>div{
    margin: 10px 0;
    }

`
const StyledLink = styled.a`
  svg{
      margin: 0 10px;
  }
`
const Logo = styled.img`
    width: 44px;
    height: auto;
    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 30px;
    `};
`
const MarketsImg = styled.img`
    width: 18px;
`
const WikiLink = styled.a`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000000;
    svg {
        width: 18px;
        margin-right: 8px;
    }
`

const HeaderContent = styled.div`
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>div{
        &:first-of-type{
            display: flex;
            align-items:center;
        }
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 0 20px;
    `};
    
`
const SwitchNetwork = styled.div`
    display: flex;
    align-items: center;
    .ant-radio-button-wrapper{
        ${({ theme }) => theme.mediaWidth.upToSmall`
            padding: 0 5px;
        `}
    }
`

const SwitchItem = styled.div`
    padding: 0 10px;
`

const options = themes.map((theme) => ({
  label: theme.displayName,
  value: theme.id
}));

const STS = ({ children, isSearchDrawerVisible, onClose, onSearch }: any) =>  {
    const { t } = useTranslation();
    const { account, chainId, library } = useActiveWeb3React()
    const [showPredict, setShowPredict] = useState(false);
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [networkValue, setNetworkValue] = useState(chainId)
    const [isScroll, setIsScroll] = useState(false);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [isShowFaucetModal, setIsShowFaucetModal] = useState(false)
    const [isShowReedemModal, setIsShowReedemModal] = useState(false)
    const [showTermOfUseModal,  setShowTermOfUseModal] = useState(false)
    const {pathname} = useLocation();
    const ref = useRef()
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
    const [themeId, setThemeId] = useState(localStorage.getItem("rai_theme") || themes[0].id);
    const history = useHistory();
    const [isSubscribed, setISubscribed] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const changeThemeId = (e: any) => {
        setThemeId(e.target.value)
        localStorage.setItem('rai_theme', e.target.value)
        window.location.reload()
    }
    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
    };

    useOnClickOutside(ref, isNavVisible ? toggleNav : undefined)

    const scrollHeader = () => {
        if (!document.querySelector('.ant-layout-header')) {
            return;
        }
    
        var scrollTop = document.documentElement.scrollTop;
        if (scrollTop >= 60) {
          setIsScroll(true)
        } else {
          setIsScroll(false)
        }
    }


    let selectedKey: string = pathname;
    if(pathname === '/' || pathname.includes('sts')){
        selectedKey = '/sts'
    }
    if(pathname.includes('ido')){
        selectedKey = '/ido'
    }

    const onCloseCollapse = () => {
        setCollapsed(false);
    };

    const toggle = () => {
        setCollapsed(!collapsed)
    };

    const changeLocale = (e: any) => {
        i18n.changeLanguage(e.target.value);
        localStorage.setItem('rai_lang', e.target.value)
    }
    const isSTS = pathname === '/'
    || pathname.includes("/sts")
    useEffect(() => {
        const ethereum = window.ethereum
        if (ethereum && ethereum.on) {
            ethereum.on('chainChanged', (_chainId: any) => {
                window.location.reload()
            });
            ethereum.on('disconnect', (_err: any) => {
                window.location.reload()
            });
            ethereum.on('accountsChanged', (_err: any) => {
                window.location.reload()
            });
        }
    }, [])

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 800px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);
        window.addEventListener('scroll', scrollHeader, false);
        return () => {
          mediaQuery.removeListener(handleMediaQueryChange);
          window.removeEventListener('scroll', scrollHeader, false);
        };
    }, []);

    const handleMediaQueryChange = (mediaQuery: any) => {
        if (mediaQuery.matches) {
          setIsSmallScreen(true);
        } else {
          setIsSmallScreen(false);
        }
    };

    const fetchData = useCallback(async () => {
        fetch(`https://prodapi.rai.finance/eth/sofi/markets`).then(async (res: any) => {
            if (res) {
                setShowPredict(true)
            }
        }).catch(async (error: any) => {
            if (error) {
                console.log('error', error)
            }
        });
    }, [])

    useEffect(() => {
        if(!account || chainId === 1 || (chainId  === 1101)){
            fetchData()
        }
    }, [account, chainId, fetchData])

    const menu = (
        <Menu className='more-menu'>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://rai-finance.gitbook.io/rai-finance/">
                    <span>Wiki</span><WikiLogo/> 
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/RaiFinance">
                    <span>Twitter</span><TwitterLogo/> 
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://medium.com/rai-finance">
                    <span>Medium</span><MediumLogo/>
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="https://t.me/RaiFinance">
                    <span>Telegram</span><TelegramLogo/>
                </a>
            </Menu.Item>
            {showPredict && (
                <Menu.Item>
                    <Link to="/predict">
                        <span>Predict</span>
                    </Link>
                </Menu.Item>
            )}
        </Menu>
      );

      const switchNetwork = async (chainId: ChainId) => {
        setNetworkValue(chainId);
        const chain = getChain(chainId);
        if(library &&  library.provider.request){
            try {
                await library.provider.request({
                  method: "wallet_switchEthereumChain",
                  params: [{
                    chainId: '0x' + chainId.toString(16),
                  }]
                });
                window.location.reload()
              } catch (switchError: any) {
                // 4902 error code indicates the chain is missing on the wallet
                if (switchError.code === 4902) {
                  try {
                    await library.provider.request({
                      method: "wallet_addEthereumChain",
                      params: [
                        {
                            chainId: '0x' + chainId.toString(16),
                            rpcUrls: [chain?.rpcUrl],
                            chainName: chain?.name,
                            nativeCurrency: {
                                name: chain?.tokenSymbol,
                                symbol: chain?.tokenSymbol,
                                decimals: 18,
                            },
                            blockExplorerUrls: [chain?.scanUrl],
                        },
                      ],
                    });
                    window.location.reload()
                  } catch (error) {
                     console.error(error)
                  }
                }
              }
        }
      };
      const handleSubscribe = useCallback(() => {
          window.CBWSubscribe.toggleSubscription();
      }, []);
      
      useEffect(() => {
          window.CBWSubscribe && window.CBWSubscribe.createSubscriptionUI({
              // Address user will be subscribing to.
              partnerAddress: '0x07ED900Ac06533A6762Cad2cDFDfEc1B0D7647e4',
              partnerName: 'RAI Finance',
              // Title for subscribe modal. See pictures below.
              modalTitle: 'Subscribe to Rai Finance Official Channel!',
              // Description title for the subscribe modal. See pictures below.
              modalBody: 'Being a top 5 biggest holder base project in Base, we have a lot more to tell you about our upcoming update including future airdrop events.',
              onSubscriptionChange: setISubscribed,
              onLoading: setIsLoading
          });
      }, []);

    return (
        <Layout style={{width: '100%', minHeight: '750px'}}>
            <div className='top' onClick={handleSubscribe}></div>
            <Header className={`header ${pathname === '/headTail' ? 'headTail' : ''} ${isScroll ? 'scroll': ''}`}>
                {/* <div className='header-notification'>
                    <TextScroller text={<>{t('header_notification')}<a href="https://legacy.rai.finance/" target="_blank">https://legacy.rai.finance/</a></>}></TextScroller>
                </div> */}
                <HeaderContent>
                    <div>
                        <button onClick={toggleNav} className="Burger">
                            <MenuOutlined />
                        </button>
                        <Logo className='logo' src={RAILogo} onClick={() => {history.push('/')}}/>
                        <ul ref={ref as any} className={`sts-menu ${!isSmallScreen || isNavVisible ? 'show' : 'hide'}`}>
                            <li>
                                <Link className={pathname.includes('/sts') ? 'active': ''} to="/sts">
                                Social Trading
                                </Link>
                            </li>
                            <li>
                                <Dropdown trigger={['click']} overlay={
                                    <Menu className="more-menu">
                                        <Menu.Item>
                                            <a target="_self" rel="noopener noreferrer" href="https://soswap.rai.finance/#/swap">
                                                <span>{t('Trade')}</span>
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <a target="_self" rel="noopener noreferrer" href="https://soswap.rai.finance/#/pool">
                                                <span>{t('Pools')}</span>
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <a target="_self" rel="noopener noreferrer" href="https://soswap.rai.finance/#/farm">
                                                <span>{t('Farm')}</span>
                                            </a>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <a target="_self" rel="noopener noreferrer" href="https://soswap.rai.finance/#/analytics">
                                                <span>{t('Analytics')}</span>
                                            </a>
                                        </Menu.Item>
                                    </Menu>
                                }>
                                    <a onClick={e => e.preventDefault()}>
                                    {t('Soswap')} <ChevronDown size={20} />
                                    </a>
                                </Dropdown>
                            </li>
                            <li>
                                <Dropdown trigger={['click']} overlay={<Menu className="more-menu">
                                    <Menu.Item>
                                        <a target="_self" rel="noopener noreferrer" href="https://soswappro.rai.finance/#/swap">
                                            <span>{t('Lite Swap')}</span>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a target="_self" rel="noopener noreferrer" href="https://soswappro.rai.finance/#/limit">
                                            <span>{t('Limit Order')}</span>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a target="_self" rel="noopener noreferrer" href="https://soswappro.rai.finance/#/pro">
                                            <span>{t('Pro Trading')}</span>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a target="_self" rel="noopener noreferrer" href="https://soswappro.rai.finance/#/pools">
                                            <span>{t('Pools')}</span>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a target="_self" rel="noopener noreferrer" href="https://analytics.rai.finance/#/Dashboard">
                                            <span>{t('Analytics')}</span>
                                        </a>
                                    </Menu.Item>
                                </Menu>}>
                                    <a href="javascript:void(0)">
                                    {t('Soswap Pro')} <ChevronDown size={20}/><Tag color="green">Beta</Tag>
                                    </a>
                                </Dropdown>    
                            </li>
                            <li className='coming'>
                                <Dropdown trigger={['click']} overlay={<Menu className="more-menu">
                                    <Menu.Item>
                                        <a target="_self" rel="noopener noreferrer" href="javascript:void(0)">
                                            <span>{t('Dashboard')}</span>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a target="_self" rel="noopener noreferrer" href="javascript:void(0)">
                                            <span>{t('Lend')}</span>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a target="_self" rel="noopener noreferrer" href="javascript:void(0)">
                                            <span>{t('Farm')}</span>
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a target="_self" rel="noopener noreferrer" href="javascript:void(0)">
                                            <span>{t('Auction')}</span>
                                        </a>
                                    </Menu.Item>
                                </Menu>}>
                                <a onClick={e => e.preventDefault()}>
                                    {t('NFT')} <ChevronDown size={20}/>
                                    <Tag color="blue">coming soon</Tag>
                                </a>
                                </Dropdown>    
                            </li>
                            <li>
                                <Link className={pathname.includes('/proposals') ? 'active': ''} to="/proposals">
                                    {t('header_governance')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <HeaderElement>
                        <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                            <Web3Status />
                        </AccountElement>
                        <div className="triger">{collapsed ? <SettingOutlined style={{ fontSize: '20px'}} onClick={toggle}/> : <SettingOutlined style={{ fontSize: '20px'}} onClick={toggle}/>}</div>
                    </HeaderElement>
                </HeaderContent>
                <Drawer
                    placement="top"
                    closable={false}
                    onClose={onClose}
                    visible={isSearchDrawerVisible}
                    getContainer={false}
                    className="search-drawer"
                >
                    <Search placeholder="Search by portfolio, token" onSearch={onSearch} />
                </Drawer>
            </Header>
            {pathname === '/headTail' && 
                <div className='banner'>
                    <Carousel autoplay>
                        <div>
                            <div className='bg' style={{background: `url(${Bg3}) no-repeat`}}>
                            </div>
                        </div>
                       <div>
                            <div className='bg' style={{background: `url(${Bg2}) no-repeat`}}>
                                <div>
                                    <h1>Head & Tail</h1>
                                    <p>Guessing the Up and Down<br/> of a specific pair over a specific time period</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='bg' style={{background: `url(${Bg1}) no-repeat`}}>
                                <div>
                                    {/* <b>Guess</b> which team<br/>will win and<br/>Get more <b>Rewards</b> */}
                                    <img className='text' src={BannerText} alt=""/>
                                    <div className='ended'>{t('ended')}</div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            }
            <Layout>
                <Content style={{ maxWidth: '1440px' }}>
                    {children}
                </Content>
            </Layout>
            <Drawer
                title={t('settings')}
                placement="right"
                width={300}
                closable
                onClose={onCloseCollapse}
                visible={collapsed}
                bodyStyle={{padding: '20px'}}
                className="settings-drawer"
            >
                <h2>{t('network')}</h2>
                <p>
                    {t('select_the_network_for_the_service')}
                </p>
                <SwitchNetwork>
                    <Radio.Group
                        onChange={(e) => {
                            switchNetwork(e.target.value)
                        }}
                        value={chainId}
                    >
                        <Space direction="vertical">
                            <Radio value={ChainId.MAINNET}><ChainLogo chainId={ChainId.MAINNET}/>{t('Ethereum')}</Radio>
                            {!pathname.includes("/proposals") && <Radio value={ChainId.POLYGON} ><ChainLogo chainId={ChainId.POLYGON}/>{t('Polygon')}</Radio>}
                            <Radio value={ChainId.BASE}><ChainLogo chainId={ChainId.BASE}/>Base Mainnet</Radio>
                            <Radio value={ChainId.TAIKO}><ChainLogo chainId={ChainId.TAIKO}/>Taiko</Radio>
                            <Radio value={ChainId.BLAST_SEPOLIA}><ChainLogo chainId={ChainId.BLAST_SEPOLIA}/>Blast Sepolia</Radio>
                        </Space>
                    </Radio.Group>
                </SwitchNetwork>
                <Divider />
                <h2>{t('languages')}</h2>
                <p>
                   {t('choose_language')}
                </p>
                <Radio.Group
                    onChange={changeLocale}
                    value={localStorage.getItem("rai_lang") || "en"}
                >
                    <Space direction="vertical">
                        <Radio value='en'>English</Radio>
                        <Radio value='ko'>한국어</Radio>
                    </Space>
                </Radio.Group>
                <Divider />
                <h2>{t('theme')}</h2>
                <p>
                    {t('choose_theme')}
                </p>
                <Radio.Group
                    onChange={changeThemeId}
                    value={themeId}
                >
                    <Space direction="vertical">
                        {options.map((v: any) => <Radio key={v.value} value={v.value}>{t(v.value)}</Radio>)}
                    </Space>
                </Radio.Group>
            </Drawer>
            <FaucetModal
              isOpen={isShowFaucetModal}
              handleCancel={() => {setIsShowFaucetModal(false)}}
            />
            <ReedemModal 
                isOpen={isShowReedemModal}
                handleCancel={() => {setIsShowReedemModal(false)}}
            />
            <DynamicTheme themes={themes} value={localStorage.getItem("rai_theme") || themes[0].id} />
            {/* {pathname.includes('/predict') ? 
            <Footer style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => {setShowTermOfUseModal(true)}}>Term of Use</Footer>
            :
            <Footer style={{ textAlign: 'center' }}>Copyrights © {new Date().getFullYear()} RAI.Finance All rights reserved.</Footer>
            } */}
        <Footer
            columns={[
                {
                    title: '',
                    icon:(
                        <Logo className='footer-logo' src={RAILogo} onClick={() => {history.push('/')}}/>
                    )
                },
                {
                    title: 'Soswap',
                    items: [
                        {
                            title: 'Trade',
                            url: 'https://soswap.rai.finance/#/swap',
                            openExternal: false,
                        },
                        {
                            title: 'Pools',
                            url: 'https://soswap.rai.finance/#/pool',
                            openExternal: false,
                        },
                        {
                            title: 'Farm',
                            url: 'https://soswap.rai.finance/#/farm',
                            openExternal: false,
                        },
                        {
                            title: 'Analytics',
                            url: 'https://soswap.rai.finance/#/analytics',
                            openExternal: false,
                        },
                    ],
                },
                {
                    title: <>Soswap Pro&nbsp;<Tag color="green">Beta</Tag></>,
                    items: [
                        {
                            title: 'Lite Swap',
                            url: 'https://soswappro.rai.finance/#/swap',
                            openExternal: false,
                        },
                        {
                            title: 'Limit Order',
                            url: 'https://soswappro.rai.finance/#/limit',
                            openExternal: false,
                        },
                        {
                            title: 'Pro Trading',
                            url: 'https://soswappro.rai.finance/#/pro',
                            openExternal: false,
                        },
                        {
                            title: 'Pools',
                            url: 'https://soswappro.rai.finance/#/pools',
                            openExternal: false,
                        },
                        {
                            title: 'Analytics',
                            url: 'https://analytics.rai.finance/#/Dashboard',
                            openExternal: false,
                        },
                    ],
                },
                {
                    title: <>NFT&nbsp;<Tag color="blue">coming soon</Tag></>,
                    items: [
                        {
                            title: 'Dashboard',
                            url: 'javascript:void(0)',
                            openExternal: false,
                        },
                        {
                            title: 'Lend',
                            url: 'javascript:void(0)',
                            openExternal: false,
                        },
                        {
                            title: 'Farm',
                            url: 'javascript:void(0)',
                            openExternal: false,
                        },
                        {
                            title: 'Auction',
                            url: 'javascript:void(0)',
                            openExternal: false,
                        },
                    ],
                },
                {
                    title: 'More Products',
                    items: [
                        {
                            title: 'Social Trading',
                            url: '#/sts',
                            openExternal: false,
                        },
                        {
                            title: 'Analytics',
                            url: 'javascript:void(0)',
                            openExternal: false,
                        },
                    ],
                },
                {
                    title: 'More',
                    items: [
                        {
                            title: t('header_governance'),
                            url: '#/proposals',
                            openExternal: false,
                        },
                        {
                            title: 'Whitepaper',
                            url: 'https://rai.finance/RAI-Finance-WhitePaper.pdf',
                            openExternal: false,
                        },
                        {
                            title: 'Wiki',
                            url: 'https://rai-finance.gitbook.io/rai-finance/',
                            openExternal: true,
                        }
                    ],
                },
            ]}
            bottom={
                <div className='footer_bottom'>
                    <div>{`Copyrights © ${new Date().getFullYear()} RAI.Finance All rights reserved.`}</div>
                    <div>  
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/RaiFinance">
                            <TwitterLogo/> 
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://medium.com/rai-finance">
                            <MediumLogo/>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/RaiFinance">
                            <TelegramLogo/>
                        </a>
                    </div>
                </div>}
        />
            <TermOfUseModal isOpen={showTermOfUseModal} handleCancel={() => {setShowTermOfUseModal(false)}}/>
        </Layout> 
    )
}

export default STS;
