import React, { useCallback, useState } from 'react'
import { RouteComponentProps, Link, useHistory } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Card, Tabs, Input, Table, PageHeader, Tooltip, Row, Col, Avatar, Tag } from 'antd';
import { usePortfolioDetail, useAllChartData, useTokens, useAllPortfolios, useManagerInfo } from './hooks';
import { ExclamationCircleOutlined, CalendarOutlined, TwitterOutlined, GithubOutlined, QuestionCircleOutlined, RedoOutlined, LoadingOutlined } from '@ant-design/icons';
import ManagerChart from './ManagerChart';
import { useActiveWeb3React } from '../../hooks'
import { PriceChartRangeOption } from 'constants/priceChartEnums'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import Copy from 'components/AccountDetails/Copy'
import { useTransactionAdder, useIsTransactionPending } from '../../state/transactions/hooks'
import { useSetTokenContract, useBasicIssuanceModuleContract, useTradeModuleContract } from '../../hooks/useContract'
import { portfolioFactory, portfolioModule, trading, managerModule } from '../../constants'
import {ReactComponent as Email} from 'assets/svg/email.svg'

import './index.less';
import STS from './index'
import { relative } from 'path';
import { useEffect } from 'react';

const { Meta } = Card;
const { TabPane } = Tabs;
const { Search } = Input;

const Container = styled.div`
    padding: 50px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 20px;
    `};
`
const Title = styled.h1`
    font-size:32px;
    color: #5542F6;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        margin-bottom: 30px;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-bottom: 20px;
    `};
    span{
        font-size: 14px;
        color: #000;
    }
`

const SubTitle = styled.h2`
    font-size:14px;
    margin-top: 18px;
    margin-bottom: 12px;
`

export default function Manager(props: RouteComponentProps<{ address: string }>) {
  const {
    match: {
      params: { address }
    }
  } = props
  const { t } = useTranslation();
  const { account, chainId, library } = useActiveWeb3React()
  const { tokens } = useTokens();
  const [chartRange, setChartRange] = useState<string>(PriceChartRangeOption.DAILY_PRICE_RANGE)
  const { portfolio } = usePortfolioDetail(address);
  const [refresh, setRefresh] = useState<number>(1);
  const [refreshTime, setRefreshTime] = useState<any>(new Date());
  // const { chartData } = useManagerChartData(address, chartRange);
  const setTokenContract = useSetTokenContract(address)
  const [isTrade, setIsTrade] = useState<boolean>(false)
  const [isBuySell, setIsBuySell] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])
  const basicIssuanceModuleContract = useBasicIssuanceModuleContract(portfolioModule[chainId ? chainId: 4])
  const tradeModuleContract = useTradeModuleContract(trading[chainId ? chainId: 4])
  const addTransaction = useTransactionAdder()
  const history = useHistory();
  const allPortfolios = useAllPortfolios(address);
  const { managerInfo } = useManagerInfo(address);
  const allPortfoliosAddress = allPortfolios.map((v: any) => v.address);
  const { allChartData } = useAllChartData(allPortfoliosAddress, chartRange, refresh);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    getIsTrade();
    getIsBuySell();
  }, [address])

  useEffect(() => {
    setSelectedRowKeys(allPortfoliosAddress.slice(0,5));
  }, [allPortfoliosAddress.length])

  const getIsBuySell = async () => {
    if(setTokenContract){
      const _isBuySell = await setTokenContract.isInitializedModule(portfolioModule[chainId ? chainId: 4])
      setIsBuySell(_isBuySell)
    }
  }
 
  const getIsTrade = async () => {
    if(setTokenContract){
      const status = await setTokenContract.moduleStates(managerModule[chainId ? chainId: 4])
      const _isTrade = status === 2;
      setIsTrade(_isTrade)
    }
  }
 
  const columns: ColumnsType<any> = [
    {
      title: t("portfolios"),
      dataIndex: 'symbol',
      fixed: 'left',
      width: '100px'
    },
    {
      title: t("price"),
      dataIndex: 'value_per_set',
      width: '100px',
      render: (value_per_set: number) => `$${numeral(value_per_set.toFixed(2)).format('0,0.00')}`,
      sorter: {
        compare: (a: any, b: any) => a.value_per_set - b.value_per_set,
      },
    },
    {
      title: <>24h%&nbsp;<Tooltip title={t('24h_tooltip')}><ExclamationCircleOutlined /></Tooltip></>,
      dataIndex: 'price_changed_24hr',
      width: '100px',
      render: (v: any) => v ? <span className={v > 0 ? 'up': 'down'}>{`${v > 0 ? '+' : ''}${numeral(v).format('0,0.00')}%`}</span> : '-',
      sorter: {
        compare: (a: any, b: any) => a.price_changed_24hr - b.price_changed_24hr,
      },
    },
    {
      title: <>7d%&nbsp;<Tooltip title={t('7d_tooltip')}><ExclamationCircleOutlined /></Tooltip></>,
      dataIndex: 'price_changed_7day',
      width: '100px',
      render: (v: any) => v ? <span className={v > 0 ? 'up': 'down'}>{`${v > 0 ? '+' : ''}${numeral(v).format('0,0.00')}%`}</span> : '-',
      sorter: {
        compare: (a: any, b: any) => a.price_changed_7day - b.price_changed_7day,
      },
    },
    {
      title: t("holder"),
      dataIndex: 'holders_count',
      width: '80px',
      render: (v: any) => v ? numeral(v).format('0,0') : '-',
      sorter: {
        compare: (a: any, b: any) => a.holders_count - b.holders_count,
      },
    },
    {
      title: t('market_cap'),
      dataIndex: 'total_value',
      width: '120px',
      render: (toatl_value: number) => `$${numeral(toatl_value).format('0,0.00')}`,
      sorter: {
        compare: (a: any, b: any) => a.total_value - b.total_value,
      },
    },
  ];

  const onSelectChange = (selectedRowKeys: any) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    setSelectedRowKeys(allPortfoliosAddress.slice(5*(page - 1), 5*page));
  }, [page])

  const handleTableChange = (pagination: any) => {
    console.log('pagination:', pagination);
    setPage(pagination.current)
  }

  const refreshChart = () => {
    setRefresh(refresh + 1);
    setLoading(true)
    setRefreshTime(new Date())
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  return (
    <STS>
      <Container>
          <PageHeader
            title={<>{managerInfo?.name}</>}
            className="portfolio-page-header"
          >
                <Row gutter={16}>
                    <Col sm={16} xs={24}>
                        <Card 
                            className="chartCard" 
                            title={<span>{t('portfolio_manager_performance')}</span>} 
                            extra={<></>}
                        >
                            <ManagerChart data={allChartData} address={allPortfoliosAddress} allPortfolios={allPortfolios} selectedRowKeys={selectedRowKeys} chartRange={chartRange} setChartRange={setChartRange}/>
                            <div className="card_footer">{t('updated_on')} {moment(refreshTime).format('YYYY-MM-DD HH:mm:ss')}&nbsp;&nbsp;<button onClick={refreshChart}>{loading ? <LoadingOutlined /> : <RedoOutlined />}&nbsp;Refresh</button></div>
                        </Card>
                        <SubTitle>{t("all_portfolios")}</SubTitle>
                        <Table 
                            className="marketTable"
                            columns={columns}
                            dataSource={allPortfolios} 
                            pagination={{ pageSize: 5, position: ['bottomCenter']}} 
                            onChange={handleTableChange} 
                            showSorterTooltip={false}
                            rowKey="address"
                            scroll={{ x: 500 }}
                            rowSelection={rowSelection}
                            onRow={(record: any) => {
                                return {
                                onClick: event => {history.push(`/sts/portfolio/${record.address}`)}, // 点击行
                                };
                            }}
                        />
                        {/* <SubTitle>About</SubTitle>
                        <p>
                        The DeFi Pulse Index is a capitalization-weighted index that tracks the performance of decentralized financial assets across the market.
                        </p>
                        <SubTitle>Objective</SubTitle>
                        <p>
                        The DeFi Pulse Index is a digital asset index designed to track tokens’ performance within the Decentralized Finance industry. The index is weighted based on the value of each token’s circulating supply. The DeFi Pulse Index aims to track projects in Decentralized Finance that have significant usage and show a commitment to ongoing maintenance and development.
                        </p> */}
                    </Col>
                  <Col sm={8} xs={24}>
                      <Card className="managerInfo">
                        <div className="avatar">
                          <Avatar size={40} icon={!managerInfo?.photo_url && <UserOutlined />} src={managerInfo?.photo_url} />
                        </div>
                        <div className="links">
                          <div className="address">
                            <div>
                              {managerInfo.address}
                            </div>
                            <div>
                              <Copy toCopy={managerInfo.address}></Copy>
                            </div>
                          </div>
                          <div>
                            <Tag className="date" icon={<CalendarOutlined style={{color: '#5542F6'}}/>} color="#EEECFE">{managerInfo.created_at ? -moment(managerInfo.created_at * 1000).diff(moment(), 'days') : 0} days</Tag>
                            {managerInfo.twitter_url && <a href={managerInfo.twitter_url} target="_blank"><TwitterOutlined style={{color: '#00ACED'}}/></a>}
                            {managerInfo.github && <a href={managerInfo.github} target="_blank"><GithubOutlined style={{color: '#D7D7D7'}}/></a>}
                            {managerInfo.email && <a href={`mailto:${managerInfo.email}`}><Email className="anticon" style={{fill: '#D7D7D7'}}/></a>}
                          </div>
                        </div>
                      </Card>
                      <Card className="infoCard2" style={{marginTop: '30px'}}>
                          <Card.Grid style={{width: '50%', border: 'none', boxShadow:'none'}}>
                            <Tooltip title="Current total market cap"><p>{t('total_market_cap')}&nbsp;<QuestionCircleOutlined /></p></Tooltip>
                            <b>${numeral(managerInfo?.total_market_cap).format('0,0.00')  || '-'}</b>
                            <Tooltip title="Calculation by current market cap and current profit"><p>{t("cumulative_APY")}&nbsp;<QuestionCircleOutlined /></p></Tooltip>
                            <b>{`${managerInfo?.all_apy}%` || '-'}</b>
                            <p>{t('inception_date')}</p>
                            <b>{`${moment(managerInfo?.created_at*1000).format('YYYY-MM-DD')}` || '-'}</b>
                          </Card.Grid>
                          <Card.Grid style={{width: '50%', border: 'none', boxShadow:'none'}}>
                              <p>{t('total_holders')}</p>
                              <b>{numeral(managerInfo?.total_holders).format('0,0') || '-'}</b>
                              <p>{t('number_of_portfolio')}</p>
                              <b>{`${allPortfolios.length}` || '-'}</b>
                          </Card.Grid>
                      </Card>
                  </Col>
              </Row>    
          </PageHeader>
      </Container>
    </STS>
  )
}
