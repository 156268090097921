export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  
  GÖRLI = 5,
  KOVAN = 42,

  RINKEBY = 4,
  POLYGON = 137,
  BASE = 8453,
  BASE_GOERLI = 84531,
  PZT = 1442,
  PZ = 1101,
  ZKSYNC = 324,
  ZKSYNC_TEST = 280,
  TAIKO = 167000,
  BLAST_SEPOLIA = 168587773,
}