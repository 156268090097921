import { useCallback, useEffect, useState,useMemo } from 'react'
import { ChainId } from 'constants/chainId';
import { useActiveWeb3React } from '../../hooks'
import { APILIST } from '../../constants'
import { get, request } from '../../utils/request';
import { getSign } from 'utils/txSign';
import { useBytes32TokenContract, usePortfolioContract, useTokenContract } from '../../hooks/useContract'

export function useSocialList(topic: string, refresh?: number): any {
    const [list, setList] = useState<any>([]);
    const { account, chainId } = useActiveWeb3React()
    const api_url = APILIST[1];
    const fetchData = useCallback(async () => {
      let params: any = {
        address: account,
        topic,
      };
      if(!account) {delete params.account}
      if(!topic) {delete params.topic}
      let query = Object.keys(params)
      .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
      get(`${api_url}/sofi/posts?${query}`).then(async (res: any) => {
          if (res) {
              setList(res)
          }
      });
    }, [topic, account])
  
    useEffect(() => {
      fetchData()
    }, [account, chainId, refresh, topic])
    return list
}

export function useTrendingList(): any {
    const [list, setList] = useState<any>([]);
    const { chainId } = useActiveWeb3React()
    const api_url = APILIST[1];
    const fetchData = useCallback(async () => {
        get(`${api_url}/sofi/trending`).then(async (res: any) => {
            if (res) {
                setList(res)
            }
        });
    }, [])
  
    useEffect(() => {
        if(chainId){
            fetchData()
        }
    }, [chainId])
    return list
}

export const postPredict = async (address: string, id: string, direction: string, chainId: ChainId, library: any) => {
    let sig, timestamp
    const sigResult = await getSign(library, address)
    sig = sigResult.sig
    timestamp = sigResult.timestamp
    const api_url = APILIST[1];
    const params = {
        address,
        post: id,
        direction
    }
    try {
      const url = `${api_url}/sofi/predict`
      const res = await request(url, {
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          'mode': 'no-cors',
          'address': address,
          'signature': sig,
          'signatureMessage': timestamp
        },
        method: 'POST'
      });
      return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
  }
  
export const postSocial = async (address: string, id: string, action: string, chainId: ChainId, library: any) => {
    let sig, timestamp
    const sigResult = await getSign(library, address)
    sig = sigResult.sig
    timestamp = sigResult.timestamp
    const api_url = APILIST[1];
    const params = {
        address,
        id,
        action
    }
    try {
      const url = `${api_url}/sofi/post`
      const res = await request(url, {
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          'mode': 'no-cors',
          'address': address,
          'signature': sig,
          'signatureMessage': timestamp
        },
        method: 'POST'
      });
      return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
  }