import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal'
import { AutoColumn, ColumnCenter } from '../../../components/Column'
import { RowBetween, RowFixed } from '../../../components/Row'
import styled, { ThemeContext } from 'styled-components'
import { Text } from 'rebass'
import { CloseIcon, CustomLightSpinner } from '../../../theme/components'
import { ArrowDown, AlertTriangle } from 'react-feather'
import CurrencyLogo from '../../../components/CurrencyLogo'
import { TruncatedText, SwapShowAcceptChanges } from '../../../components/swap/styleds'
import { ButtonLight, ButtonPrimary } from '../../../components/Button'
import { Token } from '@uniswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { formatUnits } from '@ethersproject/units'
import moment, { Moment } from 'moment';
interface AggregateModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  handleSubmit: () => void;
  title: string;
  startTime: string;
  endTime: Moment | null | undefined;
  stakeAccount: BigNumber;
}

export default function AggregateModal({
    isOpen,
    onDismiss,
    handleSubmit,
    title,
    startTime,
    endTime,
    stakeAccount
  }: AggregateModalProps) {
    // confirmation screen
    const theme = useContext(ThemeContext)
    const { t } = useTranslation();
    return (
      <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
        <Wrapper>
        <Section>
          <RowBetween justify="space-between" >
            <Text fontWeight={500} fontSize={20}>
              {t('confirm_proposal')}
            </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
            <RowBetween align="flex-end">
              <RowFixed gap={'0px'}>
                {t('your_roposal')}
              </RowFixed>
            </RowBetween>
            <Title>{title}</Title>
            {/* <RowBetween align="flex-end">
              <RowFixed gap={'0px'}>
                Start time
              </RowFixed>
              <RowFixed gap={'0px'}>
                <Bold>
                  {moment().add(3, 'days').format("YYYY-MM-DD HH:mm")}
                </Bold>
              </RowFixed>
            </RowBetween> */}
            <RowBetween align="flex-end">
              <RowFixed gap={'0px'}>
                {t('end_time')}
              </RowFixed>
              <RowFixed gap={'0px'}>
                <Bold>{endTime?.format("YYYY-MM-DD HH:mm")}</Bold>
              </RowFixed>
            </RowBetween>
            <RowBetween align="flex-end">
              <RowFixed gap={'0px'}>
                {t('you_will_stake')}
              </RowFixed>
              <RowFixed gap={'0px'}>
                <Bold>{formatUnits(stakeAccount, 18)} SOFI </Bold>
              </RowFixed>
            </RowBetween>
          </AutoColumn>
          <Description>
            {t('vote_choice_note')}
          </Description>
        </Section>
        <BottomSection gap="12px">
          <ButtonLight onClick={onDismiss} >{t('cancel')}</ButtonLight>
          <ButtonPrimary onClick={handleSubmit} >{t('confirm_proposal')}</ButtonPrimary>
        </BottomSection>
        </Wrapper>
      </Modal>
    )
  }

const Section = styled(AutoColumn)`
  padding: 24px;
`

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const Wrapper = styled.div`
  width: 100%;
`

const Description = styled.div`
  background: #F4F4F4;
  border-radius: 6px;
  padding: 18px;
  margin-top: 11px;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const Bold = styled.span`
  font-weight: bold;
`