import React, { useCallback, useEffect, useState } from 'react'
import { List, Card, Button, Tabs, Input, Avatar, message, Tooltip, Form } from 'antd';
import { Link, useHistory } from 'react-router-dom'
import numeral from 'numeral';
import moment from 'moment';
import useThrottle from "@rooks/use-throttle"
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { SearchOutlined, MoreOutlined, QuestionCircleOutlined, UserOutlined, PlusOutlined, LeftOutlined } from '@ant-design/icons';
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import Copy from 'components/AccountDetails/Copy'
import { useTokens, useHotPortfolios, useAllPortfolios, useManagersList, useChartData, postFollowManager, postFavoritePortfolio, usePortfolios, useSearchPortfolios } from './hooks';
import PortfolioChart from './PortfolioChart';
import { PriceChartRangeOption } from 'constants/priceChartEnums'
import { shortenAddress, toPlainString } from '../../utils'
import { useActiveWeb3React } from '../../hooks'
import {ReactComponent as TopRiskIcon} from 'assets/svg/sts/top-risk.svg'
import {ReactComponent as MostViewedIcon} from 'assets/svg/sts/most-viewed-risk.svg'
import {ReactComponent as MostTradedIcon} from 'assets/svg/sts/most-traded-risk.svg'
import {ReactComponent as HighiskIcon} from 'assets/svg/sts/high-risk.svg'
import {ReactComponent as CollectIcon} from 'assets/svg/sts/collect.svg'
import {ReactComponent as UncollectIcon} from 'assets/svg/sts/uncollect.svg'

import './index.less';
import './Market.less';
import STS from './index'

const { Meta } = Card;
const { TabPane } = Tabs;
const { Search } = Input;


export const Container = styled.div`
    padding: 48px 0;
    padding-top: 32px;
    ${({ theme }) => theme.mediaWidth.upToLarge`
        padding: 30px 0;
    `};
    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 30px 0;
    `};
    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 20px 0;
    `};
    overflow: auto;
`

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const InsideContent = styled.div`
  max-width: 1056px;
`

export const InfoBox = styled.div`
  width: 516px;
  height: 458px;
  background: #FFFFFF;
  box-shadow: 0px 8px 48px -1px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  position: relative;
  &:hover {
    box-shadow: 0px 8px 48px -1px rgba(0, 0, 0, 0.15);
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`

const SubTitle = styled.h2`
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 28px;
    color: #18181B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>div{
      color: #71717A;
      font-weight: 500;
      font-size: 14px;
    }
`

const PortfolioAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`

export const NoContentStyled = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 12px;
    }
    .btn {
      width: 228px;
      height: 48px;
      background: #F4F4F5;
      border-radius: 8px;
      text-align: center;
      line-height: 48px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: #EDECFE;
        color: #5542F6;
      }
    }
`

export const ContentBtn = styled.div<{ width?: number | string }>`
  width: 228px;
  height: 48px;
  background: #F4F4F5;
  border-radius: 8px;
  text-align: center;
  line-height: 48px;
  font-weight: 500;
  font-size: 16px;
  font-family: Graphik;
  @media (max-width: 576px) {
    width: auto;
    font-size: 14px;
  }
  &:hover,&:active {
    background-color: #EDECFE;
    color: #5542F6;
  }
`
export const SocialTradingDetailStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const GovernanceDetailStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
.item{
  border: 1px solid var(--border-color);
  background: var(--background); 
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .head {
      height: 16px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .chain{
          border-bottom-left-radius: 12px;
          color: #4274F6;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
      }
      .id{
          border-bottom-right-radius: 12px;
          color: #71717A;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
      }
  }

  .category {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
  }


  h2{
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: var(--text-primary);
      height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
  }
  p{
      font-size: 14px;
      color: #000;
      height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
  }
  .created, .ended{
      color: var(--text-secondary);
      font-size: 12px;
      margin-bottom: 0;
  }
  .footer{
      display: flex;
      justify-content: space-between;
      line-height: 20px;
      .tag{
          padding: 0px 4px;
          margin-right: 10px;
          font-weight: 600;
          font-size: 12px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 4px;
          color: #fff;
          &.active{
              background: #14B8A6; 
          }
          &.soon{
              background: #5542F6;
          }
          &.closed,&.canceled{
              background: #A1A1AA;
          }
      }
  }
}
`

export const ItemBoxLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    font-weight: 700;
    font-size: 16px;
    color: var(--text-primary);
    align-items: center;
    &>div{
        max-width: 118px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    span{
        font-weight: 400;
        font-size: 12px;
        color: #71717A;
    }
    .ant-avatar{
        margin-right: 10px;
    }
    img{
      width: 40px;
      height: 40px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    .title {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      div {
        &:first-of-type{
          width: 54px;
          height: 16px;
          background: #F4F4F5;
          border-radius: 4px;
          margin-right: 16px;
          padding: 2px 4px 2px 4px;
          line-height: 16px;
        }
        &:last-of-type {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #18181B;
        }
      }
      
    }
    b{
      &.up{
          color: #14B8A6;
      }
      &.down{
          color: #F64242;
      }
  }
  }
`

export const PredictModelLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  .title {
    text-align: left;
    line-height: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }
  .issue {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const PredictIssue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .result {
    display: flex;
    .yes {
      width: 31px;
      height: 20px;
      background: #14B8A6;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height:20px;
      color: #FFFFFF;
      text-align: center;
      margin-right: 14px;
    }
    .count {
      width: 58px;
      height: 20px;
      background: #F4F4F5;
      border-radius: 4px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      color: #18181B;
    }
  }
  .date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #71717A;
  }
`

export const GoverIssue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .result {
    display: flex;
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    .tag{
        padding: 0px 4px;
        margin-right: 10px;
        font-weight: 600;
        font-size: 12px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border-radius: 4px;
        color: #fff;
        &.active{
            background: #14B8A6; 
        }
        &.soon{
            background: #5542F6;
        }
        &.closed,&.canceled{
            background: #A1A1AA;
        }
    }
    .yes {
      width: 45px;
      height: 20px;
      // background: #14B8A6;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height:20px;
      color: #FFFFFF;
      text-align: center;
      margin-right: 14px;
    }
    .pass {
      background: #14B8A6;
    }
    .active {
      background: #5542F6;
    }
    .count {
      min-width: 58px;
      height: 20px;
      background: #F4F4F5;
      border-radius: 4px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      color: #18181B;
      padding: 0px 10px;
    }
  }
  .date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #71717A;
  }
`

export const Chart = ({address} : any) => {
  const [chartRange, setChartRange] = useState<string>(PriceChartRangeOption.WEEKLY_PRICE_RANGE)
  const { chartData } = useChartData(address, chartRange);
  return <PortfolioChart data={chartData} chartRange={chartRange} setChartRange={setChartRange}/>
}

export const ChartDay = ({ address, type }: any) => {
  const [chartRange, setChartRange] = useState<string>(PriceChartRangeOption.DAILY_PRICE_RANGE)
  const { chartData } = useChartData(address, chartRange);
  return <PortfolioChart data={chartData} chartRange={chartRange} setChartRange={setChartRange}/>
}

export const ChartType = ({ address, type }: any) => {
  const [chartRange, setChartRange] = useState<string>(type)
  const { chartData } = useChartData(address, chartRange);
  return <PortfolioChart data={chartData} chartRange={chartRange} setChartRange={setChartRange}/>
}

export default function Markets() {
  const { t } = useTranslation();
  const { tokens } = useTokens();
  const history = useHistory();
  const [refreshPortfolio, setRefreshPortfolio] = useState<number>(1);
  const [refreshManager, setRefreshManager] = useState<number>(1);
  const favoritesPortfolios = usePortfolios('favorited', 4, refreshPortfolio);
  const bestPortfolios = usePortfolios('best', 4, refreshPortfolio);
  const newPortfolios = usePortfolios('new', 4, refreshPortfolio);
  // const allPortfolios = useAllPortfolios('', refreshPortfolio);
  const { list: managerList } = useManagersList(refreshManager);
  const [searchText, setSearchText] = useState('');  
  const [searchAI, setSearchAI] = useState('');  
  const [aiSearch, setAISearch] = useState(false)
  const searchedPortfolios = useSearchPortfolios(searchText)
  const [showMoreType, setShowMoreType] = useState('');  
  const [showMorePortfolios, setShowMorePortfolios] = useState([]);  
  const [activeKey, setActiveKey] = useState('portfolio');  
  const { chainId, account, library } = useActiveWeb3React()
  const [isSearchDrawerVisible, setIsSearchDrawerVisible] = useState(false);
  const [form] = Form.useForm()

  const onSearch = (value: any) => {
    onClose();
    if(value){
      setSearchText(value);
      // if(activeKey === 'portfolio'){
      //   const list = allPortfolios.filter((v: any) => JSON.stringify(v).toLowerCase().includes(value.toLowerCase()))
      //   setSearchPortfolios(list);
      // }
      // if(activeKey === 'manager'){
      //   const list = managerList.filter((v: any) => JSON.stringify(v).toLowerCase().includes(value.toLowerCase()))
      //   setSearchManagerList(list)
      // }
    }else {
      setSearchText('')
    }
  };

  const onSearchAI = (value: any) => {
    onClose();
    if(value){
      setSearchAI(value);
      handleSearchAI(value)
    }else {
      setSearchAI('')
    }
  };

  const handleSearchAI = (searchAI: string) => {
    if(!searchAI){
      return;
    }
    history.push(`/sts/create?search=${searchAI}`)
  }

  const onChange = (activeKey: string) => {
    setActiveKey(activeKey);
  };

  const favoritePortfolio = useCallback(async(e: any, portfolioAddress: string, action: boolean) => {
    e.stopPropagation();
    if(account && chainId){
      const res: any = await postFavoritePortfolio(portfolioAddress, account, action, chainId, library);
      console.log(res)
      if(res.address) {
        message.success(action ? t('successfully_favorited') : t('successfully_unfavorited') )
        setRefreshPortfolio(refreshPortfolio + 1)
      }
    }
  }, [account, library, refreshPortfolio])

  const [ handleFavoritePortfolio ] = useThrottle(favoritePortfolio, 2000)

  const showDrawer = () => {
    setIsSearchDrawerVisible(true);
  };

  const onClose = () => {
    setIsSearchDrawerVisible(false);
  };

  return (
    <STS isSearchDrawerVisible={isSearchDrawerVisible} onClose={onClose} onSearch={onSearch}>
      {!searchText && !aiSearch && !showMoreType &&<Container className='markets'>
        <div className='title'>
          <div>Social Trading</div>
          <div>
            <Button type="default" onClick={showDrawer}><SearchOutlined /></Button>
            <Tooltip title={t('sts_with_sts')}><Button type="default"><QuestionCircleOutlined /></Button></Tooltip>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => {history.push(`/sts/create`)}}>{t('create_portfolio')}</Button>
          </div>
        </div>
        <div className='rink'>
          <div onClick={() => {history.push('/sts/more/rated')}}>
            <TopRiskIcon/>
            {t('sts_top_rated')}
          </div>
          <div onClick={() => {history.push('/sts/more/viewed')}}>
            <MostViewedIcon/>
            {t('sts_most_viewed')}
          </div>
          <div onClick={() => {history.push('/sts/more/traded')}}>
            <MostTradedIcon/>
            {t('sts_most_traded')}
          </div>
          <div onClick={() => {history.push('/sts/more/risk')}}>
            <HighiskIcon/>
            {t('sts_high_risk')}
          </div>
        </div>
        <div className='ai-box'>
          <h2>Build your portfolio with AI</h2>
          <p>What are you interested in?</p>
          <Search className='search-input' defaultValue={searchAI} placeholder="" onSearch={onSearchAI} onFocus={() => {
            console.log('focus search')
            setAISearch(true)
          }} />
        </div>
        <div className='subTitle'>
          <div>{t('sts_favorites')}</div>
          <div onClick={() => {history.push('/sts/more/favorited')}}>{t('sts_see_more')}</div>
        </div>
        <List
          className="marketsList"
          dataSource={favoritesPortfolios.slice(0, 4)}
          renderItem={(item: any) => (
            <List.Item onClick={() => {history.push(`/sts/portfolio/${item.address}`)}}>
              <Card 
                title={<div className='tokenInfo'><img className="tokenAvator" src={item.avatar_uri}/><div>{item.symbol === 'WETH' ? 'ETH' : item.symbol}<br /><span>{item.name}</span></div></div>} 
                extra={<span>{item.favorite ? <CollectIcon onClick={(e) => {e.stopPropagation(); handleFavoritePortfolio(e, item.address, false)}}/> : <UncollectIcon onClick={(e) => {handleFavoritePortfolio(e, item.address, true)}}/>}<MoreOutlined /></span>}
              >
                <div className='price'><Tooltip title={`$${toPlainString(item?.value_per_set)}`}>{item?.value_per_set > 0.01 ? `$${numeral(toPlainString(item?.value_per_set)).format('0,0.00')}` : '<$0.01'}</Tooltip> <b className={`${item.apy >= 0 ? 'up':'down'}`}>{`${item.apy > 0 ? `+${item.apy}%`: `${item.apy}%`}`}</b></div>
              </Card>
            </List.Item>
          )}
        />
        <div className='subTitle'>
          <div>{t('sts_featured')}</div>
          <div onClick={() => {history.push('/sts/more/best')}}>{t('sts_see_more')}</div>
        </div>
        <List
          className="marketsList"
          dataSource={bestPortfolios.slice(0, 4)}
          renderItem={(item: any) => (
            <List.Item onClick={() => {history.push(`/sts/portfolio/${item.address}`)}}>
              <Card 
                title={<div className='tokenInfo'><img className="tokenAvator" src={item.avatar_uri}/><div>{item.symbol === 'WETH' ? 'ETH' : item.symbol}<br /><span>{item.name}</span></div></div>} 
                extra={<span>{item.favorite ? <CollectIcon onClick={(e) => {e.stopPropagation(); handleFavoritePortfolio(e, item.address, false)}}/> : <UncollectIcon onClick={(e) => {handleFavoritePortfolio(e, item.address, true)}}/>}<MoreOutlined /></span>}
              >
                <Chart address={item.address}/>
                <div className='price'><Tooltip title={`$${toPlainString(item?.value_per_set)}`}>{item?.value_per_set > 0.01 ? `$${numeral(toPlainString(item?.value_per_set)).format('0,0.00')}` : '<$0.01'}</Tooltip> <b className={`${item.apy >= 0 ? 'up':'down'}`}>{`${item.apy > 0 ? `+${item.apy}%`: `${item.apy}%`}`}</b></div>
              </Card>
            </List.Item>
          )}
        />
        <div className='subTitle'>
          <div>{t('sts_new')}</div>
          <div onClick={() => {history.push('/sts/more/new')}}>{t('sts_see_more')}</div>
        </div>
        <List
          className="marketsList"
          dataSource={newPortfolios.slice(0, 4)}
          renderItem={(item: any) => (
            <List.Item onClick={() => {history.push(`/sts/portfolio/${item.address}`)}}>
              <Card 
                title={<div className='tokenInfo'><img className="tokenAvator" src={item.avatar_uri}/><div>{item.symbol === 'WETH' ? 'ETH' : item.symbol}<br /><span>{item.name}</span></div></div>} 
                extra={<span>{item.favorite ? <CollectIcon onClick={(e) => {e.stopPropagation(); handleFavoritePortfolio(e, item.address, false)}}/> : <UncollectIcon onClick={(e) => {handleFavoritePortfolio(e, item.address, true)}}/>}<MoreOutlined /></span>}
              >
                <div className='price'><Tooltip title={`$${toPlainString(item?.value_per_set)}`}>{item?.value_per_set > 0.01 ? `$${numeral(toPlainString(item?.value_per_set)).format('0,0.00')}` : '<$0.01'}</Tooltip> <b className={`${item.apy >= 0 ? 'up':'down'}`}>{`${item.apy > 0 ? `+${item.apy}%`: `${item.apy}%`}`}</b></div>
                <div className='managerInfo'>
                  <div>{shortenAddress(item?.address)}</div>
                  <div>
                  {t('created_at')} {moment(item?.created_at * 1000).format('YYYY-MM-DD')}
                  </div>
                </div>
              </Card>
            </List.Item>
          )}
        />
        <div className='subTitle'>
          <div>{t('sts_managers')}</div>
          <div onClick={() => {history.push('/sts/more/managers')}}>{t('sts_see_more')}</div>
        </div>
        <List
          className="managerList"
          dataSource={managerList.slice(0, 5)}
          renderItem={(item: any) => (
            <List.Item onClick={() => {history.push(`/sts/profile/${item.address}`)}}>
              <Card 
                title={<div className='tokenInfo'>
                  {item?.photo_url ? <Avatar size={24} src={item?.photo_url} />: <Avatar size={24} icon={<UserOutlined style={{fontSize: '18px'}}/>}/>}
                  <div>{shortenAddress(item?.address)}</div>
                </div>} 
              >
              </Card>
            </List.Item>
          )}
        />
        <div className='subTitle'>{t('sts_token')}</div>
        <div className='tokens'>
          {tokens.map((token: any) => <div key={token.address} onClick={() => {onSearch(token.symbol)}}><Avatar size={20} src={getTokenLogoURL(token.symbol)} />{token.symbol}</div>)}
        </div>
      </Container>
      }
      {searchText && <Container className='markets'>
        <div className='back'>
          <div>
            <LeftOutlined onClick={() => {setSearchText('')}} style={{fontSize: '16px'}}/>
          </div>
          <div>
            <Search defaultValue={searchText} placeholder={t('search_portfolio_token')} onSearch={onSearch} />
          </div>
        </div>
        <div className='subTitle'>
          <div>{searchedPortfolios.length} {t('results_were_found')}</div>
          <div></div>
        </div>
        <List
          className="marketsList"
          dataSource={searchedPortfolios}
          renderItem={(item: any) => (
            <List.Item onClick={(e) => {;history.push(`/sts/portfolio/${item.address}`)}}>
              <Card 
                title={<div className='tokenInfo'><img className="tokenAvator" src={item.avatar_uri}/><div>{item.symbol === 'WETH' ? 'ETH' : item.symbol}<br /><span>{item.name}</span></div></div>} 
                extra={<span>{item.favorite ? <CollectIcon onClick={(e) => {e.stopPropagation(); handleFavoritePortfolio(e, item.address, true)}}/> : <UncollectIcon onClick={(e) => {handleFavoritePortfolio(e, item.address, true)}}/>}<MoreOutlined /></span>}
              >
                <div className='price'><Tooltip title={`$${toPlainString(item?.value_per_set)}`}>{item?.value_per_set > 0.01 ? `$${numeral(toPlainString(item?.value_per_set)).format('0,0.00')}` : '<$0.01'}</Tooltip> <b className={`${item.apy >= 0 ? 'up':'down'}`}>{`${item.apy > 0 ? `+${item.apy}%`: `${item.apy}%`}`}</b></div>
              </Card>
            </List.Item>
          )}
        />
      </Container>
      }

    {aiSearch && <Container className='markets'>
        <div>
          <LeftOutlined onClick={() => {setAISearch(false)}} style={{fontSize: '16px'}}/>
        </div>
        <div className='subTitle'>
          What's your interest?
        </div>
        <div>
        <Form
              name="basic"
              form={form}
          >
          <Form.Item
              rules={[  
                  { required: true, message: "" }
              ]}
              initialValue={searchAI}
              name="search"
            >
            <Search onSearch={onSearchAI} />
          </Form.Item>
        </Form>
          <div className='topics'>
            <span onClick={() => {form.setFieldsValue({search: 'AI'}); setSearchAI('AI')}}>#AI?</span>
            <span onClick={() => {form.setFieldsValue({search: 'meme'}); setSearchAI('meme')}}>#meme</span>
            <span onClick={() => {form.setFieldsValue({search: 'RWA'}); setSearchAI('RWA')}}>#RWA?</span>
            <span onClick={() => {form.setFieldsValue({search: 'Defi'}); setSearchAI('Defi')}}>#Defi</span>
            <span onClick={() => {form.setFieldsValue({search: 'Base Ecosystem'}); setSearchAI('Base Ecosystem')}}>#Base Ecosystem</span>
          </div>
        </div>
        <div className='enter-btn'>
          <Button type='primary' size='large' onClick={() => {handleSearchAI(searchAI)}}>Enter</Button>
        </div>
      </Container>
      }
      {showMoreType && <Container className='markets'>
        <div className='back'>
          <div>
            <LeftOutlined onClick={() => {setShowMoreType('')}} style={{fontSize: '16px'}}/>
          </div>
          <div>
            <h2>{showMoreType}</h2>
          </div>
        </div>
        <SubTitle>
          <div>{showMoreType === 'managers' ? managerList.length : showMorePortfolios.length} {t('results_were_found')}</div>
        </SubTitle>
        {showMoreType === 'managers' ? 
          <List
            className="managerList"
            dataSource={managerList}
            renderItem={(item: any) => (
              <List.Item onClick={() => {history.push(`/sts/profile/${item.address}`)}}>
                <Card 
                  title={<div className='tokenInfo'>
                    {item?.photo_url ? <Avatar size={24} src={item?.photo_url} />: <Avatar size={24} icon={<UserOutlined style={{fontSize: '18px'}}/>}/>}
                    <div>{shortenAddress(item?.address)}</div>
                  </div>} 
                >
                </Card>
              </List.Item>
            )}
          />
          :
          <List
            className="marketsList"
            dataSource={searchedPortfolios}
            renderItem={(item: any) => (
              <List.Item onClick={() => {history.push(`/sts/portfolio/${item.address}`)}}>
                <Card 
                  title={<div className='tokenInfo'><img className="tokenAvator" src={item.avatar_uri}/><div>{item.symbol === 'WETH' ? 'ETH' : item.symbol}<br /><span>{item.name}</span></div></div>} 
                  extra={<span>{item.favorite ? <CollectIcon onClick={(e) => {handleFavoritePortfolio(e, item.address, true)}}/> : <UncollectIcon onClick={(e) => {handleFavoritePortfolio(e, item.address, true)}}/>}<MoreOutlined /></span>}
                >
                  <div className='price'>${item?.value_per_set ? numeral(item?.value_per_set).format('0,0.00') : '-'} <b className={`${item.apy >= 0 ? 'up':'down'}`}>{`${item.apy > 0 ? `+${item.apy}%`: `${item.apy}%`}`}</b></div>
                </Card>
              </List.Item>
            )}
          />
        }
      </Container>
      }
    </STS>
  )
}
