import { CurrencyAmount, JSBI, Token, Trade, TokenAmount, Currency, ChainId } from '@uniswap/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, Link, useHistory } from 'react-router-dom'
import { Empty, Button, Card, Comment, Tag, Radio, Space, Checkbox, Statistic, notification } from 'antd';
import numeral from 'numeral';
import moment from 'moment';
import { useActiveWeb3React } from '../../hooks'
import { useDefaultPairList } from 'state/lists/hooks';
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
} from 'state/swap/hooks'
import { AggregateChartRangeOption } from 'constants/priceChartEnums'
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import STS from '../STS'
import { Chart } from './Chart';
import { Container } from '../STS/Markets'
import { shortenAddress, getEtherscanLink } from 'utils';
import { retry, RetryableError } from 'utils/retry'
import { useEventDetail, useChartData } from './hooks';
import { usePredictContract } from 'hooks/useContract';
import { SOFI_Predict_contract_address } from 'constants/index'
import { useTransactionAdder } from 'state/transactions/hooks'
import { imgages, formatTeam } from './constant';
import {ReactComponent as ChartDown} from 'assets/images/headTail/ChartDown.svg'
import {ReactComponent as ChartUp} from 'assets/images/headTail/ChartUp.svg'
import {ReactComponent as JoinedIcon} from 'assets/images/headTail/joined.svg'
import EventImg from 'assets/images/headTail/event.png'
import './PredictionRace.less'

const { Countdown } = Statistic;

export default function PredictionRace(props: RouteComponentProps<{ id: string }>) {
  const {
    match: {
      params: { id }
    }
  } = props
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { t } = useTranslation()
  const { chainId, library } = useActiveWeb3React()
  const [chartRange, setChartRange] = useState<string>(AggregateChartRangeOption.DAILY_PRICE_RANGE)
  const predictContract = usePredictContract(SOFI_Predict_contract_address[chainId || ChainId.RINKEBY])
  const [refresh, setRefresh] = useState<number>(1);
  const eventData = useEventDetail(id, refresh);
  const [infoId, setInfoId] = useState<number>(1)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const addTransaction = useTransactionAdder()
  const now = new Date().getTime()
  const chartData = useChartData(eventData?.name && eventData?.name.includes('price') ? eventData.name.replace("-price", '') : '');

  const onChange = (e: any) => {
    console.log('radio checked', e.target.value);
    setInfoId(e.target.value)
  };

  const handlePredict = () => {
    if (predictContract && library) {
      // const signature = await getSign(library, account)
      // console.log('signature', signature)
      setConfirmLoading(true)
      predictContract
        .predict(eventData.roundId, infoId)
        .then((res: any) => {
          addTransaction(res, {
            summary: 'Predict Success'
          })
          setConfirmLoading(false)
          retry(() => {
            return library
            .getTransactionReceipt(res.hash)
            .then(receipt => {
                if (receipt === null) {
                    console.debug('Retrying for hash', res.hash)
                    throw new RetryableError()
                  }
                  if (receipt) {
                    console.log("trade receipt", receipt)
                    setRefresh(refresh + 1)
                  }
            })
          }, {
              n: Infinity,
              minWait: 2500,
              maxWait: 3500
          })
        })
        .catch((error: any) => {
          console.error('Vote Failed', error)
          if (error.code === 4001) {
            notification['error']({
              message: '',
              description: t('cancelled_transaction')
            })
          } else {
            notification['error']({
              message: '',
              description: error.message
            })
          }
          setConfirmLoading(false)
        })
    }
  }

  return (
    <STS>
        <Container className='predictionRace'>
            <div className='title'>
                <div>{eventData?.name === 'event' ? t('event_race') : 'Prediction Race'}</div>
                <div></div>
            </div>
            <div className='race'>
              <div>
                {eventData && eventData?.name === 'event' ?
                  <div className='event'>
                    <img src={imgages[`${formatTeam(eventData.team_a)}_${formatTeam(eventData.team_b)}`]} alt="" />
                  </div> 
                  :
                  <>
                    <div className='tokenInfo'>
                      <div>
                        <img className="tokenAvator" src={eventData?.name && getTokenLogoURL(eventData.name.replace("-price", ''))}/>
                        <img className="tokenAvator" src={getTokenLogoURL('usdt')}/>
                        {eventData?.name && eventData.name.replace("-price", '').toUpperCase()}&nbsp;/&nbsp;{'USDT'}
                      </div>
                    </div>
                    <Chart token={eventData?.name?.replace("-price", '')}/>
                    {/* <div className="price_box">
                      <b className="price">{`${tickerData.price >= 1 ? numeral(tickerData.price).format('0,0.00') : numeral(tickerData.price).format('0,0.00000000')}`}</b>
                      <span className={tickerData?.priceChangePercent > 0 ? 'up': 'down'}>{`${tickerData?.priceChangePercent > 0 ? `+${tickerData?.priceChangePercent}%`: `${tickerData?.priceChangePercent}%`}`}</span>
                    </div>
                    <div className="card_footer">{moment(tickerData?.time).format('YYYY-MM-DD HH:mm:ss')}</div> */}
                  </>
                }
                <Card className="infoCard">
                    <div className='subTitle'>
                        <div>{t('summary')}</div>
                        <div></div>
                    </div>
                    {eventData.name === 'event' ?
                    <div>
                      <div>
                          <div>Team A</div>
                          <div>{eventData.team_a || '-'}</div>
                      </div>
                      <div>
                        <div>Team B</div>
                        <div>{eventData.team_b || '-'}</div>
                      </div>
                      <div>
                        <div>{t('now_joined')}</div>
                        <div>{numeral(eventData.joined).format('0,0')} {t('users')} <JoinedIcon/></div>
                      </div>
                      <div>
                        <div>#{eventData.roundId} {t('running')}</div>
                        <div>
                          {now < eventData.start_predict * 1000 && t('not_started')}
                          {now > eventData.start_predict * 1000 && now < eventData.end_predict * 1000 && <>{t('remain')}: <Countdown value={eventData.end_predict*1000} format="D[d] H[h] m[m] s[s]" /></>}
                          {now > eventData.end_predict * 1000 && now < eventData.end_round * 1000 && t('in_the_game')}
                          {now > eventData.end_round * 1000 && <>
                              {eventData.result === 0 && t('in_the_game')}
                              {eventData.result === 1 && `${eventData.team_a} ${t('won')}`}
                              {eventData.result === 2 && `${eventData.team_b} ${t('won')}`}
                              {eventData.result === 3 && `${t('draw')}`}
                              {eventData.result === 4 && `${t('cancel')}`}
                          </>}
                        </div>
                        {/* <div>{t('remain')}: <Countdown value={eventData.end_predict*1000} format="D[d] H[h] m[m] s[s]" /></div> */}
                      </div>
                    </div>
                    :
                    <div className='token'>
                      <div>
                          <div>Start</div>
                          <div className='priceUnit'>{eventData.start_price} 
                            <div>
                              <img className="tokenAvator" src={eventData?.name && getTokenLogoURL(eventData.name.replace("-price", ''))}/>
                              <img className="tokenAvator" src={getTokenLogoURL('usdt')}/>
                              {eventData?.name && eventData.name.replace("-price", '').toUpperCase()}&nbsp;/&nbsp;{'USDT'}
                            </div>
                          </div>
                      </div>
                      <div>
                        <div>Now</div>
                        <div className='priceUnit'>{chartData.length > 0 && chartData[chartData.length-1].value} 
                            <div>
                              <img className="tokenAvator" src={eventData?.name && getTokenLogoURL(eventData.name.replace("-price", ''))}/>
                              <img className="tokenAvator" src={getTokenLogoURL('usdt')}/>
                              {eventData?.name && eventData.name.replace("-price", '').toUpperCase()}&nbsp;/&nbsp;{'USDT'}
                            </div>
                          </div>
                      </div>
                      {/* <div>
                        <div>Now Prize Pool</div>
                        <div>-</div>
                      </div> */}
                      <div>
                        <div>#{eventData.roundId} Runing</div>
                        <div>
                          {now < eventData.start_predict * 1000 && t('not_started')}
                          {now > eventData.start_predict * 1000 && now < eventData.end_predict * 1000 && <>{t('remain')}: <Countdown value={eventData.end_predict*1000} format="D[d] H[h] m[m] s[s]" /></>}
                          {now > eventData.end_predict * 1000 && now < eventData.end_round * 1000 && t('in_the_game')}
                          {now > eventData.end_round * 1000 && <>
                              {eventData.result === 0 && t('in_the_game')}
                              {eventData.result === 1 && `${eventData.team_a} ${t('won')}`}
                              {eventData.result === 2 && `${eventData.team_b} ${t('won')}`}
                              {eventData.result === 3 && `${t('draw')}`}
                              {eventData.result === 4 && `${t('cancel')}`}
                          </>}
                        </div>
                      </div>
                    </div>
                  }
                </Card>
                <div className='subTitle'>
                    <div>{t('race_history')}</div>
                    <div></div>
                </div>
                <div className='history'>
                  {eventData?.predicts && eventData?.predicts.map((data: any) =>
                      <Comment
                          key={data.txid}
                          author={<>
                            {data.round_status === 'ONGOING' && <Tag className={data.round_status}>{t('on_going')}</Tag>}
                            {data.round_status === 'CANCELLED' && <Tag className={data.round_status}>{t('cancel')}</Tag>}
                            {data.round_status === 'DRAW' && <Tag className={data.round_status}>{t('draw')}</Tag>}
                            {data.round_status === 'SUCCESS' && <Tag className={data.round_status}>{t('Success')}</Tag>}
                            {data.round_status === 'FAIL' && <Tag className={data.round_status}>{t('fail')}</Tag>}
                          </>}
                          content={
                              <>
                                <div className='content'>
                                  <div>
                                      <p>{chainId && <a target='_blank' href={getEtherscanLink(chainId, data.txid, 'transaction')}>{data.sender && shortenAddress(data.sender)}</a>}
                                      {eventData.name === 'event' ? <>{t('predict')} {data.info === 1 ? `${eventData.team_a} ${t('perdict_won')}` : `${eventData.team_b} ${t('perdict_won')}`}</> : 
                                        <>&nbsp;{data.info === 1 ? t('head') : t('tail')} at {data.my_predict} {eventData?.name && eventData.name.replace("-price", '').toUpperCase()}&nbsp;/&nbsp;{'USDT'}</>}
                                        </p>
                                      <div>{t('race_in_roundId', {roundId: eventData.roundId})}</div>
                                  </div>
                                  <div>   
                                      {moment(data.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                      <div>{t('point')}: {data.points}</div>
                                  </div>
                                </div>
                              </>
                          }
                      />
                  )}
                  {
                    eventData?.predicts && eventData?.predicts.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no_record')}/>
                  }   
                </div>
              </div>
              <div className="swap-content">
                  <div className='head'>
                      <div>
                        <div>Now</div>
                        <div>Head : {eventData?.info_1_percent} / Tail : {eventData?.info_2_percent}</div>
                      </div>
                  </div>
                  <div className='body'>
                  {eventData.name === 'event' ?
                    <Radio.Group className='options' onChange={onChange} value={eventData.my_predict || infoId}>
                        <Space direction="vertical">
                          <Radio value={1}>
                            <div className='team'>
                              <div>
                                <div>Team A</div>
                                <div>{eventData.team_a || '-'}</div>
                              </div>
                            </div>
                          </Radio>
                          <Radio value={2}>
                            <div className='team'>
                              <div>
                                <div>Team B</div>
                                <div>{eventData.team_b || '-'}</div>
                              </div>
                            </div>
                          </Radio>
                        </Space>
                    </Radio.Group>
                    :
                    <Radio.Group className='options' onChange={onChange} value={eventData.my_predict || infoId}>
                      <Space direction="vertical">
                        <Radio value={1}>
                          <div className='direction'>
                            <div><ChartUp/></div>
                            <div>
                              <div>{t('head')}</div>
                            </div>
                          </div>
                        </Radio>
                        <Radio value={2}>
                          <div className='direction'>
                            <div><ChartDown/></div>
                            <div>
                              <div>{t('tail')}</div>
                            </div>
                          </div>
                        </Radio>
                      </Space>
                    </Radio.Group>
                    }
                    <div className='btns'>
                      {eventData && eventData.my_predict === 0 ? 
                        <Button type='primary' onClick={handlePredict} disabled={now < eventData.start_predict*1000 || now > eventData.end_predict*1000 } block>
                           {now < eventData.start_predict*1000 && t('round_not_predictable')}
                           {now > eventData.start_predict*1000 && now < eventData.end_predict*1000 && t('participate') }
                           {now > eventData.end_predict*1000 && t('ended') }
                        </Button> :
                        <Button type="default" disabled block>{t('predicted')}</Button> 
                      }
                    </div>
                  </div>
              </div>
            </div>
        </Container>
    </STS>
  )
}
