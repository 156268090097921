import React, {useState, useCallback} from 'react'
import { Avatar, Carousel, Tag, Card, Comment, List, Button, Statistic } from 'antd';
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import STS from '../STS'
import { useActiveWeb3React } from '../../hooks'
import { Container } from '../STS/Markets'
import { Chart } from './Chart';
import { useRuningRaceList, usePointsList } from './hooks';
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import { imgages, formatTeam } from './constant';
import {ReactComponent as ArrowLeft} from 'assets/images/headTail/ArrowLeft.svg'
import {ReactComponent as ArrowRight} from 'assets/images/headTail/ArrowRight.svg'
import {ReactComponent as SOFI} from 'assets/images/headTail/sofi.svg'
import {ReactComponent as JoinedIcon} from 'assets/images/headTail/joined.svg'
import './MoreRace.less';

const { Countdown } = Statistic;

const MoreRace: React.FC = () =>  {
    const history = useHistory();
    const runningRaceList = useRuningRaceList(100);
    const { t } = useTranslation()
    const now = new Date().getTime()

    return (
        <STS >
            <Container className="moreRace">
                <div className='subTitle'>
                    <div>{t('more_running_race')}</div>
                </div>
                <List
                    className="raceList"
                    dataSource={runningRaceList}
                    renderItem={(item: any) => (
                        <List.Item style={{background: `url(${imgages[formatTeam(item.team_a) + '_' + formatTeam(item.team_b)]}) no-repeat`}} onClick={() => {history.push(`${item.name === 'event'  ? `/headTail/event/${item.roundId}`:`/headTail/${item.name.replace("-price", '')}-usdt/${item.roundId}`}`)}}>
                            <Card 
                                className={`${item.name === 'event' ? 'event': 'price'}`}
                                title={t('race_in_roundId', {roundId: item.roundId})} 
                                extra={
                                    <>
                                        {now < item.start_predict * 1000 && t('not_started')}
                                        {now > item.start_predict * 1000 && now < item.end_predict * 1000 && <Countdown value={item.end_predict*1000} format="D[d] H[h] m[m] s[s]" />}
                                        {now > item.end_predict * 1000 && now < item.end_round * 1000 && t('in_the_game')}
                                        {now > item.end_round * 1000 && <>
                                            {item.result === 0 && t('in_the_game')}
                                            {item.result === 1 && `${item.team_a} ${t('won')}`}
                                            {item.result === 2 && `${item.team_b} ${t('won')}`}
                                            {item.result === 3 && `${t('draw')}`}
                                            {item.result === 4 && `${t('cancel')}`}
                                        </>}
                                    </>
                                }
                            >
                                {item.name === 'event' ?
                                    <>
                                        <div className='title'>{t('event_race')}</div>
                                        <div className='joined'>
                                            <div className='tag'><JoinedIcon/>{t('number_users_joined',{number: item.joined})}</div>
                                        </div>
                                    </>
                                    : 
                                    <>
                                        <div className='tokenImages'>
                                            <img className="tokenAvator" src={getTokenLogoURL(item.name.replace("-price", ''))}/>
                                            <img className="tokenAvator" src={getTokenLogoURL('usdt')}/>
                                        </div>
                                        <div className='symbol'>{item.name.replace("-price", '').toUpperCase()}&nbsp;/&nbsp;{'USDT'}</div>
                                        <div className='joined'>
                                            <div className='tag'><JoinedIcon/>{t('number_users_joined',{number: item.joined})}</div>
                                        </div>
                                        <Chart token={item.name.replace("-price", '')}/>
                                    </>
                                }
                                
                                <Button type="primary">
                                    {now < item.start_predict * 1000 && t('coming_soon')}
                                    {now > item.start_predict * 1000 && now < item.end_predict * 1000 && t('join_now')}
                                    {now > item.end_predict * 1000 && t('ended')}
                                </Button>
                            </Card>
                        </List.Item>
                    )}
                />
                {/* <List
                    className="leaderList"
                    dataSource={tradedPortfolios}
                    renderItem={(item: any) => (
                        <List.Item onClick={() => {history.push(`/sts/portfolio/${item.address}`)}}>
                        <Card 
                            title={<div className='tokenInfo'><GoldIcon/><img className="tokenAvator" src={item.avatar_uri}/><div>0x...4f19<br /><span>in Race #243</span></div></div>} 
                            extra="4.78 x"
                        >
                        </Card>
                        </List.Item>
                    )}
                /> */}
            </Container>
        </STS>
    )
}

export default MoreRace;
