import { useCallback, useEffect, useState,useMemo } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { APILIST } from '../../constants'
import { get, request } from '../../utils/request';

export function useRuningRaceList(limit: number): any {
    const [list, setList] = useState<any>([]);
    const { account, chainId } = useActiveWeb3React()
    const api_url = chainId ? APILIST[chainId] : APILIST[1];
    const fetchData = useCallback(async () => {
      get(`${api_url}/sofi/rounds?limit=${limit}`).then(async (res: any) => {
          if (res) {
              setList(res)
          }
      });
    }, [limit])
  
    useEffect(() => {
      if(!account || chainId === 1){
        fetchData()
      }
    }, [limit, account, chainId])
    return list
}

export function useEventDetail(id: string, refresh?: number): any{
  const [data, setData] = useState<any>({});
  const { account, chainId } = useActiveWeb3React()
  const api_url = chainId ? APILIST[chainId] : APILIST[1];
  const fetchData = useCallback(async () => {
    get(`${api_url}/sofi/rounds/${id}?address=${account}`).then((response: any) => {
      if (response) {
        setData(response)
      }
    });
  }, [account])

  useEffect(() => {
    if(id){
      fetchData()
    }
  }, [id, account, refresh])
  return data
}

export function usePointsList(): any {
  const [data, setData] = useState<any>([]);
  const { account, chainId } = useActiveWeb3React()
  const api_url = chainId ? APILIST[chainId] : APILIST[1];
  const fetchData = useCallback(async () => {
    get(`${api_url}/sofi/points?address=${account}`).then(async (res: any) => {
        if (res) {
            setData(res)
        }
    });
  }, [account])

  useEffect(() => {
    if(!account || chainId === 1){
      fetchData()
    }
  }, [account, chainId])
  return data
}

export function useChartData(token:string, type?: string, refresh = 1): any {
  const [chartData, setChartData] = useState<any>([]);
  const { chainId, account } = useActiveWeb3React()
  const api_url = chainId && account ? APILIST[chainId] : APILIST[4];
  const fetchChartData = useCallback(async () => {
    get(`${api_url}/sofi/tickers?token=${token}`).then((res: any) => {
      if (res) {
        setChartData(res)
      }
    });
  }, [token])

  useEffect(() => {
    if (token && !token.includes('undefined')) {
      fetchChartData();
    }
  }, [token, refresh])
  return chartData
}
