import { Currency, ETHER, Token } from '@uniswap/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ChainId } from 'constants/chainId'
import EthereumLogo from '../../assets/images/ethereum-logo.png'
import MaticLogo from '../../assets/images/matic-token-icon.png'
import BaseGoerliLogo from '../../assets/svg/base-goerli.svg'
import BaseLogo from '../../assets/svg/base.svg'
import BlastSepolia from '../../assets/images/blast.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import TaikoLogo from '../../assets/svg/taiko.svg'
import ZksyncLogo from '../../assets/images/zksync.png'
import PZLogo from '../../assets/svg/pz.svg'

export const getTokenLogoURL = (token: string | undefined) =>
  `https://rai-static.s3.ap-northeast-1.amazonaws.com/sts/token/${token && token.toLowerCase()}.png`

const StyledEthereumLogo = styled.img<{ size: string, showBoxShadow?: boolean | undefined }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 24px;
  box-shadow: ${({ showBoxShadow }) => showBoxShadow ? "0px 6px 10px rgba(0, 0, 0, 0.075)" : "none"};
  background: #ddd;
  border-radius: 50%;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background: #ddd;
  border-radius: 50%;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: any
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.symbol)]
      }
      return [getTokenLogoURL(currency.symbol)]
    }
    return [getTokenLogoURL(currency.symbol)]
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }

  return <StyledLogo size={size} srcs={srcs} style={style} />
}

export function ChainLogo({
  chainId,
  size = '24px',
  style,
  showBoxShadow = true
}: {
  chainId?: number
  size?:string
  style?: React.CSSProperties,
  showBoxShadow?: boolean
}) {
  if (chainId === ChainId.POLYGON) {
      return <StyledEthereumLogo src={MaticLogo} size={size} style={style} showBoxShadow={showBoxShadow}/>
  }
  if (chainId === ChainId.BASE_GOERLI) {
    return <StyledEthereumLogo src={BaseGoerliLogo} size={size} style={style} showBoxShadow={showBoxShadow}/>
  }
  if (chainId === ChainId.TAIKO) {
    return <StyledEthereumLogo src={TaikoLogo} size={size} style={style} showBoxShadow={showBoxShadow}/>
  }
  if (chainId === ChainId.ZKSYNC_TEST) {
    return <StyledEthereumLogo src={ZksyncLogo} size={size} style={style} showBoxShadow={showBoxShadow}/>
  }
  if (chainId === ChainId.BASE) {
    return <StyledEthereumLogo src={BaseLogo} size={size} style={style} showBoxShadow={showBoxShadow}/>
  }
  if (chainId === ChainId.BLAST_SEPOLIA) {
    return <StyledEthereumLogo src={BlastSepolia} size={size} style={style} showBoxShadow={showBoxShadow}/>
  }
  if (chainId === 1101 || chainId === 1442) {
    return <StyledEthereumLogo src={PZLogo} size={size} style={style} showBoxShadow={showBoxShadow}/>
  }

    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} showBoxShadow={showBoxShadow} />
}
