import React, {useState, useCallback} from 'react'
import { Avatar, Empty, Tag, Card, Comment, List, Button, Statistic } from 'antd';
import { UserOutlined, TwitterOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom'
import numeral from 'numeral'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { InfoCircleFilled } from '@ant-design/icons'
import Slider from "react-slick";
import STS from '../STS'
import { Chart } from './Chart';
import { useActiveWeb3React } from '../../hooks'
import { Container } from '../STS/Markets'
import { useRuningRaceList, usePointsList } from './hooks';
import { shortenAddress, getEtherscanLink } from 'utils';
import { getTokenLogoURL } from 'components/CurrencyLogo/index'
import { imgages, formatTeam } from './constant';
import {ReactComponent as GoldIcon} from 'assets/images/headTail/gold.svg'
import {ReactComponent as SilverIcon} from 'assets/images/headTail/silver.svg'
import {ReactComponent as BronzeIcon} from 'assets/images/headTail/bronze.svg'
import {ReactComponent as ArrowLeft} from 'assets/images/headTail/ArrowLeft.svg'
import {ReactComponent as ArrowRight} from 'assets/images/headTail/ArrowRight.svg'
import {ReactComponent as SOFI} from 'assets/images/headTail/sofi.svg'
import {ReactComponent as JoinedIcon} from 'assets/images/headTail/joined.svg'
import './index.less';

const { Countdown } = Statistic;

const HeadTail: React.FC = () =>  {
    const history = useHistory();
    const runningRaceList = useRuningRaceList(3);
    const pointsList = usePointsList();
    const { t } = useTranslation()
    const { chainId, account, connector } = useActiveWeb3React()
    const now = new Date().getTime()

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
        centerPadding: "40px",
        speed: 500,
        nextArrow: <div><ArrowRight /></div>,
        prevArrow: <div><ArrowLeft /></div>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    // infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <STS >
            <Container className="headTailPage">
                {chainId !== 1 && <div className="tip">
                    <InfoCircleFilled style={{ fontSize: '16px', color: '#5542F6' }} />&nbsp;&nbsp;
                    {t("head_tail_title")}
                </div>}
                <div className='subTitle'>
                    <div>{t('running_race')}</div>
                    <div onClick={() => {history.push('/headTail/moreRace')}}>{t('sts_see_more')}</div>
                </div>
                <List
                    className="raceList"
                    dataSource={runningRaceList.slice(0, 3)}
                    renderItem={(item: any) => (
                        <List.Item style={{background: `url(${imgages[formatTeam(item.team_a) + '_' + formatTeam(item.team_b)]}) no-repeat`}} onClick={() => {history.push(`${item.name === 'event'  ? `/headTail/event/${item.roundId}`:`/headTail/${item.name.replace("-price", '')}-usdt/${item.roundId}`}`)}}>
                            <Card 
                                className={`${item.name === 'event' ? 'event': 'price'}`}
                                title={t('race_in_roundId', {roundId: item.roundId})} 
                                extra={
                                    <>
                                        {now < item.start_predict * 1000 && t('not_started')}
                                        {now > item.start_predict * 1000 && now < item.end_predict * 1000 && <Countdown value={item.end_predict*1000} format="D[d] H[h] m[m] s[s]" />}
                                        {now > item.end_predict * 1000 && now < item.end_round * 1000 && t('in_the_game')}
                                        {now > item.end_round * 1000 && <>
                                            {item.result === 0 && t('in_the_game')}
                                            {item.result === 1 && `${item.team_a} ${t('won')}`}
                                            {item.result === 2 && `${item.team_b} ${t('won')}`}
                                            {item.result === 3 && `${t('draw')}`}
                                            {item.result === 4 && `${t('cancel')}`}
                                        </>}
                                    </>
                                }
                            >
                                {item.name === 'event' ?
                                    <>
                                        <div className='title'>{t('event_race')}</div>
                                        <div className='joined'>
                                            <div className='tag'><JoinedIcon/>{t('number_users_joined',{number: item.joined})}</div>
                                        </div>
                                    </>
                                    : 
                                    <>
                                        <div className='tokenImages'>
                                            <img className="tokenAvator" src={getTokenLogoURL(item.name.replace("-price", ''))}/>
                                            <img className="tokenAvator" src={getTokenLogoURL('usdt')}/>
                                        </div>
                                        <div className='symbol'>{item.name.replace("-price", '').toUpperCase()}&nbsp;/&nbsp;{'USDT'}</div>
                                        <div className='joined'>
                                            <div className='tag'><JoinedIcon/>{t('number_users_joined',{number: item.joined})}</div>
                                        </div>
                                        <Chart token={item.name.replace("-price", '')}/>
                                    </>
                                }
                                
                                <Button type="primary">
                                    {now < item.start_predict * 1000 && t('coming_soon')}
                                    {now > item.start_predict * 1000 && now < item.end_predict * 1000 && t('join_now')}
                                    {now > item.end_predict * 1000 && t('ended')}
                                </Button>
                            </Card>
                        </List.Item>
                    )}
                />
                {/* <List
                    className="leaderList"
                    dataSource={tradedPortfolios}
                    renderItem={(item: any) => (
                        <List.Item onClick={() => {history.push(`/sts/portfolio/${item.address}`)}}>
                        <Card 
                            title={<div className='tokenInfo'><GoldIcon/><img className="tokenAvator" src={item.avatar_uri}/><div>0x...4f19<br /><span>in Race #243</span></div></div>} 
                            extra="4.78 x"
                        >
                        </Card>
                        </List.Item>
                    )}
                /> */}
                <div className='subTitle'>
                    <div>{t('leader_board')}</div>
                    <div></div>
                </div>
            </Container>
            <Slider {...settings} className="leaderList">
                {pointsList.leaderborad && pointsList.leaderborad.map((item: any, index: number) => 
                    <Card 
                        title={<div className='tokenInfo'>
                            {index == 0 && <GoldIcon/>}
                            {index == 1 && <SilverIcon/>}
                            {index == 2 && <BronzeIcon/>}
                            {index > 2 && <div className='tag'>{index + 1}</div>}
                            {item.photo_url ? <img className="tokenAvator" src={item.photo_url}/> : <Avatar size={40} icon={<UserOutlined />} />}
                            <div>{shortenAddress(item.sender)}<br /></div></div>} 
                        extra={item.points}
                    >
                    </Card>
                    )}
            </Slider>   
            <Container className="headTailPage">
                <div className='subTitle'>
                    <div>{t('my_history')}</div>
                    <div>{t('my_point')}: <b>{numeral(pointsList.mypoints).format('0,0')}</b></div>
                </div>
                <div className='history'>
                    {pointsList.history && pointsList.history.map((data: any) =>
                        <Comment
                            key={data.id}
                            author={<>
                                {data.round_status === 'ONGOING' && <Tag className={data.round_status}>{t('on_going')}</Tag>}
                                {data.round_status === 'CANCELLED' && <Tag className={data.round_status}>{t('cancel')}</Tag>}
                                {data.round_status === 'DRAW' && <Tag className={data.round_status}>{t('draw')}</Tag>}
                                {data.round_status === 'SUCCESS' && <Tag className={data.round_status}>{t('Success')}</Tag>}
                                {data.round_status === 'FAIL' && <Tag className={data.round_status}>{t('fail')}</Tag>}            
                                {data.name !== 'event' && <a className="tweet" target="_blank" href={`https://twitter.com/intent/tweet?via=RaiFinance&hashtags=Crypto%2Cprice%2Cpredict%2Crai_finance&original_referer=https%3A%2F%2Fdeveloper.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&related=twitterapi%2Ctwitter&text=${t('year_end_event_twitter_contents', {starting_price: `$${data.start_price}`, pair: `${data?.name && data.name.replace("-price", '').toUpperCase()}/USDT`, type: data.info === 1 ? t('higher') : t('lower'), timestamp: data.timestamp && moment(data.timestamp*1000).format("D, MMM YY")})}`}>
                                    <Tag className="twitter" icon={<TwitterOutlined />}></Tag>
                                    {t('share')}
                                </a>}
                              </>}
                            content={
                                <>
                                <div className='content'>
                                    <div>
                                        {data.name === 'event' &&<p>{chainId && <a target='_blank' href={getEtherscanLink(chainId, data.txid, 'transaction')}>{shortenAddress(data.sender)}</a>} {t('predict')} {data.info === 1 ? `${data.team_a} ${t('perdict_won')}` : `${data.team_b} ${t('perdict_won')}`}</p>}
                                        {data.name !== 'event' &&<p>{chainId && <a target='_blank' href={getEtherscanLink(chainId, data.txid, 'transaction')}>{shortenAddress(data.sender)}</a>} {t('predict')} {data.info === 1 ? `${t('head')} ${t('perdict_won')}` : `${t('tail')} ${t('perdict_won')}`}</p>}
                                        <div>{t('race_in_roundId', {roundId: data.round_id})}</div>
                                    </div>
                                    <div>   
                                        {moment(data.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                        <div>{t('point')}: {data.points}</div>
                                    </div>
                                </div>
                                </>
                            }
                        />
                    )}
                    {
                        pointsList.history && pointsList.history.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no_record')}/>
                    } 
                </div>
            </Container>
        </STS>
    )
}

export default HeadTail;
