import { Contract } from '@ethersproject/contracts'
import { abi as GOVERNANCE_ABI } from '@uniswap/governance/build/GovernorAlpha.json'
import { abi as UNI_ABI } from '@uniswap/governance/build/Uni.json'
import { abi as STAKING_REWARDS_ABI } from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { abi as MERKLE_DISTRIBUTOR_ABI } from '@uniswap/merkle-distributor/build/MerkleDistributor.json'
import { ChainId } from 'constants/chainId';
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { useMemo } from 'react'
import { JsonRpcSigner, Web3Provider, JsonRpcProvider } from '@ethersproject/providers';
import { GOVERNANCE_ADDRESS, MERKLE_DISTRIBUTOR_ADDRESS, UNI, WETH, TSOFI } from '../constants'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS
} from '../constants/abis/argent-wallet-detector'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract, getProviderOrSigner } from '../utils'
import { useActiveWeb3React } from './index'
import { FIXEDSWAP_ABI } from '../constants/abis/fixedSwap'
import { ProtocalViewer } from '../constants/abis/ProtocalViewer'
import { SetTokenCreator } from '../constants/abis/SetTokenCreator'
import { BasicIssuanceModule } from '../constants/abis/BasicIssuanceModule'
import { TradeModule } from '../constants/abis/TradeModule'
import { EXCHANGEISSUANCE_ABI } from "../constants/abis/exchangeIssuance"
import { SetToken } from '../constants/abis/SetToken'
import { RAI_PROXY } from '../constants/abis/rai-proxy'
import { sofiManage } from '../constants/abis/sofiManage'
import { sofiDao } from '../constants/abis/sofi-dao'
import { PROMOTE_ABI } from '../constants/abis/Promote'
import { SOFIPredict } from '../constants/abis/SOFIPredict'
import CONDITIONAL_TOKENS_ABI from '../constants/abis/ConditionalTokens.json';
import { FixedProductMarketMaker_ABI } from '../constants/abis/FixedProductMarketMaker'
import FixedProductMarketMakerService from 'services/fixed_product_market_maker'
import ConditionalTokensService from 'services/conditional_token';
// returns null on errors
function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true,
  connectSigner = false,
): T | null {
  const { library, account, chainId } = useActiveWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      const contract = getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      );
      if (connectSigner) {
        const signer = getSigner(library, account as string);
        return contract.connect(signer);
      }
      return contract;
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T;
}

export default useContract;

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

export function useFixedSwapContract(tokenAddress: string): Contract | null {
  return useContract(tokenAddress, FIXEDSWAP_ABI, true)
}

export function usePortfolioContract(tokenAddress: string | undefined): Contract | null {
  return useContract(tokenAddress, ProtocalViewer, true)
}

export function useCreatePortfolioContract(tokenAddress: string | undefined): Contract | null {
  return useContract(tokenAddress, SetTokenCreator, true)
}

export function useSetTokenContract(tokenAddress: string | undefined): Contract | null {
  return useContract(tokenAddress, SetToken, true)
}

export function useBasicIssuanceModuleContract(tokenAddress: string | undefined): Contract | null {
  return useContract(tokenAddress, BasicIssuanceModule, true)
}

export function useTradeModuleContract(tokenAddress: string | undefined): Contract | null {
  return useContract(tokenAddress, TradeModule, true)
}

export function useExchangeIssuanceContract(contractAddress: string | undefined): Contract | null {
  return useContract(contractAddress, EXCHANGEISSUANCE_ABI, true)
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}


export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.GÖRLI:
      case ChainId.ROPSTEN:
      case ChainId.RINKEBY:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useMerkleDistributorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? MERKLE_DISTRIBUTOR_ADDRESS[chainId] : undefined, MERKLE_DISTRIBUTOR_ABI, true)
}

export function useGovernanceContract(): Contract | null {
  return useContract(GOVERNANCE_ADDRESS, GOVERNANCE_ABI, true)
}

export function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
}

export function useRAIProxyContract(proxyAddress?: string): Contract | null {
  return useContract(proxyAddress, RAI_PROXY, true)
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI,
    false
  )
}

export function useReedemContract(tokenAddress: string): Contract | null {
  return useContract(tokenAddress, FIXEDSWAP_ABI, true)
}

export function useSofiManageContract(managerModuleAddress?: string): Contract | null {
  return useContract(managerModuleAddress, sofiManage, true)
}

export function useVoteContract(address?: string): Contract | null {
  return useContract(address, sofiDao, true)
}

export function useSofiPromoteContract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, PROMOTE_ABI, true)
}

export function usePredictContract(address?: string): Contract | null {
  return useContract(address, SOFIPredict, true)
}

export function useFpmmInterface(marketAddress: string, provider: Web3Provider, account: string) {
  const {chainId } = useActiveWeb3React()
  const contract = useContract<Contract>(
    marketAddress,
    FixedProductMarketMaker_ABI,
    true,
    Boolean(account),
  );
  return useMemo(
    () => contract && account && new FixedProductMarketMakerService(contract, chainId ? TSOFI[chainId].address : '', provider, account),
    [contract, account],
  );
}

export function useConditionalTokenInterface(provider: Web3Provider, address: string) {
  const contract = useContract<Contract>(address, CONDITIONAL_TOKENS_ABI, false);
  return useMemo(() => contract && new ConditionalTokensService(provider, contract), [contract]);
}

// export function useFixedProductMarketMakerContract(address?: string): Contract | null {
//   return useContract(address, FixedProductMarketMaker_ABI, true)
// }