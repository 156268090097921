import { useCallback } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from '@web3-react/injected-connector';
import {
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
    WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import { useTranslation } from 'react-i18next';
import { clearLocalStorage } from 'utils/txSign'
import { ConnectorNames, connectors, supportedChainIds } from 'connectors/index';
import { SUPPORTED_WALLETS } from 'constants/index'

const useAuth = () => {
    const { t } = useTranslation();
    // const dispatch = useAppDispatch()
    const { activate, deactivate, library } = useWeb3React();

    const login = useCallback(
        async (connectorID: ConnectorNames) => {
            const connector = connectors[connectorID];
            if (connector) {
                console.info('connector:  ', connector);
                activate(connector, async (error: Error) => {
                    if (error instanceof UnsupportedChainIdError) {
                        /*
                        const hasSetup = await setupNetwork()
                        if (hasSetup) {
                        activate(connector)
                        }
                        */
                        console.error(
                            'Cannot connect',
                            // eslint-disable-next-line quotes
                            `The blockchain you've selected is not supported.`
                        );
                        activate(connector);
                        let name = ''
                        Object.keys(SUPPORTED_WALLETS).map(key => {
                            if (connector === SUPPORTED_WALLETS[key].connector) {
                              return (name = SUPPORTED_WALLETS[key].name)
                            }
                            return true
                        })
                        localStorage.setItem('wallet_type', name)
                    } else {
                        // window.localStorage.removeItem(connectorLocalStorageKey)
                        if (error instanceof NoEthereumProviderError) {
                            // toastError(t('Provider Error'), t('No provider was found'))
                            console.log(t('Provider Error'), t('No provider was found'));
                        } else if (
                            error instanceof UserRejectedRequestErrorInjected ||
                            error instanceof UserRejectedRequestErrorWalletConnect
                        ) {
                            if (connector instanceof WalletConnectConnector) {
                                const walletConnector = connector as WalletConnectConnector;
                                walletConnector.walletConnectProvider = null;
                            }
                            // toastError(t('Authorization Error'), t('Please authorize to access your account'))
                            console.log(t('Authorization Error'), t('Please authorize to access your account'));
                        } else {
                            // toastError(error.name, error.message)
                            console.log(error);
                            console.log(error.name, error.message);
                        }
                    }
                });
            } else {
                // toastError(t('Unable to find connector'), t('The connector config is wrong'))
                console.log('not connector');
                console.log(t('Unable to find connector'), t('The connector config is wrong'));
            }
        },
        //[t, activate, toastError],
        [activate, t]
    );

    const logout = useCallback(() => {
        deactivate();
        clearLocalStorage();
    }, [deactivate]);

    return { login: login, logout: logout };
};

export default useAuth;
